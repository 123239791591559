<template>
    <section class="moduloCalendario">
        <div class="agenda">

            <div class="zonaFiltri">
                <DelayHydration>
                    <LazyFiltriAgenda v-if="filtri && calendario" :filtri="filtri" :calendario="calendario" />
                </DelayHydration>
            </div>

            <transition name="fade" mode="out-in">
                <div class="largecont" v-if="dataFiltri.vediMappa && !newDataLoading">
                    <div class="vistaMappa">
                        <div class="risultati" v-if="calendario && calendario.hits && calendario.hits.length > 0">
                            <OggettoEventoAgenda v-for="evento in calendario.hits" :evento="evento" :luogo="dataFiltri.comune" />
                            <OggettoEventoAgenda v-for="evento in generatedCards" :evento="evento" :luogo="dataFiltri.comune" />
                        </div>
                        <div class="empty" v-else>
                            <div class="paragraph" v-if="dataFiltri.range && dataFiltri.range.split(',').length == 2 && dataFiltri.range.split(',')[0] == dataFiltri.range.split(',')[1]">{{ $t('nessun_risultato_prova_giorni') }}</div>
                            <div class="paragraph" v-else-if="dataFiltri.proposte">{{ $t('nessun_risultato_prova_proposte') }}</div>
                            <div class="paragraph" v-else>{{ $t('nessun_risultato') }}</div>
                        </div>
                        <div :class="['mappaCont', { fixed: calendario && calendario.hits.length == 0 }]">
                            <div class="stickyMappa">
                                <RisultatiMappa :hits="[...calendario.hits, ...generatedCards]" :lat="dataFiltri.lat" :lng="dataFiltri.lng" />
                            </div>
                            <div class="fakeBackground"></div>
                        </div>
                    </div>
                </div>
                <div class="cont" v-else-if="!dataFiltri.vediMappa && !newDataLoading">
                    <div class="risultati" v-if="calendario && calendario.hits && calendario.hits.length > 0">
                        <OggettoEventoAgenda v-for="evento in calendario.hits" :evento="evento" :luogo="dataFiltri.comune" />
                        <OggettoEventoAgenda v-for="evento in generatedCards" :evento="evento" :luogo="dataFiltri.comune" />
                    </div>
                    <div class="empty" v-else>
                        <div class="paragraph" v-if="dataFiltri.range && dataFiltri.range.split(',').length == 2 && dataFiltri.range.split(',')[0] == dataFiltri.range.split(',')[1]">{{ $t('nessun_risultato_prova_giorni') }}</div>
                        <div class="paragraph" v-else-if="dataFiltri.proposte">{{ $t('nessun_risultato_prova_proposte') }}</div>
                        <div class="paragraph" v-else>{{ $t('nessun_risultato') }}</div>
                    </div>
                </div>
            </transition>
            <div class="loadmore" v-if="hasMorePages">
                <NButton size="medium" type="primary" round icon-placement="right" :loading="loading" @click="loadmore">{{ $t('load_more') }}</NButton>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { NButton } from 'naive-ui'

defineProps<{ modulo: any }>()

const { searchAgenda } = useSearch()
const { locale } = useI18n()
const directus = useDirectus()
const route = useRoute()

const dataFiltri = computed(() => {
    let range = null
    if (route.query.start_date && route.query.end_date) {
        range = [parseInt(route.query.start_date as string), parseInt(route.query.end_date as string)].join(',')
    }

    return {
        search: route.query.text ? route.query.text as string : '',
        comune: route.query.comune ? route.query.comune as string : null,
        categoria: route.query.categoria ? route.query.categoria as string : null,
        tipologia: route.query.tipologia ? route.query.tipologia as string : null,
        target: route.query.target ? route.query.target as string : null,
        lat: route.query.lat ? parseFloat(route.query.lat as string) : undefined,
        lng: route.query.lng ? parseFloat(route.query.lng as string) : undefined,
        proposte: route.query.type ? route.query.type as string : undefined,
        vediMappa: route.query.mappa ? (route.query.mappa == '1' ? true : false) : false,
        range: range
    }
})

const page = ref(1)
const hasMorePages = ref(false)
const loading = ref(false)
const newDataLoading = ref(false)
const generatedCards = ref<any>([])

const { data: calendario, refresh: refreshCalendario } = useAsyncData(
    `search-agenda-${dataFiltri.value.comune || '*'}-${dataFiltri.value.categoria || '*'}-${dataFiltri.value.tipologia || '*'}-${dataFiltri.value.target || '*'}-${dataFiltri.value.lat || 'x'}-${dataFiltri.value.lng || 'x'}-${dataFiltri.value.range || 'x'}-${dataFiltri.value.proposte || 'x'}-${dataFiltri.value.search || ''}`,
    () => searchAgenda(dataFiltri.value.search, dataFiltri.value.range, dataFiltri.value.comune, dataFiltri.value.categoria, dataFiltri.value.tipologia, dataFiltri.value.target, dataFiltri.value.lat, dataFiltri.value.lng, dataFiltri.value.proposte, page.value))
const { data: filtri } = useAsyncData(`filtri-agenda-${locale.value}`, () => directus('/pages/content/filter/agenda', { query: { locale: locale.value } }))

watch(dataFiltri, async () => {
    newDataLoading.value = true
    generatedCards.value.length = 0
    page.value = 1
    await refreshCalendario()
    newDataLoading.value = false
})

if (calendario.value && calendario.value.hits) {
    hasMorePages.value = page.value < calendario.value.totalPages
}
watch(calendario, () => {
    if (calendario.value && calendario.value.hits) {
        hasMorePages.value = page.value < calendario.value.totalPages
    }
})

const loadmore = async () => {
    loading.value = true

    page.value = page.value += 1
    const newResults = await searchAgenda(dataFiltri.value.search, dataFiltri.value.range, dataFiltri.value.comune, dataFiltri.value.categoria, dataFiltri.value.tipologia, dataFiltri.value.target, dataFiltri.value.lat, dataFiltri.value.lng, dataFiltri.value.proposte, page.value)
    if (newResults.hits) {
        generatedCards.value.push(...newResults.hits)
        hasMorePages.value = page.value < newResults.totalPages
    }

    loading.value = false
}
</script>


<style lang="scss" scoped>
.moduloCalendario {

    .zonaFiltri {
        min-height: 182px;
    }

    .risultati {
        display: flex;
        flex-direction: column;
        gap: var(--margin-default);
        margin: var(--margin-double) 0;
    }

    .vistaMappa {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: var(--margin-default);
    }

    .mappaCont {
        margin: var(--margin-double) 0;

        .stickyMappa {
            position: sticky;
            top: var(--margin-double);

            border-radius: var(--size-card-radius);
            overflow: hidden;
        }
    }

    .empty {
        margin: var(--margin-double) 0;
    }
}

.loadmore {
    margin: var(--margin-double) 0;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .moduloCalendario {

        .vistaMappa {
            position: relative;
            display: block;

            .risultati {
                padding-top: 340px
            }

            .mappaCont {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin-top: 0;
                pointer-events: none;

                .stickyMappa {
                    top: var(--margin-page);
                    pointer-events: auto;
                    z-index: 2;
                }

                .fakeBackground {
                    display: block;
                    position: sticky;
                    top: 0;
                    height: 40px;
                    background-color: var(--color-background-page);
                    width: 100%;
                    z-index: 1;
                }

                &.fixed {
                    .stickyMappa {
                        top: 0;
                        position: relative;
                    }

                    .fakeBackground {
                        display: none;
                    }
                }
            }
        }
    }

}
</style>
<template>
    <section class="moduloNewsletter">
        <div class="cont">
            <OggettoHeadModulo :modulo="modulo" />
            <div class="newsletterBox">
                <div class="innerCont">
                    <NForm ref="newsletterForm" label-placement="top" :model="data" :rules="rules" @submit.prevent="validateForm">
                        <div class="inputGroup">
                            <NFormItem :label="$t('nome')" path="first_name">
                                <NInput size="large" type="text" v-model:value="data.first_name" :input-props="{ autocomplete: 'given-name', name: 'first-name' }" :placeholder="$t('input_nome')" required />
                            </NFormItem>
                            <NFormItem :label="$t('cognome')" path="last_name">
                                <NInput size="large" type="text" v-model:value="data.last_name" :input-props="{ autocomplete: 'family-name', name: 'last-name' }" :placeholder="$t('input_cognome')" required />
                            </NFormItem>
                        </div>
                        <NFormItem :label="$t('indirizzo_email')" path="email">
                            <NInput size="large" type="text" v-model:value="data.email" :input-props="{ type: 'email', autocomplete: 'email', name: 'email' }" :placeholder="$t('input_email_newsletter')" required />
                        </NFormItem>
                        <div class="inputGroup">
                            <NFormItem :label="$t('citta')" path="citta">
                                <NInput size="large" type="text" v-model:value="data.citta" :input-props="{ autocomplete: 'city', name: 'city' }" :placeholder="$t('input_provenienza')" />
                            </NFormItem>
                            <NFormItem :label="$t('nazione')" path="nazione">
                                <NSelect size="large" v-model:value="data.nazione" filterable :placeholder="$t('input_nazione')" :options="opzioniStati" />
                            </NFormItem>
                        </div>
                        <template v-if="modulo.tipo_form == 'travel_news' && modulo.target == 'scolastica'">
                            <NFormItem :label="$t('scuola')" path="scuola">
                                <NInput size="large" type="text" v-model:value="data.scuola" :placeholder="$t('input_nome_scuola')" />
                            </NFormItem>
                            <NFormItem :label="$t('tipologia')" path="scuola_tipologia">
                                <NSelect size="large" v-model:value="data.scuola_tipologia" :placeholder="$t('input_tipologia_scuola')" :options="opzioniTipologiaScuola" />
                            </NFormItem>
                        </template>
                        <template v-if="modulo.tipo_form == 'trade_news'">
                            <NFormItem :label="$t('nome_societa')" path="company">
                                <NInput size="large" type="text" v-model:value="data.company" :placeholder="$t('input_societa')" />
                            </NFormItem>
                            <NFormItem :label="$t('interesse_educational')" path="interesse_educational">
                                <NSelect size="large" v-model:value="data.interesse_educational" :placeholder="$t('input_interesse')" :options="opzioniInteresseEducational" />
                            </NFormItem>
                        </template>
                        <template v-if="modulo.tipo_form == 'mice_news'">
                            <NFormItem :label="$t('nome_societa')" path="company">
                                <NInput size="large" type="text" v-model:value="data.company" :placeholder="$t('input_societa')" />
                            </NFormItem>
                            <NFormItem :label="$t('qualifica')" path="job">
                                <NInput size="large" type="text" v-model:value="data.job" :placeholder="$t('input_ruolo')" />
                            </NFormItem>
                        </template>
                        <NFormItem :label="$t('privacy')" path="privacy">
                            <NCheckbox size="large" v-model:checked="data.privacy" required>
                                {{ $t('privacy_1') }} <a href="https://turismotorino.org/it/turismo-torino-e-provincia/informative-sul-trattamento-dei-dati-personali">{{ $t('privacy_2') }}</a>.
                            </NCheckbox>
                        </NFormItem>

                        <div class="buttonCont">
                            <NButton size="medium" type="primary" round :loading="inProgress" attr-type="submit">{{ $t('button_iscriviti') }}</NButton>
                        </div>
                    </NForm>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import type { FormInst, FormItemRule } from 'naive-ui'
import { NButton, NFormItem, NCheckbox, NInput, NSelect, NForm, useDialog } from 'naive-ui'

const { getItems } = useDirectusItems()
const { validateEmail } = useValidation()
const { iscrizioneNewsletter } = useNewsletter()
const { t } = useI18n()
const dialog = useDialog()

const props = defineProps<{ modulo: any }>()

const newsletterForm = ref<FormInst | null>(null)

const { data: stati } = useAsyncData('stati', () => getItems({
    collection: 'stati',
    params: {
        limit: -1,
        fields: ['id', 'nome']
    }
}))
const opzioniStati = computed(() => {
    if (stati.value) {
        return stati.value.sort(function (x: any, y: any) {
            return x.nome == 'Italia' ? -1 : y.nome == 'Italia' ? 1 : 0;
        }).map((s: any) => { return { label: s.nome, value: s.nome } })
    } else return []
})
/*const opzioniLingue = ref([
    { label: t('italiano'), value: 'Italiano' },
    { label: t('inglese'), value: 'Inglese' },
    { label: t('francese'), value: 'Francese' }
])*/
const opzioniTipologiaScuola = ref([
    { label: t('scuola_primaria'), value: 'primaria' },
    { label: t('scuola_secondaria_primo_grado'), value: 'secondaria di primo grado' },
    { label: t('scuola_secondaria_secondo_grado'), value: 'secondaria di secondo grado' },
])
const opzioniInteresseEducational = ref([
    { label: t('city_break'), value: 'City break' },
    { label: t('trekking_estivo'), value: 'Trekking estivo' },
    { label: t('sci_montagna_invernale'), value: 'Sci e Montagna invernale' },
    { label: t('nessuno'), value: 'Nessuno' },
])

const data = reactive({
    first_name: '',
    last_name: '',
    email: '',
    citta: '',
    nazione: null,
    privacy: false,
    scuola: '',
    scuola_tipologia: null,
    company: '',
    interesse_educational: null,
    job: ''
})
const rules = {
    first_name: {
        required: true,
        message: t('nome_required')
    },
    last_name: {
        required: true,
        message: t('cognome_required')
    },
    email: {
        required: true,
        trigger: 'blur',
        validator: (rule: FormItemRule, value: string) => {
            if (value == '') return Error(t('email_required'))
            else {
                if (validateEmail(value)) {
                    //if (errorProfile.value && errorProfile.value.message && errorProfile.value.message == 'EMAIL_DUPLICATA' && value == lastSubmittedEmail) return Error('Questo indirizzo email è già stato utilizzato')
                    return true
                }
                else return Error(t('error_email_non_valida'))
            }
        }
    },
    citta: {
        required: props.modulo.tipo_form == 'trade_news' ? true : false,
        message: t('citta_required')
    },
    nazione: {
        required: true,
        message: t('nazione_required')
    },
    privacy: {
        required: true,
        validator: (rule: FormItemRule, value: boolean) => {
            if (value === false) return Error(t('campo_required'))
            else return true
        }
    },
    scuola: {
        required: props.modulo.tipo_form == 'travel_news' && props.modulo.target == 'scolastica' ? true : false,
        message: t('nome_scuola_required')
    },
    scuola_tipologia: {
        required: props.modulo.tipo_form == 'travel_news' && props.modulo.target == 'scolastica' ? true : false,
        message: t('tipologia_scuola_required')
    },
    company: {
        required: props.modulo.tipo_form == 'trade_news' || props.modulo.tipo_form == 'mice_news' ? true : false,
        message: t('societa_required')
    }
}

const { submit: submitNewsletter, inProgress: inProgress, error } = useSubmit(
    () => {

        return iscrizioneNewsletter(props.modulo.tipo_form, props.modulo.target, props.modulo.iat, data)

    },
    {
        onSuccess: () => {
            data.first_name = ''
            data.last_name = ''
            data.email = ''
            data.citta = ''
            data.nazione = null
            data.privacy = false
            data.scuola = ''
            data.scuola_tipologia = null
            data.company = ''
            data.interesse_educational = null
            data.job = ''

            dialog.success({
                title: t('newsletter_success'),
                iconPlacement: 'top',
                positiveText: 'OK'
            })
        },
        onError: () => {
            dialog.error({
                class: 'errorDialog',
                title: t('newsletter_error'),
                iconPlacement: 'top',
                positiveText: 'OK'
            })
            //profileForm.value?.validate()
        }
    }
);

const validateForm = (e: Event) => {
    e.preventDefault()
    newsletterForm.value?.validate((formErrors: any) => {
        if (!formErrors) { submitNewsletter() }
    })
}
</script>

<style lang="scss" scoped>
.moduloNewsletter {

    .newsletterBox {
        border-radius: var(--size-header-radius);
        background-color: var(--color-background-box);
        margin: var(--margin-double) 0;

        .innerCont {
            padding: var(--margin-default);
        }

        .inputGroup {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: var(--margin-default);
        }

        .buttonCont {
            padding: var(--margin-default) 0;

            &:deep(button) {
                min-width: 200px;
            }
        }

        &:deep(.n-input) {
            --n-border: 1px solid var(--color-border);
            --n-border-hover: 1px solid var(--color-border);
            --n-border-focus: 1px solid var(--color-border);

            .n-input__prefix {
                margin-right: 20px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .moduloNewsletter {
        .newsletterBox {
            .inputGroup {
                display: flex;
                flex-direction: column;
                gap: 0;
            }
        }
    }
}
</style>
<template>
    <div>
        <NuxtLinkLocale :to="{ name: 'acquista-visite-guidate-id', params: { id: visita.id } }" :custom="true" v-if="v && !v.esaurito">
            <template v-slot="{ navigate }">
                <NButton size="medium" type="primary" round @click="navigate">{{ $t('prenota') }}</NButton>
            </template>
        </NuxtLinkLocale>
        <NButton disabled size="medium" type="primary" round @click="navigate" v-else-if="v && v.esaurito">{{ $t('button_posti_esauriti') }}</NButton>
    </div>
</template>

<script lang="ts" setup>
import { NButton } from 'naive-ui';
const { locale } = useI18n()
const directus = useDirectus()
const props = defineProps<{ visita: any }>()

const { data: v } = useLazyAsyncData<VisitaGuidata>(`visite-guidate-${props.visita.id}`, () => directus(`/visite-guidate/${props.visita.id}/${locale.value}/disponibilita`))
</script>
<template>
    <section class="moduloExtra">
        <div class="cont">

            <div class="boxPrenotaGiro" v-if="modulo.tipologia == 'prenota_giro'">
                <iframe v-if="localeProperties.code == 'it'" src="https://gruppi.turismotorino.com/index-it2.php" frameborder="0"></iframe>
                <iframe v-else-if="localeProperties.code == 'en'" src="https://gruppi.turismotorino.com/index-en2.php" frameborder="0"></iframe>
                <iframe v-else-if="localeProperties.code == 'fr'" src="https://gruppi.turismotorino.com/index-fr2.php" frameborder="0"></iframe>
            </div>

            <OggettoCookiePolicy v-else-if="modulo.tipologia == 'cookie'" />

        </div>
    </section>
</template>

<script lang="ts" setup>
const { localeProperties } = useI18n()
defineProps<{ modulo: any }>()
</script>

<style lang="scss" scoped>
.moduloExtra {

    .cont {

        .boxPrenotaGiro {
            position: relative;
            width: 100%;
            height: 800px;
            background-color: var(--color-background-box);
            border-radius: var(--size-card-radius);
            overflow: hidden;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
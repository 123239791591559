export const useSearch = () => {

    const { api } = useRuntimeConfig().public
    const { locale } = useI18n()

    const searchHeader = (text: string): Promise<any> => {
        return $fetch(`${api}search/ttp-frontend-${locale.value}/header`, {
            method: 'POST',
            body: JSON.stringify({
                q: text
            })
        })
    }

    const searchText = (text: string, filter?: string | null, target?: string | null, page?: number | null): Promise<any> => {
        return $fetch(`${api}search/ttp-frontend-${locale.value}`, {
            method: 'POST',
            body: JSON.stringify({
                q: text,
                filter: filter,
                target: target,
                page: page
            })
        })
    }

    const searchRisorse = (text: string): Promise<any> => {
        return $fetch(`${api}search/ttp-frontend-${locale.value}/risorse`, {
            method: 'POST',
            body: JSON.stringify({
                q: text
            })
        })
    }

    const searchRisorseDesign = (aree?: string[]): Promise<any> => {
        return $fetch(`${api}search/design-map/${locale.value}`, {
            method: 'POST',
            body: JSON.stringify({
                aree: aree || undefined
            })
        })
    }

    const searchEventiPacchetti = (text: string): Promise<any> => {
        return $fetch(`${api}search/ttp-frontend-${locale.value}/eventi-pacchetti`, {
            method: 'POST',
            body: JSON.stringify({
                q: text
            })
        })
    }
    const searchMacroeventi = (text: string): Promise<any> => {
        return $fetch(`${api}search/ttp-frontend-${locale.value}/macroeventi`, {
            method: 'POST',
            body: JSON.stringify({
                q: text
            })
        })
    }
    const searchSottoeventi = (text: string): Promise<any> => {
        return $fetch(`${api}search/ttp-frontend-${locale.value}/sottoeventi`, {
            method: 'POST',
            body: JSON.stringify({
                q: text
            })
        })
    }
    const searchAderisce = (text: string): Promise<any> => {
        return $fetch(`${api}search/ttp-frontend-${locale.value}/aderisce-a`, {
            method: 'POST',
            body: JSON.stringify({
                q: text
            })
        })
    }

    const searchAgenda = (text?: string | null, range?: string | null, comune?: string | null, categoria?: string | null, tipologia?: string | null, target?: string | null, lat?: number | null, lng?: number | null, type?: string | null, page?: number | null): Promise<any> => {
        if (range || comune || categoria || target || lat || lng || (page && page > 1)) {
            return $fetch(`${api}search/ttp-frontend-${locale.value}/agenda`, {
                method: 'POST',
                body: JSON.stringify({
                    q: text,
                    range: range,
                    categoria: categoria,
                    tipologia: tipologia,
                    target: target,
                    lat: lat,
                    lng: lng,
                    type: type,
                    page: page
                })
            })
        } else {
            return $fetch(`${api}search/ttp-frontend-${locale.value}/agenda`, {
                method: 'POST'
            })
        }
    }
    const searchDoveMangiare = (text?: string | null, comune?: string | null, loc?: string | null, cucina?: string | null, prezzo?: string | null, target?: string | null, lat?: number | null, lng?: number | null, aderisce?: string | null, page?: number | null): Promise<any> => {
        if (text || comune || loc || cucina || prezzo || target || lat || lng || aderisce || (page && page > 1)) {
            return $fetch(`${api}search/ttp-frontend-${locale.value}/ristorazione`, {
                method: 'POST',
                body: JSON.stringify({
                    q: text,
                    locale: loc,
                    cucina: cucina,
                    prezzo: prezzo,
                    target: target,
                    comune: comune,
                    lat: lat,
                    lng: lng,
                    aderisce: aderisce,
                    page: page
                })
            })
        } else {
            return $fetch(`${api}search/ttp-frontend-${locale.value}/ristorazione`, {
                method: 'POST'
            })
        }
    }
    const searchDoveDormire = (text?: string | null, comune?: string | null, tipologia?: string | null, categoria?: string | null, target?: string | null, lat?: number | null, lng?: number | null, page?: number | null): Promise<any> => {
        if (text || comune || tipologia || categoria || target || lat || lng || (page && page > 1)) {
            return $fetch(`${api}search/ttp-frontend-${locale.value}/ricettivita`, {
                method: 'POST',
                body: JSON.stringify({
                    q: text,
                    tipologia: tipologia,
                    categoria: categoria,
                    target: target,
                    comune: comune,
                    lat: lat,
                    lng: lng,
                    page: page
                })
            })
        } else {
            return $fetch(`${api}search/ttp-frontend-${locale.value}/ricettivita`, {
                method: 'POST'
            })
        }
    }

    const searchMeilimoduleCardsGrid = (formula: any): Promise<any> => {
        return $fetch(`${api}search/ttp-frontend-${locale.value}/meilimodule/grid`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formula)
        })
    }
    const searchMeilimoduleCardsLista = (formula: any, page: number): Promise<any> => {
        const payload = formula
        payload.page = page

        return $fetch(`${api}search/ttp-frontend-${locale.value}/meilimodule/lista`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        })
    }
    const searchMeilimoduleCardsMap = (formula: any): Promise<any> => {
        return $fetch(`${api}search/ttp-frontend-${locale.value}/meilimodule/map`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formula)
        })
    }

    return {
        searchHeader,
        searchText,
        searchAgenda,
        searchRisorse,
        searchRisorseDesign,
        searchEventiPacchetti,
        searchMacroeventi,
        searchSottoeventi,
        searchAderisce,
        searchDoveMangiare,
        searchDoveDormire,

        searchMeilimoduleCardsGrid,
        searchMeilimoduleCardsLista,
        searchMeilimoduleCardsMap
    }
}
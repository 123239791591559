<template>
    <article v-if="attivita">

        <section class="attivitaInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBody v-if="attivita.translations && attivita.translations[0]?.body" :body="attivita.translations[0]?.body" />
                        <ModuloBody v-if="attivita.translations && attivita.translations[0]?.periodo_consigliato" :body="attivita.translations[0]?.periodo_consigliato" :titolo="$t('periodo_consigliato')" />

                        <!-- IMMAGINI -->
                        <ModuloImmagine v-if="attivita.media && attivita.media.length == 1" :image="attivita.media[0]" />
                        <ModuloMedia v-else-if="attivita.media && attivita.media.length > 1" :media="attivita.media" />

                        <!-- CONTATTI -->
                        <SezioneShare />
                        <LazySkipHydration>
                            <LazySezioneContatti :contatti="attivita" />
                        </LazySkipHydration>

                        <!-- ORARI / TARIFFE / INFORMAZIONI -->
                        <SezioneAccordionAttivita :attivita="attivita" />

                    </div>
                    <aside>

                        <!-- PRODOTTI -->
                        <ListaSideProdotti v-if="attivita.aderisce_a && attivita.aderisce_a.length > 0" :prodotti="attivita.aderisce_a" />

                    </aside>
                </div>
            </div>
        </section>

        <!-- MAPPA -->
        <MappaCoordinate v-if="attivita.geolocalizzazione && attivita.geolocalizzazione.coordinates" :latitude="attivita.geolocalizzazione.coordinates[1]" :longitude="attivita.geolocalizzazione.coordinates[0]" />

        <!-- OPERATORE -->
        <ListaOperatore v-if="attivita.operatore" :operatore="attivita.operatore" type="operatori_outdoor" />

        <!-- ACCORDION TARGET -->
        <SezioneAccordionTarget :risorsa="attivita" />

        <!-- EVENTI / POTREBBE INTERESSARTI -->
        <ModuloCarousel v-if="moduloRelated" :modulo="moduloRelated" size="small" />

        <!-- PAGINE COLLEGATE -->
        <ListaPagineCollegate v-if="attivita.pagine_collegate && attivita.pagine_collegate.length > 0" :pagine="attivita.pagine_collegate" />

    </article>
</template>

<script lang="ts" setup>
const { localeProperties, t } = useI18n()
const { findStartDate, findEndDate } = useSchema()
const { createAttivitaRoutes, dizionario } = useSitemap()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()
const props = defineProps<{ attivita: any }>()

const moduloRelated = computed(() => {
    if (props.attivita.risorse && props.attivita.risorse.length > 0) {

        const risorse = props.attivita.risorse && props.attivita.risorse.length > 0 ? props.attivita.risorse : []

        return {
            contenuto: 'manuale',
            translations: [
                {
                    titolo: t('la_proposta_comprende')
                }
            ],
            card: [
                ...risorse.map((r: any) => {
                    return {
                        item: {
                            __typename: 'risorse',
                            ...r.risorse_id
                        }
                    }
                })
            ]
        }
    } else return null
})

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }]
});
useSeoMeta({
    title: () => props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_title ? `${props.attivita.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_title ? `${props.attivita.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_title ? `${props.attivita.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,

    description: () => props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_description ? props.attivita.translations[0].og_description : null,
    ogDescription: () => props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_description ? props.attivita.translations[0].og_description : null,
    twitterDescription: () => props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_description ? props.attivita.translations[0].og_description : null,

    ogImage: () => props.attivita && props.attivita.cover && props.attivita.cover.height && props.attivita.cover.width ? `${directusUrl}assets/${props.attivita.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.attivita && props.attivita.cover && props.attivita.cover.height && props.attivita.cover.width ? `${directusUrl}assets/${props.attivita.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

const attivitaRoutes = createAttivitaRoutes(props.attivita)
if (attivitaRoutes) setI18nParams(attivitaRoutes)

const itemListElement = computed(() => {
    const elements = <any[]>[]
    const parents = <{ slug: string, titolo: string }[]>[]
    const urls = <string[]>[]

    let hasMoreParents = true
    let currentParent = props.attivita.tipologia.pagina

    if (currentParent) {
        while (hasMoreParents) {
            if (currentParent.translations && currentParent.translations.length > 0) {
                const localeParentTranslation = currentParent.translations.find((t: any) => t.languages_code.code == localeProperties.value.code)
                if (localeParentTranslation && localeParentTranslation.slug) {
                    parents.push(localeParentTranslation)
                }
            }

            if (currentParent.parent) currentParent = currentParent.parent
            else hasMoreParents = false
        }
    }

    for (const p of parents.reverse()) {
        urls.push(p.slug)
        elements.push({
            '@type': 'ListItem',
            position: urls.length,
            name: p.titolo,
            item: `https://turismotorino.org/${urls.join('/')}`
        })
    }

    elements.push({
        '@type': 'ListItem',
        position: urls.length + 1,
        name: dizionario.titoloAttivita[localeProperties.value.code as 'it' | 'en' | 'fr'],
        item: `https://turismotorino.org/${urls.join('/')}/${dizionario.linkAttivita[localeProperties.value.code as 'it' | 'en' | 'fr']}`
    })
    elements.push({
        '@type': 'ListItem',
        position: urls.length + 2,
        name: props.attivita.translations?.[0]?.titolo,
        item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
    })

    return elements
})

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            'itemListElement': itemListElement.value
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_title ? props.attivita.translations[0].og_title : undefined,
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.attivita && props.attivita.cover && props.attivita.cover.height && props.attivita.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.attivita.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.attivita.cover.height * 1600 / props.attivita.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.attivita.cover.title
            } : undefined,
            datePublished: props.attivita && props.attivita.date_created ? new Date(props.attivita.date_created).toJSON() : undefined,
            dateModified: props.attivita && props.attivita.date_updated ? new Date(props.attivita.date_updated).toJSON() : undefined,
            description: props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_description ? props.attivita.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org/${localeProperties.value.code}`
                    ]
                }
            ]
        },
        {
            '@type': 'Event',
            '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#event`,
            name: props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_title ? props.attivita.translations[0].og_title : undefined,
            description: props.attivita && props.attivita.translations && props.attivita.translations[0]?.og_description ? props.attivita.translations[0].og_description : undefined,
            image: props.attivita.media ? props.attivita.media.map((m: any) => `${directusUrl}assets/${m.directus_files_id.id}?width=1600&format=jpeg`) : undefined,
            url: props.attivita.link_esterni && props.attivita.link_esterni.filter((l: any) => l.tipologia == 'website').length > 0 ? props.attivita.link_esterni.filter((l: any) => l.tipologia == 'website')[0].link : undefined,
            eventStatus: "https://schema.org/EventScheduled",
            eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
            location: props.attivita.indirizzo_partenza ? {
                "@type": "Place",
                address: {
                    "@type": "PostalAddress",
                    streetAddress: props.attivita.indirizzo_partenza,
                    addressLocality: props.attivita.comune_partenza ? props.attivita.comune_partenza.nome : undefined,
                    postalCode: props.attivita.cap_partenza,
                    addressCountry: "IT"
                }
            } : undefined,
            offers: props.attivita.link_esterni && props.attivita.link_esterni.filter((l: any) => l.tipologia == 'acquista' || l.tipologia == 'prenota').length > 0 ?
                props.attivita.link_esterni.filter((l: any) => l.tipologia == 'acquista' || l.tipologia == 'prenota').map((l: any) => ({
                    '@type': 'Offer',
                    url: l.link,
                })) : undefined,
            sameAs: props.attivita.link_esterni ? props.attivita.link_esterni.filter((l: any) => l.tipologia != 'acquista' && l.tipologia != 'prenota').map((l: any) => l.link) : undefined,
            startDate: findStartDate(props.attivita.date),
            endDate: findEndDate(props.attivita.date)
        }
    ]
}))
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .attivitaInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .attivitaInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>
<template>
    <div :class="['sideProdotto', prodotto.tipologia_prodotto]">
        <div class="title3" v-if="prodotto && prodotto.translations && prodotto.tipologia_prodotto != 'progetto'">{{ prodotto.translations[0].titolo.replace('o+P', 'o + P') }}</div>

        <div class="laVoglioBox paragraph" v-if="prodotto.tipologia_prodotto == 'card'">{{ $t('la_voglio_ora') }}</div>
        <div class="laVoglioBox paragraph" v-else>{{ $t('scopri_di_piu') }}</div>

        <Image v-if="prodotto.cover_oggetto_aderisce" :image="prodotto.cover_oggetto_aderisce" :width="220" :height="420" :mobileWidth="364" :mobileHeight="496" imageClass="cover" :key="prodotto.cover_oggetto_aderisce.id" />
        <NuxtLinkLocale class="overlink" :to="link"></NuxtLinkLocale>
    </div>
</template>

<script lang="ts" setup>
const { getCardLink } = useCard()
const props = defineProps<{ prodotto: any }>()

const link = computed(() => {
    return getCardLink({ item: props.prodotto }, 'prodotti').address
})
</script>

<style lang="scss" scoped>
.sideProdotto {
    width: 220px;
    height: 420px;
    background-color: var(--color-background-image);

    position: relative;

    border-radius: 30px;
    overflow: hidden;

    .title3 {
        position: absolute;
        top: 25px;
        left: 25px;
        right: 25px;
        color: white;
        z-index: 1;
    }

    .laVoglioBox {
        background-color: var(--color-background-box);
        position: absolute;
        bottom: var(--margin-default);
        left: var(--margin-default);
        right: var(--margin-default);
        padding: var(--margin-gap);
        z-index: 1;

        border-radius: 10px;
        box-sizing: border-box;
        text-align: center;
    }

    &.progetto {
        height: 300px;

        .laVoglioBox {
            background-color: var(--color-azure);
        }
    }
}

@media screen and (max-width: 1060px) {
    .sideProdotto {
        width: 100%;
    }
    
}

@media screen and (max-width: 600px) {
    .sideProdotto {
        width: 100%;
        height: calc((100vw - var(--margin-default) * 2 - var(--margin-gap)) / 2 * 420 / 220);

        &.progetto {
            height: calc((100vw - var(--margin-default) * 2 - var(--margin-gap)) / 2 * 300 / 220);
        }
    }
}
</style>
export const useSitemap = () => {

    const dizionario = {
        linkVisita: {
            it: 'visita',
            en: 'visit',
            fr: 'decouvrir'
        },
        titoloVisita: {
            it: 'Visita',
            en: 'Visit',
            fr: 'Découvrir'
        },

        linkPianifica: {
            it: 'pianifica-la-tua-visita',
            en: 'plan-your-trip',
            fr: 'preparer-ton-sejour'
        },
        titoloPianifica: {
            it: 'Pianifica la tua visita',
            en: 'Plan your visit',
            fr: 'Planifiez votre visite'
        },

        linkProdotti: {
            it: 'proposte-turistiche',
            en: 'tourist-products',
            fr: 'produits-touristiques'
        },
        titoloProdotti: {
            it: 'Proposte turistiche',
            en: 'Tourist products',
            fr: 'Produits touristiques'
        },

        linkEventi: {
            it: 'eventi',
            en: 'events',
            fr: 'evenements'
        },
        titoloEventi: {
            it: 'Eventi',
            en: 'Events',
            fr: 'Événements'
        },

        linkVisiteGuidate: {
            it: 'visite-guidate',
            en: 'guided-tours',
            fr: 'visites-guidees'
        },
        titoloVisiteGuidate: {
            it: 'Visite guidate',
            en: 'Visite guidate',
            fr: 'Visite guidée'
        },

        linkMusica: {
            it: 'musica',
            en: 'music',
            fr: 'musique'
        },
        titoloMusica: {
            it: 'Musica',
            en: 'Music',
            fr: 'Musique'
        },

        linkAttivita: {
            it: 'attivita',
            en: 'activities',
            fr: 'activites'
        },
        titoloAttivita: {
            it: 'Attività',
            en: 'Activities',
            fr: 'Activités'
        },

        linkPacchetti: {
            it: 'pacchetti-e-servizi-turistici',
            en: 'tourist-packages-and-services',
            fr: 'paquets-et-services-touristiques'
        },
        titoloPacchetti: {
            it: 'Pacchetti e servizi turistici',
            en: 'Tourist packages and services',
            fr: 'Paquets et services touristiques'
        },

        linkOperatoriOutdoor: {
            it: 'operatori',
            en: 'operators',
            fr: 'operateurs'
        },
        titoloOperatoriOutdoor: {
            it: 'Operatori outdoor',
            en: 'Outdoor operators',
            fr: 'Opérateurs extérieurs'
        },
        linkOperatoriIncoming: {
            it: 'partner-incoming',
            en: 'incoming-partners',
            fr: 'agences-receptives'
        },
    }

    const createPagineFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            const filter = [...routeslug].filter((slug) => slug && slug != '').reverse().map((slug, index) => {
                return new Array(index)
                    .fill('parent')
                    .reduceRight((obj, key) => ({ [key]: obj }), { translations: { slug: { _eq: slug } } })
            })
            return { _and: [...filter, { status: { _eq: "published" } }] }

        } else return { _and: [{ translations: { slug: { _eq: routeslug as string } } }, { status: { _eq: "published" } }] }
    }
    const createRisorseFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if (routeslug[0] != 'convention-bureau') {
                const filter = [...routeslug].filter((slug) => slug && slug != '').reverse().map((slug, index) => {
                    if (index == 0) return { translations: { slug: { _eq: slug } } }
                    else return ['tipologia', 'pagina', ...new Array(index - 1).fill('parent')]
                        .reduceRight((obj, key) => ({ [key]: obj }), { translations: { slug: { _eq: slug } } })
                })
                return {
                    filter: { _and: [...filter, { status: { _eq: "published" } }] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return { filter: { _and: [{ translations: { slug: { _eq: routeslug as string } } }, { status: { _eq: "published" } }] }, skip: false }
    }
    const createRisorseConventionFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if (routeslug[0] == 'convention-bureau' && routeslug[1] != 'news') {
                const filter = [...routeslug].filter((slug) => slug && slug != '').reverse().map((slug, index) => {
                    if (index == 0) return { translations: { slug: { _eq: slug } } }
                    else return ['tipologia_mice', 'pagina', ...new Array(index - 1).fill('parent')]
                        .reduceRight((obj, key) => ({ [key]: obj }), { translations: { slug: { _eq: slug } } })
                })

                return {
                    filter: { _and: [...filter, { status: { _eq: "published" } }] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }
    const createEventiFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if (routeslug.length == 3 && [dizionario.linkVisita.it, dizionario.linkVisita.en, dizionario.linkVisita.fr].includes(routeslug[0]) && [dizionario.linkEventi.it, dizionario.linkEventi.en, dizionario.linkEventi.fr].includes(routeslug[1])) {
                return {
                    filter: { _and: [{ translations: { slug: { _eq: routeslug[2] } } }, { status: { _eq: "published" } }] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }
    const createSpettacoliFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if (routeslug.length == 4 && [dizionario.linkVisita.it, dizionario.linkVisita.en, dizionario.linkVisita.fr].includes(routeslug[0]) && [dizionario.linkEventi.it, dizionario.linkEventi.en, dizionario.linkEventi.fr].includes(routeslug[1]) && ['musica', 'music', 'musique'].includes(routeslug[2])) {
                return {
                    filter: { _and: [{ translations: { slug: { _eq: routeslug[3] } } }, { status: { _eq: "published" } }] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }
    const createVisiteGuidateFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if (routeslug.length == 4 && [dizionario.linkVisita.it, dizionario.linkVisita.en, dizionario.linkVisita.fr].includes(routeslug[0]) && [dizionario.linkEventi.it, dizionario.linkEventi.en, dizionario.linkEventi.fr].includes(routeslug[1]) && [dizionario.linkVisiteGuidate.it, dizionario.linkVisiteGuidate.en, dizionario.linkVisiteGuidate.fr].includes(routeslug[2])) {
                return {
                    filter: { _and: [{ translations: { slug: { _eq: routeslug[3] } } }, { status: { _eq: "published" } }] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }
    const createProdottiFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if (routeslug.length == 4 && [dizionario.linkVisita.it, dizionario.linkVisita.en, dizionario.linkVisita.fr].includes(routeslug[0]) && [dizionario.linkProdotti.it, dizionario.linkProdotti.en, dizionario.linkProdotti.fr].includes(routeslug[2])) {
                return {
                    filter: { _and: [{ translations: { slug: { _eq: routeslug[3] } } }, { status: { _eq: "published" } }] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }
    const createAttivitaFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if ([dizionario.linkAttivita.it, dizionario.linkAttivita.en, dizionario.linkAttivita.fr].includes(routeslug[routeslug.length - 2])) {
                const filter = [...routeslug.filter((slug) => slug && slug != '' && slug != dizionario.linkAttivita.it && slug != dizionario.linkAttivita.en && slug != dizionario.linkAttivita.fr)].reverse().map((slug, index) => {
                    if (index == 0) return { translations: { slug: { _eq: slug } } }
                    else return ['tipologia', 'pagina', ...new Array(index - 1).fill('parent')]
                        .reduceRight((obj, key) => ({ [key]: obj }), { translations: { slug: { _eq: slug } } })
                })
                return {
                    filter: { _and: [...filter, { status: { _eq: "published" } }] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }
    const createPacchettiFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if ([dizionario.linkPacchetti.it, dizionario.linkPacchetti.en, dizionario.linkPacchetti.fr].includes(routeslug[routeslug.length - 2])) {
                return {
                    filter: { _and: [{ translations: { slug: { _eq: routeslug[3] } } }, { status: { _eq: "published" } }] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }
    const createOperatoreOutdoorFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if ([dizionario.linkOperatoriOutdoor.it, dizionario.linkOperatoriOutdoor.en, dizionario.linkOperatoriOutdoor.fr].includes(routeslug[routeslug.length - 2])) {
                const filter = [...routeslug.filter((slug) => slug && slug != '' && slug != 'operatori')].reverse().map((slug, index) => {
                    if (index == 0) return { slug_operatore_outdoor: { _eq: slug } }
                    else return ['pagina_operatore_outdoor', ...new Array(index - 1).fill('parent')]
                        .reduceRight((obj, key) => ({ [key]: obj }), { translations: { slug: { _eq: slug } } })
                })
                return {
                    filter: { _and: [...filter] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }
    const createOperatoreIncomingFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if ([dizionario.linkOperatoriIncoming.it, dizionario.linkOperatoriIncoming.en, dizionario.linkOperatoriIncoming.fr].includes(routeslug[routeslug.length - 2])) {
                const filter = routeslug.reverse().map((slug, index) => {
                    if (index == 0) return { slug_operatore_incoming: { _eq: slug } }
                    else return ['pagina_operatore_incoming', ...new Array(index - 1).fill('parent')]
                        .reduceRight((obj, key) => ({ [key]: obj }), { translations: { slug: { _eq: slug } } })
                })
                return {
                    filter: { _and: [...filter] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }
    const createNewsConventionFilter = (routeslug: string | string[]) => {
        if (Array.isArray(routeslug)) {
            if (routeslug[0] == 'convention-bureau' && routeslug[1] == 'news' && routeslug[2] && routeslug[2].length > 0 && routeslug.length == 3) {
                return {
                    filter: { _and: [{ translations: { slug: { _eq: routeslug[2] } } }, { status: { _eq: "published" } }] },
                    skip: false
                }
            } else return {
                filter: {},
                skip: true
            }
        } else return {
            filter: {},
            skip: true
        }
    }


    const localeParentRoutes = (translations: any, parents: any, locale: string, add?: string) => {
        const localeTranslation = translations.find((t: any) => t.languages_code.code == locale)

        if (localeTranslation) {
            let hasMoreParents = true
            let currentParent = parents
            let url = []

            if (currentParent) {
                while (hasMoreParents) {
                    if (!currentParent.translations || currentParent.translations.length == 0) return '/'
                    const localeParentTranslation = currentParent.translations.find((t: any) => t.languages_code.code == locale)

                    if (localeParentTranslation && localeParentTranslation.slug) {
                        url.push(localeParentTranslation.slug)
                    } else return null

                    if (currentParent.parent) currentParent = currentParent.parent
                    else hasMoreParents = false
                }
            }
            if (add) return [...url.reverse(), add, localeTranslation.slug]
            else return [...url.reverse(), localeTranslation.slug]
        } else return null
    }
    const localeOperatoreRoutes = (operatore: any, pagina: string, slug: string, locale: string, add?: string) => {
        if (operatore) {
            if (operatore[pagina] && operatore[slug]) {
                let hasMoreParents = true
                let currentParent = operatore[pagina]
                let url = []

                if (currentParent) {
                    while (hasMoreParents) {
                        if (!currentParent.translations || currentParent.translations.length == 0) return '/'
                        const localeParentTranslation = currentParent.translations.find((t: any) => t.languages_code.code == locale)

                        if (localeParentTranslation && localeParentTranslation.slug) {
                            url.push(localeParentTranslation.slug)
                        } else return null

                        if (currentParent.parent) currentParent = currentParent.parent
                        else hasMoreParents = false
                    }
                }
                if (add) return `${url.reverse().join('/')}/${add}/${operatore[slug]}`
                else return `${url.reverse().join('/')}/${operatore[slug]}`
            }
        } else return null
    }


    const createPagineRoutes = (pagina: any) => {
        if (pagina && pagina.alt_translations) {
            const translations = pagina.alt_translations
            const parents = pagina.parent

            const it = pagina.lingue.includes('it') ? localeParentRoutes(translations, parents, 'it') : undefined
            const en = pagina.lingue.includes('en') ? localeParentRoutes(translations, parents, 'en') : undefined
            const fr = pagina.lingue.includes('fr') ? localeParentRoutes(translations, parents, 'fr') : undefined

            return {
                it: it ? { slug: it } : { slug: ['traduzione-mancante'] },
                en: en ? { slug: en } : { slug: ['missing-translation'] },
                fr: fr ? { slug: fr } : { slug: ['traduction-manquante'] },
            }
        } else return null
    }
    const createRisorseRoutes = (risorsa: any) => {
        if (risorsa && risorsa.alt_translations) {
            const translations = risorsa.alt_translations
            const parents = risorsa.tipologia?.pagina

            const it = localeParentRoutes(translations, parents, 'it')
            const en = localeParentRoutes(translations, parents, 'en')
            const fr = localeParentRoutes(translations, parents, 'fr')

            return {
                it: it ? { slug: it } : { slug: ['traduzione-mancante'] },
                en: en ? { slug: en } : { slug: ['missing-translation'] },
                fr: fr ? { slug: fr } : { slug: ['traduction-manquante'] },
            }
        } else return null
    }
    const createRisorseConventionRoutes = (risorsa: any) => {
        if (risorsa && risorsa.alt_translations) {
            const translations = risorsa.alt_translations
            const parents = risorsa.tipologia_mice?.pagina

            const it = localeParentRoutes(translations, parents, 'it')
            const en = localeParentRoutes(translations, parents, 'en')
            const fr = localeParentRoutes(translations, parents, 'fr')

            return {
                it: it ? { slug: it } : { slug: ['traduzione-mancante'] },
                en: en ? { slug: en } : { slug: ['missing-translation'] },
                fr: fr ? { slug: fr } : { slug: ['traduction-manquante'] },
            }
        } else return null
    }
    const createProdottiRoutes = (prodotto: any) => {
        if (prodotto) {
            const translations = prodotto.alt_translations

            const slug_it = translations.find((t: any) => t.languages_code.code == 'it')?.slug
            const slug_en = translations.find((t: any) => t.languages_code.code == 'en')?.slug
            const slug_fr = translations.find((t: any) => t.languages_code.code == 'fr')?.slug

            return {
                it: slug_it ? { slug: `${dizionario.linkVisita.it}/${dizionario.linkPianifica.it}/${dizionario.linkProdotti.it}/${slug_it}` } : { slug: ['traduzione-mancante'] },
                en: slug_en ? { slug: `${dizionario.linkVisita.en}/${dizionario.linkPianifica.en}/${dizionario.linkProdotti.en}/${slug_en}` } : { slug: ['missing-translation'] },
                fr: slug_fr ? { slug: `${dizionario.linkVisita.fr}/${dizionario.linkPianifica.fr}/${dizionario.linkProdotti.fr}/${slug_fr}` } : { slug: ['traduction-manquante'] },
            }
        } else return null
    }
    const createEventiRoutes = (evento: any) => {
        if (evento) {
            const translations = evento.alt_translations

            const slug_it = translations.find((t: any) => t.languages_code.code == 'it')?.slug
            const slug_en = translations.find((t: any) => t.languages_code.code == 'en')?.slug
            const slug_fr = translations.find((t: any) => t.languages_code.code == 'fr')?.slug

            return {
                it: slug_it ? { slug: `${dizionario.linkVisita.it}/${dizionario.linkEventi.it}/${slug_it}` } : { slug: ['traduzione-mancante'] },
                en: slug_en ? { slug: `${dizionario.linkVisita.en}/${dizionario.linkEventi.en}/${slug_en}` } : { slug: ['missing-translation'] },
                fr: slug_fr ? { slug: `${dizionario.linkVisita.fr}/${dizionario.linkEventi.fr}/${slug_fr}` } : { slug: ['traduction-manquante'] },
            }
        } else return null
    }
    const createAttivitaRoutes = (attivita: any) => {
        if (attivita) {
            const translations = attivita.alt_translations
            const tipologia = attivita.tipologia.pagina

            const it = localeParentRoutes(translations, tipologia, 'it', dizionario.linkAttivita.it)
            const en = localeParentRoutes(translations, tipologia, 'en', dizionario.linkAttivita.en)
            const fr = localeParentRoutes(translations, tipologia, 'fr', dizionario.linkAttivita.fr)

            return {
                it: it ? { slug: it } : { slug: ['traduzione-mancante'] },
                en: en ? { slug: en } : { slug: ['missing-translation'] },
                fr: fr ? { slug: fr } : { slug: ['traduction-manquante'] },
            }
        } else return null
    }
    const createPacchettiRoutes = (pacchetto: any) => {
        if (pacchetto) {
            const translations = pacchetto.alt_translations

            const slug_it = translations.find((t: any) => t.languages_code.code == 'it')?.slug
            const slug_en = translations.find((t: any) => t.languages_code.code == 'en')?.slug
            const slug_fr = translations.find((t: any) => t.languages_code.code == 'fr')?.slug

            return {
                it: slug_it ? { slug: `${dizionario.linkVisita.it}/${dizionario.linkPianifica.it}/${dizionario.linkPacchetti.it}/${slug_it}` } : { slug: ['traduzione-mancante'] },
                en: slug_en ? { slug: `${dizionario.linkVisita.en}/${dizionario.linkPianifica.en}/${dizionario.linkPacchetti.en}/${slug_en}` } : { slug: ['missing-translation'] },
                fr: slug_fr ? { slug: `${dizionario.linkVisita.fr}/${dizionario.linkPianifica.fr}/${dizionario.linkPacchetti.fr}/${slug_fr}` } : { slug: ['traduction-manquante'] },
            }
        } else return null
    }
    const createNewsConventionRoutes = (newsConvention: any) => {
        if (newsConvention) {
            const translations = newsConvention.alt_translations

            const slug_it = translations.find((t: any) => t.languages_code.code == 'it')?.slug
            const slug_en = translations.find((t: any) => t.languages_code.code == 'en')?.slug

            return {
                it: slug_it ? { slug: `convention-bureau/news/${slug_it}` } : { slug: ['traduzione-mancante'] },
                en: slug_en ? { slug: `convention-bureau/news/${slug_en}` } : { slug: ['missing-translation'] },
                fr: { slug: ['traduction-manquante'] }
            }
        } else return null
    }
    const createOperatoreOutdoorRoutes = (operatore: any) => {
        if (operatore) {
            const it = localeOperatoreRoutes(operatore, 'pagina_operatore_outdoor', 'slug_operatore_outdoor', 'it', dizionario.linkOperatoriOutdoor.it)
            const en = localeOperatoreRoutes(operatore, 'pagina_operatore_outdoor', 'slug_operatore_outdoor', 'en', dizionario.linkOperatoriOutdoor.en)
            const fr = localeOperatoreRoutes(operatore, 'pagina_operatore_outdoor', 'slug_operatore_outdoor', 'fr', dizionario.linkOperatoriOutdoor.fr)

            return {
                it: it ? { slug: it } : { slug: ['traduzione-mancante'] },
                en: en ? { slug: en } : { slug: ['missing-translation'] },
                fr: fr ? { slug: fr } : { slug: ['traduction-manquante'] },
            }
        }
    }
    const createOperatoreIncomingRoutes = (operatore: any) => {
        if (operatore) {
            const it = localeOperatoreRoutes(operatore, 'pagina_operatore_incoming', 'slug_operatore_incoming', 'it')
            const en = localeOperatoreRoutes(operatore, 'pagina_operatore_incoming', 'slug_operatore_incoming', 'en')
            const fr = localeOperatoreRoutes(operatore, 'pagina_operatore_incoming', 'slug_operatore_incoming', 'fr')

            return {
                it: it ? { slug: it } : { slug: ['traduzione-mancante'] },
                en: en ? { slug: en } : { slug: ['missing-translation'] },
                fr: fr ? { slug: fr } : { slug: ['traduction-manquante'] },
            }
        }
    }
    const createSpettacoliRoutes = (spettacolo: any) => {
        if (spettacolo) {
            const translations = spettacolo.alt_translations

            const slug_it = translations.find((t: any) => t.languages_code.code == 'it')?.slug
            const slug_en = translations.find((t: any) => t.languages_code.code == 'en')?.slug
            const slug_fr = translations.find((t: any) => t.languages_code.code == 'fr')?.slug

            return {
                it: slug_it ? { slug: `${dizionario.linkVisita.it}/${dizionario.linkEventi.it}/${dizionario.linkMusica.it}/${slug_it}` } : { slug: ['traduzione-mancante'] },
                en: slug_en ? { slug: `${dizionario.linkVisita.en}/${dizionario.linkEventi.en}/${dizionario.linkMusica.en}/${slug_en}` } : { slug: ['missing-translation'] },
                fr: slug_fr ? { slug: `${dizionario.linkVisita.fr}/${dizionario.linkEventi.fr}/${dizionario.linkMusica.fr}/${slug_fr}` } : { slug: ['traduction-manquante'] },
            }
        } else return null
    }
    const createVisiteGuidateRoutes = (visitaGuidata: any) => {
        if (visitaGuidata) {
            const translations = visitaGuidata.alt_translations

            const slug_it = translations.find((t: any) => t.languages_code.code == 'it')?.slug
            const slug_en = translations.find((t: any) => t.languages_code.code == 'en')?.slug
            const slug_fr = translations.find((t: any) => t.languages_code.code == 'fr')?.slug

            return {
                it: slug_it ? { slug: `${dizionario.linkVisita.it}/${dizionario.linkEventi.it}/${dizionario.linkVisiteGuidate.it}/${slug_it}` } : { slug: ['traduzione-mancante'] },
                en: slug_en ? { slug: `${dizionario.linkVisita.en}/${dizionario.linkEventi.en}/${dizionario.linkVisiteGuidate.en}/${slug_en}` } : { slug: ['missing-translation'] },
                fr: slug_fr ? { slug: `${dizionario.linkVisita.fr}/${dizionario.linkEventi.fr}/${dizionario.linkVisiteGuidate.fr}/${slug_fr}` } : { slug: ['traduction-manquante'] },
            }
        } else return null
    }

    return {
        createPagineFilter,
        createRisorseFilter,
        createRisorseConventionFilter,
        createEventiFilter,
        createSpettacoliFilter,
        createVisiteGuidateFilter,
        createProdottiFilter,
        createAttivitaFilter,
        createPacchettiFilter,
        createOperatoreOutdoorFilter,
        createOperatoreIncomingFilter,
        createNewsConventionFilter,
        createOperatoreOutdoorRoutes,
        createOperatoreIncomingRoutes,

        createPagineRoutes,
        createRisorseRoutes,
        createRisorseConventionRoutes,
        createProdottiRoutes,
        createEventiRoutes,
        createAttivitaRoutes,
        createPacchettiRoutes,
        createNewsConventionRoutes,
        createSpettacoliRoutes,
        createVisiteGuidateRoutes,

        dizionario
    }
}
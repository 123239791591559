<template>
    <div :class="['card', { convention }, { noimage: !showimage }]">
        <div class="avatarCont" v-if="showimage">
            <div v-if="imageTag" :class="['imageTag', imageTag]">{{ $t(imageTag) }}</div>
            <div class="filler"></div>
            <div :class="['avatar', { logo }, { concluso: imageTag == 'concluso' }]" v-if="cover">
                <Image :image="cover" :width="600" imageClass="cover" :key="cover.id" />
            </div>
        </div>
        <div class="info">
            <div class="text">
                <div class="tag" v-if="tag">{{ tag }}</div>
                <div class="titleline">
                    <h3 class="title3b" v-if="titolo">{{ titolo }}</h3>
                    <OggettoClassificazioneHotel v-if="categoriaRicettivita" :categoria="categoriaRicettivita" :small="true" />
                </div>
                <div class="paragraph" v-if="descrizione">
                    <p v-html="descrizione"></p>
                </div>
            </div>
            <slot name="end"></slot>
        </div>
        <a v-if="link && isExternal" class="overlink" :href="link" target="_blank" :aria-label="titolo"></a>
        <NuxtLinkLocale v-if="link && !isExternal" class="overlink" :to="link" @click="clickedLink" :aria-label="titolo"></NuxtLinkLocale>
    </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['clickedLink'])

interface Props {
    cover?: any,
    tag?: string,
    titolo?: string,
    descrizione?: string,
    link?: any,
    isExternal?: boolean,
    convention?: boolean,
    categoriaRicettivita?: string,
    showimage?: boolean,
    imageTag?: string | null,
    logo?: boolean
}
withDefaults(defineProps<Props>(), {
    convention: false,
    showimage: true,
    logo: false
})

const clickedLink = () => {
    emit('clickedLink')
}
</script>


<style lang="scss" scoped>
.card {
    position: relative;
    min-height: var(--size-card-minheight);
    background-color: var(--color-background-box);
    border-radius: var(--size-card-radius);
    overflow: hidden;

    display: grid;
    grid-template-columns: 240px 1fr;

    .avatarCont {
        position: relative;
        padding: var(--margin-default) 0 var(--margin-default) var(--margin-default);

        .imageTag {
            position: absolute;
            z-index: 1;

            font-weight: 800;
            text-transform: uppercase;

            padding: 10px 12px;
            top: 10px;
            left: 10px;
            border-radius: 8px;

            &.concluso {
                background-color: var(--color-background-gray-logo);
                color: white;
            }
        }

        .avatar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            &.logo {
                top: var(--margin-double);
                left: var(--margin-double);
                height: calc(100% - var(--margin-double) * 2);
                width: calc(100% - var(--margin-double) * 2);

                &:deep(picture) {
                    background-image: none !important;

                    img {
                        object-fit: contain;
                    }
                }
            }
            &.concluso {
                filter: saturate(0);
            }
        }

        .filler {
            width: 100%;
            padding-top: 85%;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--margin-default);
        padding: var(--margin-default) var(--margin-default) var(--margin-default) var(--margin-double);

        .text {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .tag {
                font-weight: 800;
                color: var(--color-azure-dark);
                text-transform: uppercase;
            }

            .titleline {
                display: flex;
                gap: var(--margin-gap);
            }

            .date {
                font-size: var(--text-size-small);
                line-height: var(--text-line-small);
                text-transform: uppercase;
                font-weight: 600;
                margin-top: var(--margin-tight);
            }

            a {
                text-decoration: none;
            }
        }

        .end {
            text-transform: uppercase;
        }
    }

    &.noimage {
        min-height: 0;

        .info {
            grid-column: 1 / span 2;
            padding-left: var(--margin-default);
            gap: var(--margin-double)
        }
    }

    &.convention {
        background-color: var(--color-background-blue);
        color: white;

        .info .text .tag {
            color: var(--color-azure);
        }
    }
}

@media screen and (max-width: 768px) {
    .card {
        display: flex;
        flex-direction: column;

        .avatarCont {
            width: 240px;

            .imageTag {
                top: 30px;
                left: 30px;
                border-radius: 4px;
            }

            .avatar {
                position: absolute;
                top: var(--margin-default);
                bottom: var(--margin-default);
                left: var(--margin-default);
                right: 0;
                border-radius: var(--size-card-radius-inner);
                overflow: hidden;

                width: auto;
                height: auto;

                &.logo {
                    left: var(--margin-default)
                }
            }
        }

        .info {
            padding: 0 var(--margin-default) var(--margin-default) var(--margin-default);

            .text {
                .paragraph {
                    hyphens: auto;
                }
            }
        }

        &.noimage {
            .info {
                padding-top: var(--margin-default);
            }
        }
    }
}
</style>
<template>
    <div class="cardMini">
        <div :class="['avatar', { empty: !cover }]">
            <Image v-if="cover" :image="cover" :width="600" imageClass="cover" :key="cover.id" />
        </div>
        <div class="info">
            <div class="text">
                <div class="title3" v-if="titolo">{{ titolo }}</div>
            </div>
            <slot name="end"></slot>
        </div>
        <NuxtLink v-if="link" class="overlink" :to="localeRoute(link)"></NuxtLink>
    </div>
</template>

<script lang="ts" setup>
const localeRoute = useLocaleRoute()

interface Props {
    cover?: any,
    tag?: string,
    titolo?: string,
    descrizione?: string,
    link?: any
}
defineProps<Props>()
</script>

<style lang="scss" scoped>
.cardMini {
    position: relative;
    min-height: var(--size-minicard-minheight);

    background-color: var(--color-background-box);
    border-radius: var(--size-card-radius);
    overflow: hidden;

    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 0;

    .avatar {
        position: relative;
        width: 100%;
        height: auto;
        background-color: var(--color-background-image);
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--margin-default);
        padding: var(--margin-default);

        .text {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}

@media screen and (max-width: 768px) {
    .cardMini {
        display: flex;
        flex-direction: column;

        .avatar {
            width: 180px;
            height: 140px;

            margin: var(--margin-default) 0 0 var(--margin-default);
            border-radius: var(--size-card-radius-inner);
            overflow: hidden;

            &.empty {
                display: none;
            }
        }
    }
}
</style>
export const useSchema = () => {

    const aggiungiGiornoAperto = (giorno: string, index_giorno: string, index_turni: string, d: any, openingHours: any[]) => {
        if (d[index_giorno] == 'aperto') {
            if (d[index_turni] && d[index_turni].length > 0) {
                for (const f of d[index_turni]) {
                    openingHours.push({
                        '@type': 'OpeningHoursSpecification',
                        dayOfWeek: `http://schema.org/${giorno}`,
                        opens: f.ora_inizio.substring(0, 5),
                        closes: f.ora_fine ? f.ora_fine.substring(0, 5) : undefined,
                        validFrom: d.inizio_ricorrenza ? d.inizio_ricorrenza : undefined,
                        validThrough: d.fine_ricorrenza ? d.fine_ricorrenza : undefined
                    })
                }
            } else {
                openingHours.push({
                    '@type': 'OpeningHoursSpecification',
                    dayOfWeek: 'http://schema.org/Monday',
                    opens: '00:00',
                    closes: '23:59',
                    validFrom: d.inizio_ricorrenza ? d.inizio_ricorrenza : undefined,
                    validThrough: d.fine_ricorrenza ? d.fine_ricorrenza : undefined
                })
            }
        }
    }

    const createOpeningHours = (date: Orario[]) => {
        if (date && date.length > 0) {

            const openingHours = []

            for (const d of date) {

                switch (d.ricorrenza) {
                    case 'mai':
                        if (d.fasce_orarie && d.fasce_orarie.length > 0) {
                            for (const f of d.fasce_orarie) {
                                openingHours.push({
                                    '@type': 'OpeningHoursSpecification',
                                    opens: f.ora_inizio.substring(0, 5),
                                    closes: f.ora_fine ? f.ora_fine.substring(0, 5) : undefined,
                                    validFrom: d.data_inizio ? d.data_inizio : undefined,
                                    validThrough: d.data_fine ? d.data_fine : undefined
                                })
                            }
                        } else {
                            openingHours.push({
                                '@type': 'OpeningHoursSpecification',
                                opens: '00:00',
                                closes: '23:59',
                                validFrom: d.data_inizio ? d.data_inizio : undefined,
                                validThrough: d.data_fine ? d.data_fine : undefined
                            })
                        }
                        break;
                    case 'giornaliera':
                        if (d.fasce_orarie && d.fasce_orarie.length > 0) {
                            for (const f of d.fasce_orarie) {
                                openingHours.push({
                                    '@type': 'OpeningHoursSpecification',
                                    opens: f.ora_inizio.substring(0, 5),
                                    closes: f.ora_fine.substring(0, 5),
                                    validFrom: d.inizio_ricorrenza ? d.inizio_ricorrenza : undefined,
                                    validThrough: d.fine_ricorrenza ? d.fine_ricorrenza : undefined
                                })
                            }
                        } else {
                            openingHours.push({
                                '@type': 'OpeningHoursSpecification',
                                opens: '00:00',
                                closes: '23:59',
                                validFrom: d.inizio_ricorrenza ? d.inizio_ricorrenza : undefined,
                                validThrough: d.fine_ricorrenza ? d.fine_ricorrenza : undefined
                            })
                        }
                        break;
                    case 'settimanale':

                        aggiungiGiornoAperto('Monday', 'lunedi_aperto', 'lunedi_turni', d, openingHours)
                        aggiungiGiornoAperto('Tuesday', 'martedi_aperto', 'martedi_turni', d, openingHours)
                        aggiungiGiornoAperto('Wednesday', 'mercoledi_aperto', 'mercoledi_turni', d, openingHours)
                        aggiungiGiornoAperto('Thursday', 'giovedi_aperto', 'giovedi_turni', d, openingHours)
                        aggiungiGiornoAperto('Friday', 'venerdi_aperto', 'venerdi_turni', d, openingHours)
                        aggiungiGiornoAperto('Saturday', 'sabato_aperto', 'sabato_turni', d, openingHours)
                        aggiungiGiornoAperto('Sunday', 'domenica_aperto', 'domenica_turni', d, openingHours)

                        break;
                }
            }

            if (openingHours.length > 0) return openingHours
            else return undefined
        } else return undefined
    }

    const getRisorsaType = (risorsa: any) => {
        if (risorsa.tipologia && risorsa.tipologia.tipo_business_schema) return risorsa.tipologia.tipo_business_schema
        else {
            switch (risorsa.tipo) {
                case 'ristorazione':
                    return 'FoodEstablishment'
                case 'ricettivita':
                    return 'LodgingBusiness'
                default:
                    return 'LocalBusiness'
            }
        }
    }
    const getRisorsaMiceType = (risorsa: any) => {
        if (risorsa.tipologia_mice && risorsa.tipologia_mice.tipo_business_schema) return risorsa.tipologia_mice.tipo_business_schema
        else {
            switch (risorsa.tipo) {
                case 'ristorazione':
                    return 'FoodEstablishment'
                case 'ricettivita':
                    return 'LodgingBusiness'
                default:
                    return 'LocalBusiness'
            }
        }
    }
    const getEventoType = (evento: any) => {
        if (evento.tipologie && evento.tipologie.length > 0 && evento.tipologie[0].tipologie_eventi_id.tipo_business_schema) return evento.tipologie[0].tipologie_eventi_id.tipo_business_schema
        else {
            if (evento.tipologia == 'macroevento') return 'EventSeries'
            else return 'Event'
        }
    }

    const findStartDate = (date: Orario[]) => {
        if (date && date.length > 0) {

            const dateTrovate = <string[]>[]

            for (const d of date) {
                if (d.ricorrenza == 'mai') {
                    if (d.data_inizio) dateTrovate.push(d.data_inizio as string)
                    if (d.data_fine) dateTrovate.push(d.data_fine as string)
                } else {
                    if (d.inizio_ricorrenza) dateTrovate.push(d.inizio_ricorrenza as string)
                    else dateTrovate.push('2010-01-01')
                    if (d.fine_ricorrenza) dateTrovate.push(d.fine_ricorrenza as string)
                    else dateTrovate.push('2033-12-31')
                }
            }

            if (dateTrovate.length == 0) return '2010-01-01'
            else {
                const sortedDate = dateTrovate.sort((a, b) => a.localeCompare(b))
                return sortedDate[0]
            }

        } else return undefined
    }
    const findEndDate = (date: Orario[]) => {
        if (date && date.length > 0) {

            const dateTrovate = <string[]>[]

            for (const d of date) {
                if (d.ricorrenza == 'mai') {
                    if (d.data_inizio) dateTrovate.push(d.data_inizio as string)
                    if (d.data_fine) dateTrovate.push(d.data_fine as string)
                } else {
                    if (d.inizio_ricorrenza) dateTrovate.push(d.inizio_ricorrenza as string)
                    else dateTrovate.push('2010-01-01')
                    if (d.fine_ricorrenza) dateTrovate.push(d.fine_ricorrenza as string)
                    else dateTrovate.push('2033-12-31')
                }
            }

            if (dateTrovate.length == 0) return '2033-12-31'
            else {
                const sortedDate = dateTrovate.sort((a, b) => a.localeCompare(b))
                return sortedDate[sortedDate.length - 1]
            }

        } else return undefined
    }

    return {
        getRisorsaType,
        getRisorsaMiceType,
        getEventoType,

        createOpeningHours,

        findStartDate,
        findEndDate
    }
}
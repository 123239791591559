<template>
    <div :class="['allegato', fillColor]">
        <NIcon size="1.176rem" :color="fillColorVariable" :component="InsertDriveFileRound" :aria-label="$t('documento')"></NIcon>
        <span>{{ etichetta }}</span>
        <a class="overlink" :href="`${directusUrl}/assets/${file.id}`" target="_blank" :download="file.title"></a>
    </div>
</template>

<script lang="ts" setup>
import { NIcon } from 'naive-ui';
import InsertDriveFileRound from '@vicons/material/InsertDriveFileRound'

interface Props {
    etichetta: string,
    file: any,
    fillColor?: 'text' | 'azuredark'
}
const props = withDefaults(defineProps<Props>(), {
    fillColor: 'azuredark'
})
const directusUrl = useDirectusUrl()

const fillColorVariable = computed(() => {
    switch (props.fillColor) {
        case 'text':
            return 'var(--color-text-default)'
        case 'azuredark':
            return 'var(--color-azure-dark)'
        default:
            break;
    }
})
</script>

<style lang="scss" scoped>
.allegato {
    font-size: var(--font-size-body);
    line-height: var(--font-line-body);

    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 6px;

    color: v-bind(fillColorVariable);

    span {
        display: block;
    }
}
</style>
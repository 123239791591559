<template>
    <div v-if="cta && cta.link && cta.link.length > 0">
        <div class="link linkDesktop">
            <NuxtLinkLocale :to="getCardLink(cta.link[0], cta.link[0].item.__typename).address" :custom="true">
                <template v-slot="{ navigate }">
                    <NButton size="medium" type="primary" round icon-placement="right" @click="navigate" tabindex="-1" :aria-label="cta.translations && cta.translations[0]?.etichetta ? cta.translations[0].etichetta : `${$t('vedi_tutto')} ${etichetta || ''}`">
                        {{ cta.translations && cta.translations[0]?.etichetta ? cta.translations[0].etichetta : $t('vedi_tutto') }}
                        <template #icon>
                            <NIcon :component="ArrowForwardRound" :aria-label="$t('freccia')" />
                        </template>
                    </NButton>
                </template>
            </NuxtLinkLocale>
        </div>
        <div class="linkMobile">
            <NuxtLinkLocale :to="getCardLink(cta.link[0], cta.link[0].item.__typename).address" :custom="true">
                <template v-slot="{ navigate }">
                    <NButton size="medium" type="primary" round icon-placement="right" @click="navigate" tabindex="-1" :aria-label="`${$t('vedi_tutto')} mobile ${etichetta || ''}`">
                        <template #icon>
                            <NIcon :component="ArrowForwardRound" :aria-label="$t('freccia')" />
                        </template>
                    </NButton>
                </template>
            </NuxtLinkLocale>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NButton, NIcon } from 'naive-ui'
import ArrowForwardRound from '@vicons/material/ArrowForwardRound'

defineProps<{ cta: any, etichetta?: string }>()
const { getCardLink } = useCard()
</script>

<style lang="scss" scoped>
.linkMobile {
    display: none;
}

@media screen and (max-width: 600px) {
    .linkDesktop {
        display: none;
    }

    .linkMobile {
        display: block;
    }
}
</style>
<template>
    <section class="zonaTariffeCard" v-if="tariffeProdotto.length > 0">
        <div class="grid">
            <template v-for="tariffa in tariffeProdotto">
                <div class="gridItem" v-if="tariffa.aventi_diritto_prodotto">
                    <div class="avatar">
                        <div class="filler"></div>
                        <Image v-if="tariffa.aventi_diritto_prodotto.cover_oggetto_card" :image="tariffa.aventi_diritto_prodotto.cover_oggetto_card" :width="600" imageClass="cover" />
                    </div>
                    <div class="info">
                        <div class="paragraph">
                            <p>{{ tariffa.aventi_diritto_prodotto.translations[0]?.titolo?.replace(/\+/g, '+&#8203;') }}</p>
                            <p><strong>{{ $t(tariffa.tipologia) }}</strong></p>
                        </div>
                    </div>
                    <NuxtLinkLocale class="overlink" :to="getCardLink({ item: tariffa.aventi_diritto_prodotto }, 'prodotti').address"></NuxtLinkLocale>
                </div>
            </template>
        </div>
    </section>
</template>

<script lang="ts" setup>
const { getCardLink } = useCard()
const props = defineProps<{ evento: any }>()

const tariffeProdotto = computed(() => {
    if (props.evento && props.evento.tariffe && props.evento.tariffe.length > 0) {
        return props.evento.tariffe.filter((t: any) => t.aventi_diritto == 'prodotto')
    } else if (props.evento && props.evento.tariffe_visite_guidate && props.evento.tariffe_visite_guidate.length > 0) {
        return props.evento.tariffe_visite_guidate.filter((t: any) => t.aventi_diritto == 'prodotto')
    } else return []
})
</script>

<style lang="scss" scoped>
.zonaTariffeCard {

    .grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: var(--margin-gap);

        .gridItem {
            position: relative;
            border-radius: var(--size-card-radius);
            background-color: var(--color-background-box);
            overflow: hidden;

            .avatar {
                position: relative;
                background-color: var(--color-background-image);

                .filler {
                    padding-top: 63%;
                }

                &:deep(img) {
                    top: 0;
                }
            }

            .info {
                padding: var(--margin-default);

                .paragraph {
                    text-wrap: balance;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .zonaTariffeCard {
        .grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}
</style>
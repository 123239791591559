<template>
    <section class="moduloMappa">
        <div class="cont">
            <div class="mappaCont">
                <div class="filler"></div>
                <div class="mappa">
                    <ClientOnly>
                        <LMap ref="map" :zoomAnimation="true" :use-global-leaflet="false">
                            <LTileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base" attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors" name="OpenStreetMap" @ready="initMap" />
                            <LFeatureGroup ref="markerGroup">
                                <slot name="featureGroup"></slot>
                            </LFeatureGroup>
                        </LMap>
                    </ClientOnly>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const map = ref()
const markerGroup = ref()

const initMap = () => {
    setTimeout(() => {
        //console.log(markerGroup.value.leafletObject.getBounds())
        try {
            if (markerGroup.value) map.value.leafletObject.fitBounds(markerGroup.value.leafletObject.getBounds().pad(0.1))
        } catch (e) {
            map.value.leafletObject.setView([45.116177, 7.742615], 9);
        }
    }, 500)
}
</script>

<style lang="scss" scoped>
.moduloMappa {

    .mappaCont {
        position: relative;
        width: 100%;

        background-color: var(--color-background-image);
        border-radius: var(--size-card-radius);
        overflow: hidden;

        .filler {
            padding-top: 65%;
        }

        .mappa {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        &:deep(.leaflet-control-attribution.leaflet-control) {
            background: none !important;
            padding-right: 15px;
            padding-bottom: 10px;

            font-size: 10px;
            color: rgba(34, 47, 62, 0.7);

            a {
                color: currentColor
            }

            svg {
                display: none !important;
            }
        }
    }
}
</style>
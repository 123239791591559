<template>
    <section class="zonaAccordion" v-if="prodotto.translations && prodotto.translations.length > 0 && ((prodotto.eventi && prodotto.eventi.length) || (prodotto.punti_vendita && prodotto.punti_vendita.length > 0) || (prodotto.risorse && prodotto.risorse.length > 0))">
        <div class="cont">
            <div class="innerCont">
                <div class="lista">
                    <AccordionContainer :title="$t('aderenti')" :open="false" :white="true" v-if="sortedAderenti.length > 0">
                        <template #content>
                            <ListaRisorseProdotto :risorse="sortedAderenti" />
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('in_vendita_presso')" :open="false" :white="true" v-if="sortedVenditori.length > 0">
                        <template #content>
                            <ListaRisorseProdotto :risorse="sortedVenditori" />
                        </template>
                    </AccordionContainer>
                    <AccordionContainer :title="$t('eventi')" :open="false" :white="true" v-if="prodotto.eventi && prodotto.eventi.length > 0">
                        <template #content>
                            <ListaEventiProdotto :eventi="prodotto.eventi" />
                        </template>
                    </AccordionContainer>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const props = defineProps<{ prodotto: any }>()

const sortedAderenti = computed(() => {
    if (props.prodotto.risorse && props.prodotto.risorse.length > 0) {
        return props.prodotto.risorse.sort((a: any, b: any) => (a.risorse_id.translations[0].titolo > b.risorse_id.translations[0].titolo) ? 1 : ((b.risorse_id.translations[0].titolo > a.risorse_id.translations[0].titolo) ? -1 : 0))
    } else return []
})
const sortedVenditori = computed(() => {
    if (props.prodotto.punti_vendita && props.prodotto.punti_vendita.length > 0) {
        return props.prodotto.punti_vendita.sort((a: any, b: any) => (a.risorse_id.translations[0].titolo > b.risorse_id.translations[0].titolo) ? 1 : ((b.risorse_id.translations[0].titolo > a.risorse_id.translations[0].titolo) ? -1 : 0))
    } else return []
})
</script>

<style lang="scss" scoped>
.zonaAccordion {
    .lista {
        display: flex;
        flex-direction: column;
        gap: var(--margin-gap);
    }
}
</style>
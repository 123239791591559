<template>
    <section class="moduloLista">
        <div class="cont">
            <OggettoHeadModulo :modulo="modulo" />

            <template v-if="modulo.contenuto == 'automatico' || modulo.contenuto == 'manuale'">
                <div :class="['lista', modulo.tipologia]">
                    <template v-for="card in cards">
                        <OggettoGenericoLista :card="card" v-if="modulo.tipologia == 'normale' && (!card.item?.lingue || card.item?.lingue.includes(locale))" :typename="card.item?.__typename || card.collection" />
                        <OggettoGenericoListaCompatta :card="card" v-else-if="modulo.tipologia == 'compatta' && (!card.item?.lingue || card.item?.lingue.includes(locale))" :typename="card.item?.__typename || card.collection" />
                    </template>
                </div>
                <div class="loadmore" v-if="hasMorePages">
                    <NButton size="medium" type="primary" round icon-placement="right" :loading="loading" @click="loadmore">{{ $t('load_more') }}</NButton>
                </div>
            </template>
            <template v-else-if="modulo.contenuto == 'news_convention'">
                <div class="listaNews" v-if="cardsNews && cardsNews.length > 0">
                    <template v-if="cardsNews[0].current === true">
                        <div :class="['lista', modulo.tipologia]">
                            <template v-for="card in cardsNews[0].cards">
                                <OggettoGenericoLista :card="card" v-if="modulo.tipologia == 'normale'" :typename="card.item?.__typename || card.collection" />
                                <OggettoGenericoListaCompatta :card="card" v-else-if="modulo.tipologia == 'compatta'" :typename="card.item?.__typename || card.collection" />
                            </template>
                        </div>
                    </template>
                    <ListaNewsConvention v-for="group in cardsNews.filter((c) => c.current === false)" :key="group.year" :group="group" :tipologia="modulo.tipologia"></ListaNewsConvention>
                </div>
            </template>

        </div>
    </section>
</template>

<script lang="ts" setup>
import { NButton } from 'naive-ui'

const props = defineProps<{ modulo: any }>()
const { locale } = useI18n()
const { searchMeilimoduleCardsLista } = useSearch()
const directus = useDirectus()

const page = ref(1)
const hasMorePages = ref(false)
const loading = ref(false)
const generatedCards = ref<any>([])

const { data: results } = props.modulo.contenuto == 'automatico' && props.modulo.translations_formula[0] ? useAsyncData(JSON.stringify(props.modulo.translations_formula[0].formula), () => searchMeilimoduleCardsLista(props.modulo.translations_formula[0].formula, page.value)) : { data: ref(null) }
const { data: news } = props.modulo.contenuto == 'news_convention' ? useAsyncData(`lista-newsconvention-${locale.value}`, () => directus('/pages/content/part/lista-newsconvention', { query: { locale: locale.value } })) : { data: ref(null) }

if (results.value && results.value.hits) {
    hasMorePages.value = page.value < results.value.totalPages
}
watch(results, () => {
    if (results.value && results.value.hits) {
        hasMorePages.value = page.value < results.value.totalPages
    }
})

const cards = computed(() => {
    if (props.modulo.contenuto == 'automatico' && results && results.value && results.value.hits) return [...results.value.hits, ...generatedCards.value]
    else if (props.modulo.contenuto == 'manuale') return props.modulo.card
    else return []
})
const cardsNews = computed(() => {
    if (props.modulo.contenuto == 'news_convention') {
        if (news.value && news.value.news_convention) {

            const items = news.value.news_convention.map((n: any) => { return { item: n, collection: 'news_convention' } })
            const itemCards = <any[]>[]

            for (const card of items) {
                const year = new Date(card.item.data_pubblicazione).getFullYear()
                if (!itemCards.find((c) => c.year == year)) itemCards.push({ year, current: false, cards: [] })
                itemCards.find((c) => c.year == year)?.cards.push({
                    timestamp: new Date(card.item.data_pubblicazione).getTime(),
                    ...card
                })
            }

            const sortedYears = itemCards.sort((a, b) => b.year - a.year)
            sortedYears[0].current = true

            return sortedYears

        } else return []
    } else return []
})

const loadmore = async () => {
    loading.value = true

    page.value = page.value += 1
    const newResults = await searchMeilimoduleCardsLista(props.modulo.translations_formula[0].formula, page.value)
    if (newResults.hits) {
        generatedCards.value.push(...newResults.hits)
        hasMorePages.value = page.value < newResults.totalPages
    }

    loading.value = false
}
</script>

<style lang="scss" scoped>
.moduloLista {

    .cont {
        display: flex;
        flex-direction: column;
        gap: var(--article-gap-inner);
    }

    .listaNews {
        display: flex;
        flex-direction: column;
        gap: var(--margin-double);
    }

    .lista {
        display: flex;
        flex-direction: column;

        &.normale {
            gap: var(--margin-default);
        }

        &.compatta {
            gap: var(--margin-gap);
            max-width: var(--size-innercolumn-max);
        }
    }

    .loadmore {
        margin: var(--margin-double) 0;
        display: flex;
        justify-content: center;
    }
}
</style>
<template>
    <div class="oggettoSede" v-if="sede">
        <CardListaCompatta :titolo="titolo" :link="link.address" :isExternal="link.external" />
    </div>
</template>

<script lang="ts" setup>
const { getCardTitolo, getCardLink } = useCard()
const props = defineProps<{ sede: any }>()

const titolo = computed(() => getCardTitolo({ item: props.sede }))
const link = computed(() => getCardLink({ item: props.sede }, 'risorse'))
</script>
<template>
    <div class="mappa">
        <div class="filler"></div>
        <div :class="['leaflet', { show: hits && hits.length > 0 }]">
            <ClientOnly>
                <LMap ref="map" :options="{ zoomSnap: 0.3, zoomDelta: 0.9 }" :zoomAnimation="true">
                    <LTileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base" attribution="&amp;copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors" name="OpenStreetMap" @ready="initMap" />
                    <LFeatureGroup ref="markerGroup">
                        <template v-for="hit in hits">
                            <LMarker v-if="hit && hit._geo && hit._geo.lat && hit._geo.lng" :lat-lng="[hit._geo.lat, hit._geo.lng]">
                                <LIcon icon-url="/map/marker.png" shadow-url="/map/shadow.png" :icon-size="[50, 50]" :icon-anchor="[25, 25]" :shadow-size="[50, 50]" :shadow-anchor="[25, 25]"></LIcon>
                                <LPopup>
                                    <div>
                                        <NuxtLinkLocale v-if="hit.link" :to="hit.link">{{ hit.titolo }}</NuxtLinkLocale>
                                        <span v-else>{{ hit.titolo }}</span>
                                    </div>
                                </LPopup>
                            </LMarker>
                        </template>
                    </LFeatureGroup>
                    <LCircle v-if="lat && lng" :lat-lng="[lat, lng]" :radius="10000" color="#316E86">

                    </LCircle>
                </LMap>
            </ClientOnly>
        </div>
    </div>
</template>

<script lang="ts" setup>
interface Props {
    hits: any[],
    lat?: number,
    lng?: number
}
const props = defineProps<Props>()

const map = ref()
const markerGroup = ref()

const initMap = () => {
    if (props.hits && props.hits.length > 0) map.value.leafletObject.fitBounds(markerGroup.value.leafletObject.getBounds().pad(0.1))
}

watch(() => props.hits, () => {
    if (props.hits && props.hits.length > 0) {
        setTimeout(() => {
            map.value.leafletObject.fitBounds(markerGroup.value.leafletObject.getBounds().pad(0.1))
        }, 50);
    }
})
</script>

<style lang="scss" scoped>
.mappa {
    position: relative;
    width: 100%;

    background-color: var(--color-background-image);
    border-radius: var(--size-card-radius);
    overflow: hidden;

    .filler {
        padding-top: 100%;
    }

    .leaflet {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        opacity: 0;
        transition: opacity 200ms 0ms ease-in-out;

        &.show {
            opacity: 1;
        }
    }

    &:deep(.leaflet-control-attribution.leaflet-control) {
        background: none !important;
        padding-right: 20px;

        svg {
            display: none !important;
        }
    }
}

@media screen and (max-width: 600px) {
    .mappa {
        .filler {
            padding-top: 300px;
        }
    }
}
</style>
<template>
    <div class="oggettopacchettoIncoming" v-if="pacchetto">
        <CardMini :cover="pacchetto.cover" :titolo="pacchetto.translations && pacchetto.translations.length > 0 ? pacchetto.translations[0].titolo : null" :link="getCardLink({item: pacchetto}, 'pacchetti').address">
            <template #end>
                <div class="end">
                    <div class="small" v-if="pacchetto.comune">{{ pacchetto.comune.nome }}</div>
                    <div class="small" v-else-if=pacchetto.comune_suggerito>{{ pacchetto.comune_suggerito }}</div>
                    <div class="small" v-if="dataSottotitolo">{{ dataSottotitolo }}</div>
                </div>
            </template>
        </CardMini>
    </div>
</template>

<script lang="ts" setup>
const { verbalizzaSottotitolo } = useOrari()
const { getCardLink } = useCard()
const props = defineProps<{ pacchetto: any }>()

const dataSottotitolo = computed(() => {
    if (props.pacchetto && props.pacchetto.date) {
        return verbalizzaSottotitolo(props.pacchetto.date)
    } else return null
})
</script>

<style lang="scss" scoped>
.oggettopacchettoIncoming {
    .end {
        text-transform: uppercase;
    }
}
</style>
<template>
    <div class="groupRow" v-if="tariffaAltaStagione">
        <div v-if="tariffaBassaStagione">{{ $t('alta_stagione') }}</div>
        <div v-else>{{ $t('stagione_unica') }}</div>
        <OggettoTariffaRange :tariffa="tariffaAltaStagione" />
    </div>
    <div v-else></div>
    <div class="groupRow" v-if="tariffaBassaStagione">
        <div>{{ $t('bassa_stagione') }}</div>
        <OggettoTariffaRange :tariffa="tariffaBassaStagione" />
    </div>
    <div v-else></div>
</template>

<script lang="ts" setup>
const props = defineProps<{ risorsa: any, tipologia: string }>()

const tariffaAltaStagione = computed(() => {
    if (props.risorsa && props.risorsa.ricettivita_tariffe && props.risorsa.ricettivita_tariffe.length > 0) {
        return props.risorsa.ricettivita_tariffe.find((t: any) => t.tipologia == `${props.tipologia}_alta_stagione`)
    } else return null
})
const tariffaBassaStagione = computed(() => {
    if (props.risorsa && props.risorsa.ricettivita_tariffe && props.risorsa.ricettivita_tariffe.length > 0) {
        return props.risorsa.ricettivita_tariffe.find((t: any) => t.tipologia == `${props.tipologia}_bassa_stagione`)
    } else return null
})
</script>
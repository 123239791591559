

export const useNewsletter = () => {

    const { localeProperties } = useI18n()

    const iscrizioneNewsletter = async (tipologia: string, target: string, iat: string, data: any) => {
        try {

            let lingua = 'ITA'
            if (localeProperties.value.code == 'en') lingua = 'ENG'
            else if (localeProperties.value.code == 'fr') lingua = 'FRA'

            const body = new URLSearchParams()
            body.set('oid', '00D7S000001MbKa')
            body.set('retURL', 'https://turismotorino.org')
            //body.set('debug', '1')
            //body.set('debugEmail', '')
            body.set("first_name", data.first_name)
            body.set("last_name", data.last_name)
            body.set("email", data.email)
            body.set("00NTj00000B6tLF", data.citta)
            body.set("00NTj00000B6tjR", data.nazione)
            body.set("00NTj00000B6sKN", lingua)
            body.set("00NTj00000B76vB", '1') // consenso privacy
            body.set("lead_source", "web")

            if (tipologia == 'travel_news') {
                if (target == 'utente_finale') {
                    body.set("00NTj00000B76tZ", "Travel News")
                }
                else if (target == 'scolastica') {
                    body.set("00NTj00000B6uFi", data.scuola)
                    body.set("00NTj00000B6uVp", data.scuola_tipologia)
                    body.set("00NTj00000B76tZ", "Travel News Scolastica")
                }
            } else if (tipologia == 'trade_news') {
                body.set("00NTj00000B6uFi", data.company)
                if (data.interesse_educational != 'Nessuno') {
                    body.set("00NTj00000B6uE5", "1")
                    body.set("00NTj00000B6uKX", data.interesse_educational)
                    body.set("00NTj00000B76tZ", "Trade News")
                }
            } else if (tipologia == 'mice_news') {
                body.set("00NTj00000B6uFi", data.company)
                body.set("00NTj00000B6u9F", data.job) // SELECT?
                body.set("00NTj00000B76tZ", "Mice News")
            } else if (tipologia == 'welcome_news') {
                body.set("00NTj00000B6u61", iat)
                body.set("00NTj00000B76tZ", "Welcome News")
            }

            const req = await fetch('https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D7S000001MbKa', {
                method: "POST",
                mode: 'no-cors',
                body,
            });
            const res = await req.text()
            return res
        } catch (error: any) {
            if (error.statusMessage) {
                throw createError({
                    statusCode: error.statusCode,
                    message: error.statusMessage
                })
            }
            else {
                throw createError({
                    statusCode: 500,
                    message: 'GENERIC'
                })
            }
        }
    }

    return {
        iscrizioneNewsletter
    }
}
<template>
    <div class="oggettoGenericoLista">
        <CardListaCompatta :titolo="titolo" :link="link.address" :isExternal="link.external" :showArrow="true" />
    </div>
</template>

<script lang="ts" setup>
const { getCardLink, getCardTitolo } = useCard()
const props = defineProps<{ card: any, typename?: string | null }>()

const titolo = computed(() => getCardTitolo(props.card))
const link = computed(() => getCardLink(props.card, props.typename))
</script>
<template>
    <div class="cardImmagine">
        <Image v-if="cover" :image="cover" :width="800" imageClass="cover" :key="cover.id" />
        <a v-if="link && isExternal" class="overlink" :href="link" target="_blank"></a>
        <NuxtLink v-if="link && !isExternal" class="overlink" :to="localeRoute(link)"></NuxtLink>
    </div>
</template>

<script lang="ts" setup>
const localeRoute = useLocaleRoute()

interface Props {
    cover?: any,
    link?: any,
    isExternal?: boolean
}
defineProps<Props>()
</script>

<style lang="scss" scoped>
.cardImmagine {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: var(--color-background-image);
    border-radius: var(--size-card-radius);
    overflow: hidden;
}
</style>
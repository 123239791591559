<template>
    <div class="tariffa">

        <div :class="['titlebody', { noline: (!tariffa.prezzo || tariffa.tipologia == 'gratuito') && tariffa.aventi_diritto == 'tutti' && total == 1 }]">
            <span v-if="tariffa.tipologia && tariffa.tipologia != 'personalizza'">{{ $t(tariffa.tipologia) }}</span>
            <span v-else-if="tariffa.tipologia && tariffa.tipologia == 'personalizza' && tariffa.tipologia_personalizzata && tariffa.tipologia_personalizzata[0]">{{ tariffa.tipologia_personalizzata?.[0]?.etichetta }}</span>

            <span class="prezzo" v-if="tariffa.prezzo && tariffa.tipologia != 'gratuito'">€ {{ parseFloat(tariffa.prezzo).toFixed(2) }}</span>
        </div>
        <div class="paragraph" v-if="tariffa.tariffa_straordinaria && (tariffa.data_inizio || tariffa.data_fine)">
            <span v-if="tariffa.data_inizio && tariffa.data_fine && tariffa.data_inizio == tariffa.data_fine">{{ formatoDataLungo(new Date(tariffa.data_inizio)) }}</span>
            <span v-else-if="tariffa.data_inizio && tariffa.data_fine">Dal {{ formatoDataCompatto(new Date(tariffa.data_inizio)) }} al {{ formatoDataCompatto(new Date(tariffa.data_fine)) }}</span>
            <span v-else-if="tariffa.data_inizio && !tariffa.data_fine">Dal {{ formatoDataCompatto(new Date(tariffa.data_inizio)) }}</span>
            <span v-else-if="!tariffa.data_inizio && tariffa.data_fine">Fino al {{ formatoDataCompatto(new Date(tariffa.data_fine)) }}</span>
        </div>
        <div class="paragraph" v-if="tariffa.aventi_diritto == 'custom'">
            <p>{{ listaAventiDiritto }}</p>
        </div>
        <div class="paragraph paragraphTicket" v-else-if="tariffa.aventi_diritto == 'prodotto' && tariffa.aventi_diritto_prodotto">
            <NIcon :size="16" color="var(--color-text-default)" aria-label="Ticket">
                <IconTicket />
            </NIcon>
            <p>{{ $t('possessori_carta') }} {{ tariffa.aventi_diritto_prodotto?.translations?.[0]?.titolo }}</p>
        </div>

        <div class="note mini" v-if="tariffa.translations && tariffa.translations[0] && tariffa.translations[0].note" v-html="tariffa.translations[0].note"></div>

    </div>
</template>

<script lang="ts" setup>
import { NIcon } from 'naive-ui';

const { t } = useI18n()
const { formatoDataLungo, formatoDataCompatto } = useOrari()
const props = defineProps<{ tariffa: any, total?: number }>()

const listaAventiDiritto = computed(() => {
    if (props.tariffa.aventi_diritto == 'custom') {

        const predefiniti = []
        /*for (const a of Object.entries(props.tariffa.aventi_diritto_predefiniti)) {
            if (a[1] === true) predefiniti.push(t(a[0])) // TODO TRADUZIONE
        }*/
        if (Array.isArray(props.tariffa.aventi_diritto_predefiniti)) {
            for (const a of props.tariffa.aventi_diritto_predefiniti) {
                predefiniti.push(t(a))
            }
        }

        const personalizzati = []
        if (props.tariffa.aventi_diritto_personalizzato && props.tariffa.aventi_diritto_personalizzato[0] && props.tariffa.aventi_diritto_personalizzato[0].etichetta) {
            for (const a of props.tariffa.aventi_diritto_personalizzato[0].etichetta.split(',')) {
                personalizzati.push(a.trim())
            }
        }

        return [...predefiniti, ...personalizzati].join(', ')
    } else return ''
})
</script>

<style lang="scss" scoped>
.tariffa {

    .titlebody {
        display: grid;
        grid-template-columns: 1fr min-content;
        gap: var(--margin-default);

        border-bottom: 1px solid var(--color-azure-back);
        padding-bottom: 6px;
        margin-bottom: 6px;

        .prezzo {
            white-space: nowrap;
        }

        &.noline {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .paragraphTicket {
        display: grid;
        grid-template-columns: min-content 1fr;
        gap: var(--margin-mini);

        align-items: baseline;
    }

    &:deep(a) {
        color: black;
        text-decoration: underline;
        font-weight: normal;
    }
}

@media screen and (max-width: 600px) {
    .tariffa {
        .titlebody {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}
</style>
<template>
    <div class="innerCont">
        <div class="cookiePolicy">
            <div class="paragraph" v-if="policy && policy.content" v-html="policy.content"></div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const { locale } = useI18n()

let policyLink = `https://www.iubenda.com/api/privacy-policy/33640455/cookie-policy`
if (locale.value == 'en') policyLink = `https://www.iubenda.com/api/privacy-policy/13220836/cookie-policy`
if (locale.value == 'fr') policyLink = `https://www.iubenda.com/api/privacy-policy/44414644/cookie-policy`

const { data: policy } = useAsyncData<any>('privacy-policy', () => $fetch(policyLink))
</script>

<style lang="scss" scoped>
.paragraph {

    &:deep(h2:first-of-type) {
        margin-top: 0 !important;
    }

    &:deep(h1),
    &:deep(h2),
    &:deep(h3),
    &:deep(h4),
    &:deep(h5),
    &:deep(h6) {
        font-size: var(--font-size-body);
        line-height: var(--font-line-body);
        font-weight: 800;
        margin-top: var(--font-line-body);
        font-variant: normal !important;
    }

    &:deep(h2) {
        color: var(--color-burgundy);
    }

    &:deep(hr) {
        display: none;
    }

    &:deep(.for_boxes) {
        list-style: none;
        padding-left: 0;
    }

    &:deep(summary) {
        border-top: 1px solid var(--color-text-default);
        position: relative;
        display: list-item;
        padding-top: 2px;
        cursor: pointer;

        user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;

        &::marker {
            color: var(--color-azure-dark);
        }

        &::-webkit-details-marker {
            color: var(--color-azure-dark);
            position: absolute;
            right: 0;
            top: 10px;
        }
    }
}

@media screen and (max-width: 600px) {
    .cont {
        padding-left: 0;
        padding-right: 0;
    }
}
</style>
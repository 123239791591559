<template>
    <div class="oggettoDocumentoCard" v-if="card">
        <CardStandard :cover="cover" :titolo="titolo" :link="link.address" :linkName="link.addressName" :isExternal="link.external" />
    </div>
</template>

<script lang="ts" setup>
const { getCardCover, getCardTitolo, getCardLink } = useCard()
const props = defineProps<{ card: any, typename?: string | null }>()

const cover = computed(() => getCardCover(props.card))
const titolo = computed(() => getCardTitolo(props.card))
const link = computed(() => getCardLink(props.card, props.typename))
</script>
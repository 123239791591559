<template>
    <LMarker v-if="gruppo && gruppo[1][0].latlng" :lat-lng="gruppo[1][0].latlng">
        <LIcon :icon-url="markerImage" shadow-url="/map/shadow.png" :icon-size="[50, 50]" :icon-anchor="[25, 25]" :shadow-size="[50, 50]" :shadow-anchor="[25, 25]"></LIcon>
        <LPopup>
            <div v-for="punto in gruppo[1]">
                <NuxtLinkLocale v-if="punto.link" :to="punto.link">{{ punto.titolo }}</NuxtLinkLocale>
                <span v-else>{{ punto.titolo }}</span>
            </div>
        </LPopup>
    </LMarker>
</template>

<script lang="ts" setup>
const props = defineProps<{ gruppo: any }>()

const markerImage = computed(() => {
    if (props.gruppo[1].length == 1) {
        if (props.gruppo[1][0].area) {
            if (props.gruppo[1][0].area.length == 1) return `/map/marker-${props.gruppo[1][0].area[0]}.png`
            else return '/map/marker.png'
        } else return '/map/marker.png'
    } else {
        return '/map/marker.png'
    }
})
</script>

<style lang="scss">
.leaflet-popup {
    margin-bottom: 28px;

    .leaflet-popup-content-wrapper {
        border-radius: var(--size-card-radius-inner);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.13);

        .leaflet-popup-content {
            font-family: 'Switzer', Arial, Helvetica, sans-serif;
            color: var(--color-text-default);

            margin: 0;
            padding: var(--margin-default);
            text-align: center;

            font-size: var(--font-size-subtitle);
            line-height: var(--font-line-subtitle);
            font-weight: 800;

            a {
                color: var(--color-azure-dark);
                text-decoration: none;
            }
        }
    }

    .leaflet-popup-close-button {
        display: none;
    }
}
</style>
<template>
    <CardLista :cover="hit.cover" :tag="tipologia" :titolo="hit.titolo" :descrizione="hit.descrizione" :link="hit.link" :convention="hit.collection == 'risorse_convention'" :categoriaRicettivita="hit.categoria_ricettivita" :showimage="showimage" :imageTag="imageTag" @clickedLink="clickedLink">
        <template #end>
            <div class="end">
                <div class="small" v-if="hit.ricorrenza">{{ hit.ricorrenza }}</div>
                <div class="small">{{ hit.luogo }} <span v-if="hit._geoDistance && luogo != hit.luogo">({{ (hit._geoDistance / 1000).toFixed(1) }}{{ $t('km_da') }} {{ luogo == 'me' ? $t('km_da_te') : luogo }})</span></div>
                <div class="small aderisce" v-if="hit.aderisce && hit.aderisce.length > 0">
                    <div class="adesione" v-for="adesione in hit.aderisce">
                        <NIcon :size="16" color="var(--color-azure-dark)" aria-label="Ticket">
                            <IconTicket />
                        </NIcon>
                        <span>{{ adesione }}</span>
                    </div>
                </div>
            </div>
        </template>
    </CardLista>
</template>

<script lang="ts" setup>
import { NIcon } from 'naive-ui';
const { getCardTassonomiaRicerca } = useCard()
const emit = defineEmits(['clickedLink'])

interface Props {
    hit: any,
    luogo?: string | null,
    showimage?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    showimage: true
})

const tipologia = computed(() => getCardTassonomiaRicerca(props.hit))
const imageTag = computed(() => {
    if (props.hit.scaduto === true) return 'concluso'
    else return null
})

const clickedLink = () => {
    emit('clickedLink')
}
</script>

<style lang="scss" scoped>
.end {
    text-transform: uppercase;

    .aderisce {
        margin-bottom: -4px;
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        gap: var(--margin-gap);

        .adesione {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }
}
</style>
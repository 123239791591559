<template>
    <section class="moduloCard">
        <div class="cont">
            <OggettoHeadModulo :modulo="modulo" />
            <div class="grid">
                <div class="gridItem" v-for="card in modulo.card">
                    <div class="filler"></div>
                    <CardImmagine :cover="card.item.cover_oggetto_card" :link="getCardLink(card, 'prodotti').address" />
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const { getCardLink } = useCard()
defineProps<{ modulo: any }>()
</script>

<style lang="scss" scoped>
.moduloCard {

    .grid {
        margin-top: var(--margin-double);
        display: grid;
        gap: var(--margin-gap);

        grid-template-columns: repeat(4, minmax(0, 1fr));

        .gridItem {
            position: relative;

            .filler {
                padding-top: 63%;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .moduloCard {
        .grid {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}

@media screen and (max-width: 600px) {
    .moduloCard {
        .grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}
</style>
<template>
    <section class="moduloNewsletter">
        <div class="cont">
            <OggettoHeadModulo :modulo="modulo" />
            <div class="newsletterGrid" v-if="cardsByYear && cardsByYear.length > 0">
                <template v-if="cardsByYear[0].current === true">
                    <div class="grid">
                        <div class="gridItem" v-for="card in cardsByYear[0].cards.sort((a: any, b: any) => b.timestamp - a.timestamp)" :key="card.id">
                            <OggettoNewsletter :card="card"></OggettoNewsletter>
                        </div>
                    </div>
                </template>
                <ListaNewsletter v-for="group in cardsByYear.filter((c) => c.current === false)" :key="group.year" :group="group" :tipologia="modulo.tipologia"></ListaNewsletter>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const { locale } = useI18n()
const directus = useDirectus()
const props = defineProps<{ modulo: any }>()

const { data: newsletter } = useAsyncData(`newsletter-${locale.value}-${props.modulo.contenuto_griglia.toString()}`, () => directus('/pages/content/part/newsletter', { query: { locale: locale.value, tipologia: props.modulo.contenuto_griglia } }))

const cardsByYear = computed(() => {
    if (newsletter.value && newsletter.value.newsletter && newsletter.value.newsletter.length > 0) {
        const cards = <any[]>[]
        for (const card of newsletter.value.newsletter.filter((c: any) => c.translations && c.translations.length > 0 && c.translations[0].link)) {
            const year = new Date(card.data).getFullYear()
            if (!cards.find((c) => c.year == year)) cards.push({ year, current: year == new Date().getFullYear(), cards: [] })
            cards.find((c) => c.year == year)?.cards.push({
                timestamp: new Date(card.data).getTime(),
                ...card
            })
        }
        return cards.sort((a, b) => b.year - a.year)
    } else return []
})
</script>

<style lang="scss" scoped>
.moduloNewsletter {

    .cont,
    .newsletterGrid {
        display: flex;
        flex-direction: column;
        gap: var(--margin-double);
    }

    .grid {
        display: grid;
        gap: var(--margin-gap);
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-auto-rows: 400px;

        .gridItem {
            position: relative;
        }
    }
}

@media screen and (max-width: 1060px) {
    .moduloNewsletter {
        .grid {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

@media screen and (max-width: 840px) {
    .moduloNewsletter {
        .grid {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}

@media screen and (max-width: 600px) {
    .moduloNewsletter {
        .grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}

@media screen and (max-width: 480px) {
    .moduloNewsletter {
        .grid {
            grid-template-columns: 1fr;
            grid-auto-rows: 300px;
        }
    }
}
</style>
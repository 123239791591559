<template>
    <CardLista :cover="evento.cover" :tag="etichetta" :titolo="evento.titolo" :descrizione="evento.descrizione" :link="evento.link">
        <template #end>
            <div class="end">
                <div class="small">{{ evento.luogo }} <span v-if="evento._geoDistance && luogo != evento.luogo">({{ (evento._geoDistance / 1000).toFixed(1) }}{{ $t('km_da') }} {{ luogo == 'me' ? $t('km_da_te') : luogo }})</span></div>
                <div class="small" v-if="evento.ricorrenza">{{ evento.ricorrenza }}</div>
                <div class="small aderisce" v-if="evento.aderisce && evento.aderisce.length > 0">
                    <div class="adesione" v-for="adesione in evento.aderisce">
                        <NIcon :size="16" color="var(--color-azure-dark)" aria-label="Ticket">
                            <IconTicket />
                        </NIcon>
                        <span>{{ adesione }}</span>
                    </div>
                </div>
            </div>
        </template>
    </CardLista>
</template>

<script lang="ts" setup>
import { NIcon } from 'naive-ui';

interface Props {
    evento: any,
    luogo?: string | null
}
const props = defineProps<Props>()

const etichetta = computed(() => {
    if (props.evento && props.evento.collection) {
        switch (props.evento.collection) {
            case 'pacchetti':
                return props.evento.tipologia ? props.evento.tipologia.join(', ') : null
            default:
                return props.evento.categoria ? props.evento.categoria.join(', ') : null
        }
    } else return null
})
</script>


<style lang="scss" scoped>
.end {
    text-transform: uppercase;

    .aderisce {
        margin-bottom: -4px;
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        gap: var(--margin-gap);

        .adesione {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }
}
</style>
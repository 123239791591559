<template>
    <div :class="['accordion', { open: accordionOpen }, { white }, { target }, { design }]" ref="accordion">
        <div class="header noselect" @click="toggleAccordion" ref="header">
            <h2 class="titlebody">{{ title }}</h2>
            <NIconWrapper :size="22" :color="white || target || design ? 'white' : 'var(--color-azure)'" :border-radius="11">
                <NIcon size="1.176rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound" :aria-label="$t('apri')"></NIcon>
            </NIconWrapper>
        </div>

        <div class="contentZone" ref="content">
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NIconWrapper, NIcon } from 'naive-ui'
import KeyboardArrowRightRound from '@vicons/material/KeyboardArrowRightRound'

interface Props {
    title: string;
    open?: boolean;
    white?: boolean;
    target?: boolean;
    design? : boolean;
    id?: string;
}
const props = withDefaults(defineProps<Props>(), {
    open: false,
    white: false,
    target: false,
    design: false
})
const { $listen } = useNuxtApp()
const { getYPosition, scrollToY } = useScrollFunctions()

const accordionOpen = ref(props.open)
const accordion = ref()
const header = ref()
const content = ref()

const { height: headerHeight } = useElementSize(header)
const { height: contentHeight } = useElementSize(content)
const { height: windowHeight } = useWindowSize()
const closedHeightPx = computed(() => {
    if (headerHeight.value) return `calc(${headerHeight.value}px + (var(--margin-default) * 2))`
    else return `calc(var(--font-line-body) + (var(--margin-gap) * 2) + (var(--margin-default) * 2))`
})
const contentHeightPx = computed(() => `calc(${headerHeight.value}px + (var(--margin-default) * 2) + ${contentHeight.value}px)`)

const toggleAccordion = () => {
    accordionOpen.value = !accordionOpen.value
}

$listen('goToAccordion', (id: any) => {
    if (props.id && props.id == id && accordion.value) {
        accordionOpen.value = true

        const accordionPosition = getYPosition(accordion.value)
        const accordionHeight = headerHeight.value + contentHeight.value

        scrollToY(accordionPosition - windowHeight.value / 2 + accordionHeight)
    }
})
</script>

<style lang="scss" scoped>
.accordion {
    background-color: var(--color-azure);
    border-radius: var(--size-card-radius);
    padding: var(--margin-default);

    height: v-bind(closedHeightPx);
    box-sizing: border-box;
    overflow: hidden;

    transition: height 300ms 0ms cubic-bezier(0.4, 0, 0.2, 1);

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        h2 {
            padding: var(--margin-gap) 0;
            //color: white;
        }

        &:deep(.n-icon-wrapper) {
            svg {
                transition: transform 300ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
            }
        }
    }

    .content {
        padding: var(--margin-default) var(--margin-double) var(--margin-default) 0;

        opacity: 0;
        transition: opacity 300ms 0ms cubic-bezier(0.4, 0, 0.2, 1);

        display: flex;
        flex-direction: column;
        gap: var(--margin-default);
    }

    &.open {
        height: v-bind(contentHeightPx);

        .header:deep(.n-icon-wrapper) {
            svg {
                transform: rotate(90deg);
            }
        }

        .content {
            opacity: 1;
        }
    }

    &.white {
        background-color: white;
    }

    &.target {
        background-color: white;
        border: 1px solid var(--color-azure);
    }
    &.design {
        background-color: white;
        border: 1px solid #e75a5d;
    }
}

@media screen and (max-width: 600px) {
    .accordion {
        .content {
            padding-right: var(--margin-default);
        }
    }
}
</style>

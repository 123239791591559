<template>
    <div class="listaNewsConvention">
        <div class="innerCont">
            <div :class="['estendi', 'noselect', { open: passatiOpen }]" @click="togglePassati">
                <div class="titlebody">{{ group.year }}</div>
                <NIconWrapper :size="22" :border-radius="11">
                    <NIcon size="1.176rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound" :aria-label="$t('apri')"></NIcon>
                </NIconWrapper>
            </div>
        </div>
        <NCollapseTransition :show="passatiOpen">
            <div :class="['lista', tipologia]">
                <template v-for="card in group.cards">
                    <OggettoGenericoLista :card="card" v-if="tipologia == 'normale'" :typename="card.item?.__typename || card.collection" />
                    <OggettoGenericoListaCompatta :card="card" v-else-if="tipologia == 'compatta'" :typename="card.item?.__typename || card.collection" />
                </template>
            </div>
        </NCollapseTransition>
    </div>
</template>

<script lang="ts" setup>
import { NIconWrapper, NIcon, NCollapseTransition } from 'naive-ui';
import KeyboardArrowRightRound from '@vicons/material/KeyboardArrowRightRound'

defineProps<{ group: any; tipologia: string }>()

const passatiOpen = ref(false)

const togglePassati = () => {
    passatiOpen.value = !passatiOpen.value
}
</script>

<style lang="scss" scoped>
.listaNewsConvention {
    .estendi {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:deep(.n-icon-wrapper) {
            svg {
                transition: transform 300ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
            }
        }

        &.open {
            &:deep(.n-icon-wrapper) {
                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .lista {
        padding-top: var(--margin-default);
        display: flex;
        flex-direction: column;

        &.normale {
            gap: var(--margin-default);
        }

        &.compatta {
            gap: var(--margin-gap);
            max-width: var(--size-innercolumn-max);
        }
    }
}
</style>
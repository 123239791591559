<template>
    <div class="oggettoSede" v-if="operatore">
        <CardListaCompatta :titolo="nomeVisualizzato" :link="getCardLink({ item: operatore }, type).address" />
    </div>
</template>

<script lang="ts" setup>
const { getCardLink } = useCard()
const props = defineProps<{ operatore: any, type: 'operatori_outdoor' | 'operatori_incoming' }>()
const nomeVisualizzato = computed(() => {
    if (props.operatore && props.type == 'operatori_outdoor') {
        if (props.operatore.denominazione) return props.operatore.denominazione
        else return `${props.operatore.first_name} ${props.operatore.last_name}`
    } else if (props.operatore && props.type == 'operatori_incoming') {
        return props.operatore.denominazione
    } else return ''
})
</script>
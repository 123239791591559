export const usePreferencesStore = defineStore('preferences', {
    state: () => {
        return {
            bannerClosed: false,
            gdpr: false
        }
    },
    persist: {
        key: 'ttp:preferences',
        storage: persistedState.cookiesWithOptions({
            expires: new Date(),
            maxAge: 34560000,
            sameSite: 'strict',
        })
    }
})
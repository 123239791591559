<template>
    <div class="oggettoNewsletter" v-if="card">
        <CardStandard :cover="cover" :tassonomia="tassonomia" :titolo="titolo" :link="link.address" :linkName="link.addressName" :isExternal="link.external" />
    </div>
</template>

<script lang="ts" setup>
const { getCardCover, getCardTitolo } = useCard()
const { localeProperties } = useI18n()
const props = defineProps<{ card: any }>()

const cover = computed(() => getCardCover(props.card))
const tassonomia = computed(() => {
    if (props.card.data) {
        const date = new Date(props.card.data)
        if (!isNaN(date.getTime())) return date.toLocaleDateString(localeProperties.value.language, { day: 'numeric', month: 'long' })
        else return null
    } else return null
})
const titolo = computed(() => {
    if (props.card.translations && props.card.translations.length > 0) return props.card.translations[0].titolo
    else return ''
})
const link = computed(() => {
    if (props.card.translations && props.card.translations.length > 0) return {
        address: props.card.translations[0].link,
        addressName: props.card.translations[0].titolo,
        external: true
    }
    else return ''
})
</script>
<template>
    <section class="moduloPress">
        <div class="cont">
            <OggettoHeadModulo :modulo="modulo" />
            <div class="press" v-if="cardsByYear && cardsByYear.length > 0">
                <template v-if="cardsByYear[0].current === true">
                    <div class="lista" v-if="modulo.tipologia == 'lista'">
                        <OggettoDocumentoLista v-for="card in cardsByYear[0].cards.sort((a: any, b: any) => b.timestamp - a.timestamp)" :key="card.id" :card="card.item" :typename="card.item.__typename || card.collection"></OggettoDocumentoLista>
                    </div>
                    <div class="grid" v-else-if="modulo.tipologia == 'grid'">
                        <div class="gridItem" v-for="card in cardsByYear[0].cards.sort((a: any, b: any) => b.timestamp - a.timestamp)" :key="card.id">
                            <OggettoDocumentoCard :card="card" :typename="card.item.__typename || card.collection"></OggettoDocumentoCard>
                        </div>
                    </div>
                </template>
                <ListaPress v-for="group in cardsByYear.filter((c) => c.current === false)" :key="group.year" :group="group" :tipologia="modulo.tipologia"></ListaPress>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const props = defineProps<{ modulo: any }>()

const cardsByYear = computed(() => {
    const cards = <any[]>[]
    for (const card of props.modulo.card) {
        if (card.item.__typename == 'link_file_datati' || card.item.__typename == 'link_esterni_datati') {
            const year = new Date(card.item.data).getFullYear()
            if (!cards.find((c) => c.year == year)) cards.push({ year, current: year == new Date().getFullYear(), cards: [] })
            cards.find((c) => c.year == year)?.cards.push({
                timestamp: new Date(card.item.data).getTime(),
                ...card
            })
        }
    }
    return cards.sort((a, b) => b.year - a.year)
})
</script>

<style lang="scss" scoped>
.moduloPress {

    .cont,
    .press {
        display: flex;
        flex-direction: column;
        gap: var(--article-gap-inner);
    }

    .lista {
        display: flex;
        flex-direction: column;
        gap: var(--margin-gap);
    }

    .grid {
        display: grid;
        gap: var(--margin-gap);
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-auto-rows: 400px;

        .gridItem {
            position: relative;
        }
    }
}
</style>
<template>
    <div class="oggettoPaginaCollegata" v-if="pagina">
        <div class="cardCont">
            <CardStandard :cover="pagina.cover" :titolo="titolo" :tassonomia="tassonomia" :width="750" :height="400" :mobileHeight="800" :link="link.address" :isExternal="link.external" />
        </div>
    </div>
</template>

<script lang="ts" setup>
const { getCardTitolo, getCardTassonomia, getCardLink } = useCard()
const props = defineProps<{ pagina: any }>()

const titolo = computed(() => getCardTitolo({ item: props.pagina }))
const tassonomia = computed(() => getCardTassonomia({ item: props.pagina }, 'pagine'))
const link = computed(() => getCardLink({ item: props.pagina }, 'pagine'))
</script>

<style lang="scss" scoped>
.cardCont {
    position: relative;
    height: 400px;
}
</style>
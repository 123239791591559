<template>
    <div class="oggettoGenericoLista">
        <CardLista :cover="cover" :tag="tassonomia" :titolo="titolo" :descrizione="descrizione" :link="link.address" :isExternal="link.external" :imageTag="imageTag" :logo="typename == 'operatori_incoming'">
            <template #end>
                <div class="end">
                    <div class="small" v-if="luogo">{{ luogo }}</div>
                    <div class="small" v-if="ricorrenza">{{ ricorrenza }}</div>
                    <div class="small aderisce" v-if="aderisce && aderisce.length > 0">
                        <div class="adesione" v-for="adesione in aderisce">
                            <NIcon :size="16" color="var(--color-azure-dark)" aria-label="Ticket">
                                <IconTicket />
                            </NIcon>
                            <span>{{ adesione }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </CardLista>
    </div>
</template>

<script lang="ts" setup>
import { NIcon } from 'naive-ui';
const { getCardCover, getCardTitolo, getListaTassonomia, getCardDescrizione, getCardLuogo, getCardRicorrenza, getCardAderisce, getCardLink } = useCard()
const props = defineProps<{ card: any, typename?: string | null }>()

const cover = computed(() => getCardCover(props.card))
const titolo = computed(() => getCardTitolo(props.card))
const tassonomia = computed(() => getListaTassonomia(props.card, props.typename))
const descrizione = computed(() => getCardDescrizione(props.card))
const luogo = computed(() => getCardLuogo(props.card))
const ricorrenza = computed(() => getCardRicorrenza(props.card))
const aderisce = computed(() => getCardAderisce(props.card))
const link = computed(() => getCardLink(props.card, props.typename))

const imageTag = computed(() => {
    if (props.card.scaduto === true || (props.card.item && props.card.item.scaduto === true)) return 'concluso'
    else return null
})
</script>

<style lang="scss" scoped>
.end {
    text-transform: uppercase;

    .aderisce {
        margin-bottom: -4px;
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        gap: var(--margin-gap);

        .adesione {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }
}
</style>
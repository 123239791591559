<template>
    <article v-if="pagina">

        <!-- BODY -->
        <LazyModuloBody v-if="hasBody" :body="pagina.translations[0]?.body" />

        <!-- MODULI -->
        <template v-for="(modulo, index) in pagina.moduli">

            <!-- CAROUSEL -->
            <LazyDelayHydration v-if="modulo.item && modulo.item.__typename == 'modulo_carousel' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloCarousel :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloCarousel v-else-if="modulo.item && modulo.item.__typename == 'modulo_carousel'" :modulo="modulo.item" />

            <!-- CAROUSEL SOCIAL -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_carousel_social' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloCarouselSocial :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloCarouselSocial v-else-if="modulo.item && modulo.item.__typename == 'modulo_carousel_social'" :modulo="modulo.item" />

            <!-- LISTA -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_lista' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloLista :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloLista v-else-if="modulo.item && modulo.item.__typename == 'modulo_lista'" :modulo="modulo.item" />

            <!-- ALLEGATI -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_allegati' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloAllegati :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloAllegati v-else-if="modulo.item && modulo.item.__typename == 'modulo_allegati'" :modulo="modulo.item" />

            <!-- DON'T MISS -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_dontmiss' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloDontMiss :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloDontMiss v-else-if="modulo.item && modulo.item.__typename == 'modulo_dontmiss'" :modulo="modulo.item" />

            <!-- GRID -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_grid' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloGrid :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloGrid v-else-if="modulo.item && modulo.item.__typename == 'modulo_grid'" :modulo="modulo.item" />

            <!-- BIG CARD -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_bigcard' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloBigCard :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloBigCard v-else-if="modulo.item && modulo.item.__typename == 'modulo_bigcard'" :modulo="modulo.item" />

            <!-- CARD -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_card' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloCard :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloCard v-else-if="modulo.item && modulo.item.__typename == 'modulo_card'" :modulo="modulo.item" />

            <!-- TEXT -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_text' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloTesto :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloTesto v-else-if="modulo.item && modulo.item.__typename == 'modulo_text'" :modulo="modulo.item" />

            <!-- IMAGE -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_immagine' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloImmagine :image="modulo.item.immagine" />
            </LazyDelayHydration>
            <LazyModuloImmagine v-else-if="modulo.item && modulo.item.__typename == 'modulo_immagine'" :image="modulo.item.immagine" />

            <!-- VIDEO -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_video' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloVideo :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloVideo v-else-if="modulo.item && modulo.item.__typename == 'modulo_video'" :modulo="modulo.item" />

            <!-- OUTDOOR ACTIVE -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_outdooractive' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloOutdoorActive :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloOutdoorActive v-else-if="modulo.item && modulo.item.__typename == 'modulo_outdooractive'" :modulo="modulo.item" />

            <!-- CALENDARIO -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_calendario' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloCalendario :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloCalendario v-else-if="modulo.item && modulo.item.__typename == 'modulo_calendario'" :modulo="modulo.item" />

            <!-- CERCA -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_cerca' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloCerca :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloCerca v-else-if="modulo.item && modulo.item.__typename == 'modulo_cerca'" :modulo="modulo.item" />

            <!-- MAP -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_map' && (index > 0 || (index == 0 && hasBody))">
                <LazyMappaPagine :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyMappaPagine v-else-if="modulo.item && modulo.item.__typename == 'modulo_map'" :modulo="modulo.item" />

            <!-- MAP DESIGN -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_map_design' && (index > 0 || (index == 0 && hasBody))">
                <LazyMappaDesign :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyMappaDesign v-else-if="modulo.item && modulo.item.__typename == 'modulo_map_design'" :modulo="modulo.item" />

            <!-- NEWSLETTER FORM -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_newsletter' && modulo.item.tipologia == 'form' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloNewsletterForm :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloNewsletterForm v-else-if="modulo.item && modulo.item.__typename == 'modulo_newsletter' && modulo.item.tipologia == 'form'" :modulo="modulo.item" />

            <!-- NEWSLETTER GRID -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_newsletter' && modulo.item.tipologia == 'griglia' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloNewsletterGrid :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloNewsletterGrid v-else-if="modulo.item && modulo.item.__typename == 'modulo_newsletter' && modulo.item.tipologia == 'griglia'" :modulo="modulo.item" />

            <!-- EXTRA -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_extra' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloExtra :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloExtra v-else-if="modulo.item && modulo.item.__typename == 'modulo_extra'" :modulo="modulo.item" />

            <!-- PRESS -->
            <LazyDelayHydration v-else-if="modulo.item && modulo.item.__typename == 'modulo_press' && (index > 0 || (index == 0 && hasBody))">
                <LazyModuloPress :modulo="modulo.item" />
            </LazyDelayHydration>
            <LazyModuloPress v-else-if="modulo.item && modulo.item.__typename == 'modulo_press'" :modulo="modulo.item" />

            <!--<ModuleLogoGrid7 v-else-if="modulo.module && modulo.module.meta && modulo.module.meta.moduleName == 'LogoGrid7'" :modulo="modulo" />-->
        </template>

    </article>
</template>

<script lang="ts" setup>
const { createPagineRoutes } = useSitemap()
const { localeProperties } = useI18n()
const preferences = usePreferencesStore()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()

const { gdpr } = storeToRefs(preferences)
const props = defineProps<{ pagina: any }>()


useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }],
});
useSeoMeta({
    title: () => props.pagina && props.pagina.translations && props.pagina.translations[0]?.og_title ? `${props.pagina.translations[0].og_title} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.pagina && props.pagina.translations && props.pagina.translations[0]?.og_title ? `${props.pagina.translations[0].og_title} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.pagina && props.pagina.translations && props.pagina.translations[0]?.og_title ? `${props.pagina.translations[0].og_title} | Turismo Torino e Provincia` : null,

    description: () => props.pagina && props.pagina.translations && props.pagina.translations[0]?.og_description ? props.pagina.translations[0].og_description : null,
    ogDescription: () => props.pagina && props.pagina.translations && props.pagina.translations[0]?.og_description ? props.pagina.translations[0].og_description : null,
    twitterDescription: () => props.pagina && props.pagina.translations && props.pagina.translations[0]?.og_description ? props.pagina.translations[0].og_description : null,

    ogImage: () => props.pagina && props.pagina.cover && props.pagina.cover.height && props.pagina.cover.width ? `${directusUrl}assets/${props.pagina.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.pagina && props.pagina.cover && props.pagina.cover.height && props.pagina.cover.width ? `${directusUrl}assets/${props.pagina.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

if (!props.pagina.lingue.includes(localeProperties.value.code)) throw createError({ statusCode: 404 });
const pagineRoutes = createPagineRoutes(props.pagina)
if (pagineRoutes) setI18nParams(pagineRoutes)

const hasBody = computed(() => {
    if (props.pagina.translations && props.pagina.translations[0]?.body) return true
    else return false
})
const itemListElement = computed(() => {
    const elements = <any[]>[]
    const parents = <{ slug: string, titolo: string }[]>[]
    const urls = <string[]>[]

    let hasMoreParents = true
    let currentParent = props.pagina.parent

    if (currentParent) {
        while (hasMoreParents) {
            if (currentParent.translations && currentParent.translations.length > 0) {
                const localeParentTranslation = currentParent.translations.find((t: any) => t.languages_code.code == localeProperties.value.code)
                if (localeParentTranslation && localeParentTranslation.slug) {
                    parents.push(localeParentTranslation)
                }
            }

            if (currentParent.parent) currentParent = currentParent.parent
            else hasMoreParents = false
        }
    }

    for (const p of parents.reverse()) {
        urls.push(p.slug)
        elements.push({
            '@type': 'ListItem',
            position: urls.length,
            name: p.titolo,
            item: `https://turismotorino.org/${urls.join('/')}`
        })
    }

    elements.push({
        '@type': 'ListItem',
        position: urls.length + 1,
        name: props.pagina.translations?.[0]?.titolo,
        item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
    })

    return elements
})

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            itemListElement: itemListElement.value
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.pagina && props.pagina.translations && props.pagina.translations[0]?.og_title ? props.pagina.translations[0].og_title : 'Turismo Torino e Provincia',
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.pagina && props.pagina.cover && props.pagina.cover.height && props.pagina.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.pagina.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.pagina.cover.height * 1600 / props.pagina.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.pagina.cover.title
            } : undefined,
            datePublished: props.pagina && props.pagina.date_created ? new Date(props.pagina.date_created).toJSON() : undefined,
            dateModified: props.pagina && props.pagina.date_updated ? new Date(props.pagina.date_updated).toJSON() : undefined,
            description: props.pagina && props.pagina.translations && props.pagina.translations[0]?.og_description ? props.pagina.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                    ]
                }
            ]
        }
    ]
}))

// Pixel Meta Play TO Tennis
if (props.pagina && props.pagina.id == 74640) {
    useScriptMetaPixel({
        id: '3703178729968572',
        scriptOptions: {
            trigger: useScriptTriggerConsent({
                consent: gdpr
            })
        }
    })
}
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);
}
</style>
<template>
    <div class="oggettoAttivitaOutdoor" v-if="attivita">
        <CardMini :cover="attivita.cover" :titolo="attivita.translations && attivita.translations.length > 0 ? attivita.translations[0].titolo : null" :link="getCardLink({item: attivita}, 'attivita').address">
            <template #end>
                <div class="end">
                    <div class="small" v-if="attivita.comune_partenza">{{ attivita.comune_partenza.nome }}</div>
                    <div class="small" v-else-if=attivita.comune_suggerito>{{ attivita.comune_suggerito }}</div>
                    <div class="small" v-if="dataSottotitolo">{{ dataSottotitolo }}</div>
                </div>
            </template>
        </CardMini>
    </div>
</template>

<script lang="ts" setup>
const { verbalizzaSottotitolo } = useOrari()
const { getCardLink } = useCard()
const props = defineProps<{ attivita: any }>()

const dataSottotitolo = computed(() => {
    if (props.attivita && props.attivita.date) {
        return verbalizzaSottotitolo(props.attivita.date)
    } else return null
})
</script>

<style lang="scss" scoped>
.oggettoAttivitaOutdoor {
    .end {
        text-transform: uppercase;
    }
}
</style>
<template>
    <ModuloMappa>
        <template #featureGroup>
            <MappaMarkerGroup v-for="gruppo in geoGroups" :gruppo="gruppo" />
        </template>
    </ModuloMappa>
</template>

<script lang="ts" setup>
import { encodeBase32 } from 'geohashing';
const { getCardLink } = useCard()
const { searchMeilimoduleCardsMap } = useSearch()
const props = defineProps<{ modulo: any }>()

const { data: generatedPunti } = props.modulo.contenuto == 'automatico' && props.modulo.translations_formula[0] ? useAsyncData(`${JSON.stringify(props.modulo.translations_formula[0].formula)}-map`, () => searchMeilimoduleCardsMap(props.modulo.translations_formula[0].formula)) : { data: ref(null) }

const geoGroups = computed(() => {
    const puntiHash = []

    if (props.modulo.contenuto == 'manuale') {
        for (const punto of props.modulo.punti) {
            switch (punto.item.__typename) {
                case 'punto':
                    if (punto.item.coordinate) {
                        puntiHash.push({
                            hash: encodeBase32(punto.item.coordinate.coordinates[1], punto.item.coordinate.coordinates[0], 8),
                            latlng: [punto.item.coordinate.coordinates[1], punto.item.coordinate.coordinates[0]],
                            titolo: punto.item.translations[0]?.etichetta
                        })
                    }
                    break;
                case 'pagine':
                case 'risorse':
                    // TODO altri
                    //console.log(punto)
                    if (punto.item.geolocalizzazione) {
                        puntiHash.push({
                            hash: encodeBase32(punto.item.geolocalizzazione.coordinates[1], punto.item.geolocalizzazione.coordinates[0], 8),
                            latlng: [punto.item.geolocalizzazione.coordinates[1], punto.item.geolocalizzazione.coordinates[0]],
                            titolo: punto.item.translations[0]?.titolo,
                            link: getCardLink(punto, punto.item.__typename).address
                        })
                    }
                    break;
            }
        }
    } else if (props.modulo.contenuto == 'automatico') {
        if (generatedPunti.value && generatedPunti.value.length > 0) {
            for (const punto of generatedPunti.value.filter((p: any) => p && p._geo && p._geo.lat && p._geo.lng)) {
                puntiHash.push({
                    hash: encodeBase32(punto._geo.lat, punto._geo.lng, 8),
                    latlng: [punto._geo.lat, punto._geo.lng],
                    titolo: punto.titolo,
                    link: punto.link
                })
            }
        }
        else return []
    } else return []

    const gruppi = puntiHash.reduce(
        (result: any, punto: any) => ({
            ...result,
            [punto.hash]: [
                ...(result[punto.hash] || []),
                punto,
            ],
        }),
        {},
    )

    return Object.keys(gruppi).map((key) => [key, gruppi[key]])

})
</script>
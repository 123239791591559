export const useFunctions = () => {

    const isObject = (object: any) => {
        return object != null && typeof object === "object";
    };

    const isDeepEqual = (object1: any, object2: any) => {

        const objKeys1 = Object.keys(object1);
        const objKeys2 = Object.keys(object2);

        if (objKeys1.length !== objKeys2.length) return false;

        for (var key of objKeys1) {
            const value1 = object1[key];
            const value2 = object2[key];

            const isObjects = isObject(value1) && isObject(value2);

            if ((isObjects && !isDeepEqual(value1, value2)) ||
                (!isObjects && value1 !== value2)
            ) {
                return false;
            }
        }
        return true;
    }

    const extractDomain = (address: string) => {
        if (address.indexOf('www.') == 0) {
            let domain = (new URL(`https://${address}`));
            return domain.hostname.replace('www.', '')
        } else if (address.indexOf('http://') == 0 || address.indexOf('https://') == 0) {
            let domain = (new URL(address));
            return domain.hostname.replace('www.', '')
        } else return null
    }

    const getUTCMidnightTime = (date: Date) => {
        const utcMidnight = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        return utcMidnight.getTime()
    }

    const formatEuro = (prezzo: number, divisoCento: boolean = true) => {
        const { localeProperties } = useI18n()
        const euro = new Intl.NumberFormat(localeProperties.value.language, { style: 'currency', currency: 'EUR' });
        if (prezzo) {
            if (divisoCento) return euro.format(prezzo / 100)
            else return euro.format(prezzo)
        } else {
            return euro.format(0)
        }
    }

    const padTo2Digits = (num: number) => {
        return num.toString().padStart(2, '0');
    }

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    return {
        isObject,
        isDeepEqual,
        extractDomain,
        getUTCMidnightTime,
        formatEuro,
        padTo2Digits,
        capitalizeFirstLetter
    }
}
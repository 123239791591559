<template>
    <section class="moduloVideo">
        <div class="cont">
            <OggettoHeadModulo :modulo="modulo" />
            <div class="innerCont">
                <div class="videoCont">
                    <div class="filler"></div>
                    <ScriptYouTubePlayer v-if="videoID" ref="video" :video-id="videoID" :player-vars="playerVars" class="youtubePlayer" @ready="isLoaded = true" @state-change="stateChange">
                        <template #awaitingLoad>
                            <div class="playIcon">
                                <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
                                    <path d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00" />
                                    <path d="M 45,24 27,14 27,34" fill="#fff" />
                                </svg>
                            </div>
                        </template>
                    </ScriptYouTubePlayer>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const props = defineProps<{ modulo: any }>()

const { extractID } = useVideos()
const video = ref()
const isLoaded = ref(false)
const isPlaying = ref(false)

const playerVars = ref({
    modestbranding: 1,
    showinfo: 0,
    rel: 0,
})

const play = async () => {
    await video.value.player.playVideo()
}
const stateChange = (event: any) => {
    isPlaying.value = event.data === 1
}

const videoID = computed(() => {
    if (props.modulo.tipologia == 'youtube') return extractID(props.modulo.link_youtube)
    else return null
})
</script>

<style lang="scss" scoped>
.moduloVideo {
    .cont {
        display: flex;
        flex-direction: column;
        gap: var(--article-gap-inner);
    }

    .videoCont {
        position: relative;
        width: 100%;

        background-color: black;
        border-radius: var(--size-card-radius);
        overflow: hidden;

        .filler {
            padding-top: 56.20%;
        }

        &:deep(.youtubePlayer) {
            position: absolute !important;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            aspect-ratio: 16 / 9 !important;

            &>img {
                object-fit: cover !important;
            }
        }

        &:deep(.playIcon) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            height: 48px;
            width: 64px;
        }
    }
}
</style>
<template>
    <section class="listaSottoeventi">
        <div class="cont">
            <div class="innerCont">
                <h2 class="title3">{{ $t('servizi_pacchetti_proposti') }}</h2>
                <div class="lista" v-if="sottoeventiFuturi.length > 0">
                    <OggettoPacchettoIncoming v-for="evento in sottoeventiFuturi" :pacchetto="evento" :key="evento.id" />
                </div>
                <template v-if="sottoeventiPassati.length > 0">
                    <div :class="['estendi', 'noselect', { open: passatiOpen }]" @click="togglePassati">
                        <div class="titlebody">{{ $t('attivita_passate') }}</div>
                        <NIconWrapper :size="22" :border-radius="11">
                            <NIcon size="1.176rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound" :aria-label="$t('apri')"></NIcon>
                        </NIconWrapper>
                    </div>
                    <NCollapseTransition :show="passatiOpen">
                        <div class="listaPassati">
                            <OggettoPacchettoIncoming v-for="evento in sottoeventiPassati" :pacchetto="evento" :key="evento.id" />
                        </div>
                    </NCollapseTransition>
                </template>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { NIconWrapper, NIcon, NCollapseTransition } from 'naive-ui';
import KeyboardArrowRightRound from '@vicons/material/KeyboardArrowRightRound'

const { seAttivitaPassata, sortAttivitaByOrario } = useOrari()
const props = defineProps<{ pacchetti: any[] }>()
const passatiOpen = ref(false)

const sottoeventiFuturi = computed(() => {
    const eventi = []
    if (props.pacchetti && props.pacchetti.length > 0) eventi.push(...props.pacchetti.filter((e: any) => !seAttivitaPassata(e.date)))
    if (eventi.length > 0) return sortAttivitaByOrario(eventi, 'asc')
    else return []
})
const sottoeventiPassati = computed(() => {
    const eventi = []
    if (props.pacchetti && props.pacchetti.length > 0) eventi.push(...props.pacchetti.filter((e: any) => seAttivitaPassata(e.date)))
    if (eventi.length > 0) return sortAttivitaByOrario(eventi, 'desc')
    else return []
})

const togglePassati = () => {
    passatiOpen.value = !passatiOpen.value
}
</script>

<style lang="scss" scoped>
.listaSottoeventi {

    .innerCont {
        display: flex;
        flex-direction: column;
        gap: var(--margin-double);

        .lista {
            display: flex;
            flex-direction: column;
            gap: var(--margin-gap);
        }

        .estendi {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &:deep(.n-icon-wrapper) {
                svg {
                    transition: transform 300ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
                }
            }

            &.open {
                &:deep(.n-icon-wrapper) {
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .listaPassati {
            display: flex;
            flex-direction: column;
            gap: var(--margin-gap);
        }
    }
}
</style>
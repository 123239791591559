<template>
    <div class="link">
        <template v-for="risorsa in risorse">
            <div class="risorsa" v-if="risorsa.risorse_id">
                <NuxtLinkLocale v-if="risorsa.risorse_id.translations && risorsa.risorse_id.translations.length > 0" :to="getCardLink({ item: risorsa.risorse_id }, 'risorse').address">{{ risorsa.risorse_id.translations[0].titolo }}</NuxtLinkLocale>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
const { getCardLink } = useCard()
defineProps<{ risorse: any[] }>()
</script>

<style lang="scss" scoped>
.link {
    display: flex;
    flex-direction: column;

    .risorsa {
        font-size: var(--font-size-body);
        line-height: var(--font-line-body);
        color: var(--color-azure-dark);
        font-weight: bold;

        a {
            text-decoration: none;
        }
    }
}
</style>
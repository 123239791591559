<template>
    <article v-if="visita">

        <section class="visitaInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBody v-if="visita.translations && visita.translations[0]?.body" :body="visita.translations[0]?.body" />

                        <!-- IMMAGINI -->
                        <ModuloImmagine v-if="visita.media && visita.media.length == 1" :image="visita.media[0]" />
                        <ModuloMedia v-else-if="visita.media && visita.media.length > 1" :media="visita.media" />

                        <!-- CONTATTI -->
                        <SezioneShare />
                        <LazySkipHydration>
                            <LazySezioneContatti :contatti="visita" />
                        </LazySkipHydration>

                        <!-- ORARI / TARIFFE / FILE -->
                        <SezioneCardEvento :evento="visita" />
                        <SezioneAccordionVisitaGuidata :visita="visita" />

                        <!-- MACROEVENTI / SOTTOEVENTI -->
                        <ListaMacroeventi v-if="visita.inserito_in && visita.inserito_in.length > 0" :macroeventi="visita.inserito_in" />

                    </div>
                    <aside>

                        <!-- PRODOTTI -->
                        <ListaSideProdotti v-if="visita.aderisce_a && visita.aderisce_a.length > 0" :prodotti="visita.aderisce_a" />

                    </aside>
                </div>
            </div>
        </section>

        <!-- MAPPA -->
        <MappaCoordinate v-if="visita.geolocalizzazione && visita.geolocalizzazione.coordinates" :latitude="visita.geolocalizzazione.coordinates[1]" :longitude="visita.geolocalizzazione.coordinates[0]" />

    </article>
</template>

<script lang="ts" setup>
const { localeProperties, t } = useI18n()
const { dizionario, createVisiteGuidateRoutes } = useSitemap()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()
const props = defineProps<{ visita: any }>()

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }]
});
useSeoMeta({
    title: () => props.visita && props.visita.translations && props.visita.translations[0]?.og_title ? `${props.visita.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.visita && props.visita.translations && props.visita.translations[0]?.og_title ? `${props.visita.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.visita && props.visita.translations && props.visita.translations[0]?.og_title ? `${props.visita.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,

    description: () => props.visita && props.visita.translations && props.visita.translations[0]?.og_description ? props.visita.translations[0].og_description : null,
    ogDescription: () => props.visita && props.visita.translations && props.visita.translations[0]?.og_description ? props.visita.translations[0].og_description : null,
    twitterDescription: () => props.visita && props.visita.translations && props.visita.translations[0]?.og_description ? props.visita.translations[0].og_description : null,

    ogImage: () => props.visita && props.visita.cover && props.visita.cover.height && props.visita.cover.width ? `${directusUrl}assets/${props.visita.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.visita && props.visita.cover && props.visita.cover.height && props.visita.cover.width ? `${directusUrl}assets/${props.visita.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

const visiteGuidateRoutes = createVisiteGuidateRoutes(props.visita)
if (visiteGuidateRoutes) setI18nParams(visiteGuidateRoutes)

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            itemListElement: [
                {
                    '@type': 'ListItem',
                    position: 1,
                    name: dizionario.titoloVisita[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 2,
                    name: dizionario.titoloEventi[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkEventi[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 3,
                    name: dizionario.titoloVisiteGuidate[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkEventi[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkVisiteGuidate[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 4,
                    name: props.visita.translations[0]?.titolo,
                    item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                }
            ]
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.visita && props.visita.translations && props.visita.translations[0]?.og_title ? props.visita.translations[0].og_title : undefined,
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.visita && props.visita.cover && props.visita.cover.height && props.visita.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.visita.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.visita.cover.height * 1600 / props.visita.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.visita.cover.title
            } : undefined,
            datePublished: props.visita && props.visita.date_created ? new Date(props.visita.date_created).toJSON() : undefined,
            dateModified: props.visita && props.visita.date_updated ? new Date(props.visita.date_updated).toJSON() : undefined,
            description: props.visita && props.visita.translations && props.visita.translations[0]?.og_description ? props.visita.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org/${localeProperties.value.code}`
                    ]
                }
            ]
        },
        {
            '@type': 'Event',
            '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#event`,
            name: props.visita && props.visita.translations && props.visita.translations[0]?.og_title ? props.visita.translations[0].og_title : undefined,
            description: props.visita && props.visita.translations && props.visita.translations[0]?.og_description ? props.visita.translations[0].og_description : undefined,
            image: props.visita.media ? props.visita.media.map((m: any) => `${directusUrl}assets/${m.directus_files_id.id}?width=1600&format=jpeg`) : undefined,
            url: props.visita.link_esterni && props.visita.link_esterni.filter((l: any) => l.tipologia == 'website').length > 0 ? props.visita.link_esterni.filter((l: any) => l.tipologia == 'website')[0].link : undefined,
            eventStatus: "https://schema.org/EventScheduled",
            eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
            location: props.visita.indirizzo ? {
                "@type": "Place",
                address: {
                    "@type": "PostalAddress",
                    streetAddress: props.visita.indirizzo,
                    addressLocality: props.visita.comune ? props.visita.comune.nome : undefined,
                    postalCode: props.visita.cap,
                    addressCountry: "IT"
                }
            } : undefined,
            offers: props.visita.link_esterni && props.visita.link_esterni.filter((l: any) => l.tipologia == 'acquista' || l.tipologia == 'prenota').length > 0 ?
                props.visita.link_esterni.filter((l: any) => l.tipologia == 'acquista' || l.tipologia == 'prenota').map((l: any) => ({
                    '@type': 'Offer',
                    url: l.link,
                })) : undefined,
            sameAs: props.visita.link_esterni ? props.visita.link_esterni.filter((l: any) => l.tipologia != 'acquista' && l.tipologia != 'prenota').map((l: any) => l.link) : undefined,
            startDate: props.visita.date_visite_guidate && props.visita.date_visite_guidate.length > 0 ? props.visita.date_visite_guidate.sort((a: any, b: any) => a.data.localeCompare(b.data))[0].data : undefined,
            endDate: props.visita.date_visite_guidate && props.visita.date_visite_guidate.length > 0 ? props.visita.date_visite_guidate.sort((a: any, b: any) => a.data.localeCompare(b.data))[props.visita.date_visite_guidate.length - 1].data : undefined
        }
    ]
}))
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .visitaInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .visitaInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>
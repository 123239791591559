<template>
    <section class="moduloOutdoorActive">
        <div class="cont">
            <div :class="['outdoorMap', { large: modulo.vista == 'da9plr' }]" ref="outdoorMap"></div>
        </div>
    </section>
</template>

<script lang="ts" setup>
const { locale } = useI18n()
const outdoorMap = ref()
const props = defineProps<{ modulo: any }>()

onMounted(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://api-oa.com/jscr/loader.js?id=${props.modulo.vista}&zc=10,7.79617,45.07934&lang=${locale.value}`;
    script.async = true;
    if (outdoorMap.value) outdoorMap.value.appendChild(script);
})
</script>


<style lang="scss" scoped>
.moduloOutdoorActive {

    .outdoorMap {
        height: 600px;
        width: 100%;

        background-color: #F3F3F3;
        border-radius: var(--size-card-radius);
        border: 1px solid var(--color-border-input);
        box-sizing: border-box;
        overflow: hidden;

        &.large {
            height: 699px;
        }
    }
}
</style>
export const useProdotti = () => {

    const directus = useDirectus()

    const bundleAcquistabili = async (id?: string, locale?: string) => {
        try {
            if (id) {
                const response = await directus(`/prodotti-shop/bundle/${id}${locale ? '?locale=' + locale : ''}`)
                return response
            } else {
                const response = await directus(`/prodotti-shop/bundle${locale ? '?locale=' + locale : ''}`)
                return response
            }
        } catch (error: any) {
            if (error.data && error.data.message) return error.data
            else return { message: 'GENERIC' }
        }
    }

    const checkDisponibilitaProdotto = async (tipologia_acquistabile: string, id: string) => {
        try {
            const response = await directus(`/prodotti-shop/disponibilita/${tipologia_acquistabile}/${id}`)
            return response
        } catch (error: any) {
            if (error.data && error.data.message) return error.data
            else return { message: 'GENERIC' }
        }
    }

    return {
        bundleAcquistabili,
        checkDisponibilitaProdotto
    }

}
export const useVideos = () => {

    const stripParameters = (shortcodeString: string) => {
        if (shortcodeString.includes('?')) shortcodeString = shortcodeString.split('?')[0];
        if (shortcodeString.includes('/')) shortcodeString = shortcodeString.split('/')[0];
        if (shortcodeString.includes('&')) shortcodeString = shortcodeString.split('&')[0];
        return shortcodeString;
    }

    const vimeo = (vimeoString: string) => {
        let string_ = vimeoString;

        if (string_.includes('#')) {
            [string_] = string_.split('#');
        }

        if (string_.includes('?') && !string_.includes('clip_id=')) {
            [string_] = string_.split('?');
        }

        let id;
        let array;

        const event = /https?:\/\/vimeo\.com\/event\/(\d+)$/;

        const eventMatches = event.exec(string_);

        if (eventMatches && eventMatches[1]) {
            return eventMatches[1];
        }

        const primary = /https?:\/\/vimeo\.com\/(\d+)/;

        const matches = primary.exec(string_);
        if (matches && matches[1]) {
            return matches[1];
        }

        const vimeoPipe = [
            'https?://player.vimeo.com/video/[0-9]+$',
            'https?://vimeo.com/channels',
            'groups',
            'album',
        ].join('|');

        const vimeoRegex = new RegExp(vimeoPipe, 'gim');

        if (vimeoRegex.test(string_)) {
            array = string_.split('/');
            if (array && array.length > 0) {
                id = array.pop();
            }
        } else if (/clip_id=/gim.test(string_)) {
            array = string_.split('clip_id=');
            if (array && array.length > 0) {
                [id] = array[1].split('&');
            }
        }

        return id;
    }

    const youtube = (youtubeString: string) => {
        let string_ = youtubeString;

        string_ = string_.replace('-nocookie', '');
        string_ = string_.replace(/#t=.*$/, '');
        string_ = string_.replace(/^https?:\/\//, '');

        const shortcode = /youtube:\/\/|youtu\.be\/|y2u\.be\//g;
        if (shortcode.test(string_)) {
            const shortcodeid = string_.split(shortcode)[1];
            return stripParameters(shortcodeid);
        }

        const shortsUrl = /\/shorts\//g;
        if (shortsUrl.test(string_)) {
            return stripParameters(string_.split(shortsUrl)[1]);
        }

        const parameterv = /v=|vi=/g;
        if (parameterv.test(string_)) {
            const array = string_.split(parameterv);
            return stripParameters(array[1].split('&')[0]);
        }

        const inlinev = /\/v\/|\/vi\/|\/watch\//g;
        if (inlinev.test(string_)) {
            const inlineid = string_.split(inlinev)[1];
            return stripParameters(inlineid);
        }

        const parameterwebp = /\/an_webp\//g;
        if (parameterwebp.test(string_)) {
            const webp = string_.split(parameterwebp)[1];
            return stripParameters(webp);
        }

        const eformat = /\/e\//g;
        if (eformat.test(string_)) {
            const estring = string_.split(eformat)[1];
            return stripParameters(estring);
        }

        const embedreg = /\/embed\//g;
        if (embedreg.test(string_)) {
            const embedid = string_.split(embedreg)[1];
            return stripParameters(embedid);
        }

        const usernamereg = /\/user\/([a-zA-Z\d]*)$/g;
        if (usernamereg.test(string_)) {
            return undefined;
        }

        const userreg = /\/user\/(?!.*videos)/g;
        if (userreg.test(string_)) {
            const elements = string_.split('/');
            const elementspop = elements.pop()
            if (elementspop) return stripParameters(elementspop);
        }

        const attrreg = /\/attribution_link\?.*v%3D([^%&]*)(%26|&|$)/;
        if (attrreg.test(string_)) {
            const stringmatch = string_.match(attrreg)?.[1]
            if (stringmatch) return stripParameters(stringmatch);
        }

        const livereg = /\/live\//g;

        if (livereg.test(string_)) {
            const liveid = string_.split(livereg)[1];
            return stripParameters(liveid);
        }

        return undefined;
    }

    const extractID = (url: string) => {
        if (/youtube|youtu\.be|y2u\.be|i.ytimg\./.test(url)) {
            return youtube(url)
        } else if (/vimeo/.test(url)) {
            return vimeo(url)
        } else return null
    }

    return {
        extractID,
    }
}
<template>
    <div class="heroCont">
        <div class="hero">
            <div class="controlsZone">
                <div class="contentZone">
                    <div class="controls">
                        <NButton size="medium" secondary circle @click="swipePrev" tabindex="-1" :aria-label="$t('indietro')">
                            <template #icon>
                                <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowLeftRound"></NIcon>
                            </template>
                        </NButton>
                        <NButton size="medium" secondary circle @click="swipeNext" tabindex="-1" :aria-label="$t('avanti')">
                            <template #icon>
                                <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound"></NIcon>
                            </template>
                        </NButton>
                    </div>
                </div>
            </div>
            <swiper-container :modules="[Autoplay]" :autoplay="autoplayOptions" :slides-per-view="1" :slides-per-group="1" :grab-cursor="true" :loop="true" ref="swiper">
                <slot name="content"></slot>
            </swiper-container>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NButton, NIcon } from 'naive-ui'
import { Autoplay } from 'swiper/modules'; // TODO? https://swiperjs.com/element#core-version--modules
import KeyboardArrowLeftRound from '@vicons/material/KeyboardArrowLeftRound'
import KeyboardArrowRightRound from '@vicons/material/KeyboardArrowRightRound'

interface Props {
    holdBackground?: string
}
withDefaults(defineProps<Props>(), {
    holdBackground: 'transparent'
})

const swiper = ref<any>(null)

const autoplayOptions = ref({
    delay: 8000,
    disableOnInteraction: true,
})

const swipeNext = () => {
    swiper.value?.swiper?.slideNext()
}
const swipePrev = () => {
    swiper.value?.swiper?.slidePrev()
}
</script>

<style lang="scss" scoped>
.heroCont {
    padding: var(--margin-gap);

    .hero {
        position: relative;

        background-color: var(--color-background-image);
        min-height: var(--size-hero-minheight);
        max-width: var(--size-hero-max);
        border-radius: var(--size-hero-radius);

        margin: 0 auto;
        overflow: hidden;

        pointer-events: none;

        .controlsZone {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 2;

            .contentZone {
                position: relative;
                z-index: 1;
                padding: 0 calc(var(--margin-header) * 2 - 10px) calc(var(--margin-header) * 2 - 10px);
                height: 100%;

                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                .controls {
                    margin-bottom: calc(var(--margin-header) * 2 - 10px);

                    display: flex;
                    flex-direction: row;
                    gap: var(--margin-gap);

                    pointer-events: auto
                }
            }
        }

        &:deep(swiper-container) {
            align-items: center;

            swiper-slide {
                align-self: stretch;
                height: auto;
            }
        }
    }
}

@media screen and (max-width: 1360px) {
    .heroCont {
        .hero {
            .controlsZone {
                .contentZone {
                    padding: var(--size-hero-safetop) 20px 20px 20px;
                    max-width: 1200px;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .heroCont {
        padding: 0;

        .hero {
            border-radius: 0;
            min-height: 0;

            .controlsZone {
                .contentZone {
                    padding: calc(var(--margin-header) * 2);
                    padding-top: var(--size-hero-safetop);
                }
            }
        }
    }
}
</style>
export const useCard = () => {

    const { verbalizzaSottotitolo, verbalizzaSottotitoloSpettacolo, verbalizzaSottotitoloVisitaGuidata, formatoDataLungo } = useOrari()
    const { t, locale } = useI18n()
    const { dizionario } = useSitemap()
    const directusUrl = useDirectusUrl()

    const getCardCover = (card: any) => {
        if (card && card.cover) return card.cover
        else if (card && card.item && card.item.cover) return card.item.cover
        else if (card && card.item && card.item.operatore && card.item.operatore.avatar) return card.item.operatore.avatar
        else return null
    }
    const getCardTitolo = (card: any) => {
        if (card && card.titolo) return card.titolo
        else if (card && card.item && card.item.translations && card.item.translations.length > 0) return card.item.translations[0].titolo
        else if (card && card.item && card.item.operatore) {
            if (card.item.operatore.denominazione) return card.item.operatore.denominazione
            else return `${card.item.operatore.first_name} ${card.item.operatore.last_name}`
        }
        else return null
    }

    const getCardDescrizione = (card: any) => {
        if (card && card.descrizione) return card.descrizione
        else if (card && card.item && card.item.translations && card.item.translations.length > 0 && card.item.translations[0].og_description) return card.item.translations[0].og_description
        else if (card && card.item && card.item.operatore && card.item.operatore.translations && card.item.operatore.translations.length > 0 && card.item.operatore.translations[0].descrizione) {
            const descrizione = card.item.operatore.translations[0].descrizione.replace(/<[^>]*>?/gm, '');
            return descrizione.substring(0, 255)
        }
        else return null
    }
    const getCardLuogo = (card: any) => {
        if (card && card.luogo) return card.luogo
        else if (card && card.item && card.item.comune) return card.item.comune.nome
        else if (card && card.item && card.item.comune_partenza) return card.item.comune_partenza.nome
        else if (card && card.item && card.item.comune_suggerito) return card.item.comune_suggerito
        else if (card && card.item && card.item.luogo && card.item.luogo.comune) return card.item.luogo.comune.nome
        else if (card && card.item && card.item.operatore && card.item.operatore.comune) return card.item.operatore.comune.nome
        else return null
    }
    const getCardRicorrenza = (card: any) => {
        if (card && card.ricorrenza) return card.ricorrenza
        else if (card && card.item && card.item.date) return verbalizzaSottotitolo(card.item.date)
        else if (card && card.item && card.item.date_spettacoli) return verbalizzaSottotitoloSpettacolo(card.item.date_spettacoli)
        else if (card && card.item && card.item.date_visite_guidate) return verbalizzaSottotitoloVisitaGuidata(card.item.date_visite_guidate)
        else if (card && card.item && card.item.data_pubblicazione) return formatoDataLungo(new Date(card.item.data_pubblicazione))
        else return null
    }
    const getCardAderisce = (card: any) => {
        if (card && card.aderisce) return card.aderisce
        else return []
    }

    const getCardTassonomia = (card: any, typename: string | null | undefined) => {
        if (typename) {
            switch (typename) {
                case 'attivita':
                case 'risorse':
                    if (card.tipologia) return card.tipologia[0]
                    else if (card.item.tipologia) return card.item.tipologia.translations?.[0]?.etichetta
                    break;
                case 'pacchetti':
                    if (card.tipologia) return card.tipologia[0]
                    else if (card.item.tipologia) return t(card.item.tipologia)
                    break;
                case 'pagine':
                    if (card.parent) return card.parent[0]
                    else if (card.item.parent) return card.item.parent.translations?.[0]?.titolo
                    break;
                case 'eventi':
                case 'visite_guidate':
                case 'operatori_outdoor':
                case 'operatori_incoming':
                    if (card.categoria) return card.categoria[0]
                    else if (card.item.categorie && card.item.categorie.length > 0) return card.item.categorie[0].categorie_eventi_id?.translations?.[0]?.etichetta
                    break;
                case 'spettacoli':
                    if (card.categoria) return card.categoria[0]
                    else if (card.item.categoria) return card.item.categoria.translations?.[0]?.etichetta
                    break;
                case 'prodotti':
                    return t('prodotti')
                case 'link_esterni':
                case 'link_file':
                    if (card.item.translations) return card.item.translations?.[0]?.tassonomia
                    break;
                case 'link_operatore':
                    if (card.item.operatore && card.item.operatore.categoria_operatore_outdoor) return t(card.item.operatore.categoria_operatore_outdoor)
                    break;
                case 'news_convention':
                    return 'News Convention'
                    break;
            }
            return null
        } else return null
    }
    const getListaTassonomia = (card: any, typename: string | null | undefined) => {
        if (typename) {
            switch (typename) {
                case 'attivita':
                case 'risorse':
                case 'pacchetti':
                    if (card.tipologia) return card.tipologia.join(', ')
                    else if (card.item.tipologia) return card.item.tipologia.translations?.[0]?.etichetta
                    break;
                case 'pagine':
                    if (card.parent) return card.parent[0]
                    else if (card.item.parent) return card.item.parent.translations?.[0]?.titolo
                    break;
                case 'eventi':
                case 'visite_guidate':
                case 'operatori_outdoor':
                case 'operatori_incoming':
                    if (card.categoria) return card.categoria.join(', ')
                    else if (card.item.categorie && card.item.categorie.length > 0) return card.item.categorie.map((c: any) => c?.categorie_eventi_id?.translations?.[0]?.etichetta).join(', ')
                    break;
                case 'spettacoli':
                    if (card.categoria) return card.categoria.join(', ')
                    else if (card.item.categoria) return card.item.categoria.translations?.[0]?.etichetta
                    break;
                case 'prodotti':
                    return t('prodotti')
                case 'link_esterni':
                case 'link_file':
                    if (card.item.translations) return card.item.translations?.[0]?.tassonomia
                    break;
                case 'link_operatore':
                    if (card.item.operatore && card.item.tipologia_operatore == 'outdoor' && card.item.operatore.categoria_operatore_outdoor) return t(card.item.operatore.categoria_operatore_outdoor)
                    else if (card.item.operatore && card.item.tipologia_operatore == 'incoming' && card.item.operatore.categoria_operatore_incoming) return t(card.item.operatore.categoria_operatore_incoming)
                    break;
                case 'news_convention':
                    return 'News Convention'
                    break;
            }
            return null
        } else return null
    }
    const getCardTassonomiaRicerca = (hit: any) => {
        if (hit) {
            switch (hit.collection) {
                case 'eventi':
                case 'visite_guidate':
                case 'spettacoli':
                    return hit.categoria ? hit.categoria.join(', ') : null
                case 'pagine':
                    return hit.parent ? hit.parent[0] : null
                case 'news_convention':
                    return 'News Convention'
                default:
                    return hit.tipologia ? hit.tipologia.join(', ') : null
            }
        } else return null
    }

    const getCardLink = (card: any, typename: string | null | undefined, lang = locale.value) => {
        if (card && card.link) return {
            external: false,
            address: card.link,
            addressName: undefined
        }

        const l = lang as 'it' | 'en' | 'fr'

        if (card && card.item && typename) {
            switch (typename) {
                case 'pagine':
                case 'risorse':
                case 'attivita':
                case 'pacchetti':
                case 'spettacoli':
                case 'visite_guidate':
                case 'eventi':
                case 'prodotti':
                case 'news_convention':
                    return {
                        external: false,
                        address: card.item.translations?.[0]?.url,
                        addressName: undefined
                    }
                case 'operatori_outdoor':
                    return {
                        external: false,
                        address: card.item.translations?.[0]?.url_outdoor,
                        addressName: undefined
                    }
                case 'operatori_incoming':
                    return {
                        external: false,
                        address: card.item.translations?.[0]?.url_incoming,
                        addressName: undefined
                    }
                case 'link_esterni':
                    return {
                        external: true,
                        address: card.item.link,
                        addressName: undefined
                    }
                case 'link_file':
                    return {
                        external: true,
                        address: `${directusUrl}/assets/${card.item.translations?.[0]?.file?.id}`,
                        addressName: card.item.translations?.[0]?.file?.title || ''
                    }
                case 'link_operatore':
                    if (card.item.tipologia_operatore == 'outdoor') {
                        return {
                            external: false,
                            address: card.item.operatore.translations?.[0]?.url_outdoor,
                            addressName: undefined
                        }
                    } else if (card.item.tipologia_operatore == 'incoming') {
                        return {
                            external: false,
                            address: card.item.operatore.translations?.[0]?.url_incoming,
                            addressName: undefined
                        }
                    }
                default:
                    return {
                        external: false,
                        address: null,
                        addressName: undefined
                    }
            }
        } else return {
            external: false,
            address: null,
            addressName: undefined
        }
    }

    return {
        getCardCover,
        getCardTitolo,

        getCardDescrizione,
        getCardLuogo,
        getCardRicorrenza,
        getCardAderisce,

        getCardTassonomia,
        getListaTassonomia,
        getCardTassonomiaRicerca,
        getCardLink
    }
}

// @ts-nocheck


export const localeCodes =  [
  "it",
  "en",
  "fr"
]

export const localeLoaders = {
  "it": [{ key: "../i18n/locales/it.ts", load: () => import("../i18n/locales/it.ts?hash=32a88854&locale=it" /* webpackChunkName: "locale__app_i18n_locales_it_ts" */), cache: true }],
  "en": [{ key: "../i18n/locales/en.ts", load: () => import("../i18n/locales/en.ts?hash=57c343c1&locale=en" /* webpackChunkName: "locale__app_i18n_locales_en_ts" */), cache: true }],
  "fr": [{ key: "../i18n/locales/fr.ts", load: () => import("../i18n/locales/fr.ts?hash=3f67edb7&locale=fr" /* webpackChunkName: "locale__app_i18n_locales_fr_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: "i18n_config_5b32d2e0" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "it",
      "name": "IT",
      "language": "it-IT",
      "isold": "it_IT",
      "files": [
        "/app/i18n/locales/it.ts"
      ],
      "isCatchallLocale": true
    },
    {
      "code": "en",
      "name": "EN",
      "language": "en-GB",
      "isold": "en_GB",
      "files": [
        "/app/i18n/locales/en.ts"
      ]
    },
    {
      "code": "fr",
      "name": "FR",
      "language": "fr-FR",
      "isold": "fr_FR",
      "files": [
        "/app/i18n/locales/fr.ts"
      ]
    }
  ],
  "defaultLocale": "it",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://turismotorino.org",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "it",
    "name": "IT",
    "language": "it-IT",
    "isold": "it_IT",
    "files": [
      {
        "path": "/app/i18n/locales/it.ts",
        "cache": true
      }
    ],
    "isCatchallLocale": true
  },
  {
    "code": "en",
    "name": "EN",
    "language": "en-GB",
    "isold": "en_GB",
    "files": [
      {
        "path": "/app/i18n/locales/en.ts",
        "cache": true
      }
    ]
  },
  {
    "code": "fr",
    "name": "FR",
    "language": "fr-FR",
    "isold": "fr_FR",
    "files": [
      {
        "path": "/app/i18n/locales/fr.ts",
        "cache": true
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"

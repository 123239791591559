<template>
    <div class="link">
        <template v-for="evento in eventiFuturi">
            <div class="evento">
                <NuxtLinkLocale v-if="evento.translations && evento.translations.length > 0" :to="`/visita/eventi/${evento.translations[0].slug}`">{{ evento.translations[0].titolo }}</NuxtLinkLocale>
            </div>
        </template>
        <div class="paragraph" v-if="eventiFuturi.length == 0">Non ci sono eventi in programma</div>
    </div>
</template>

<script lang="ts" setup>
const { seEventoPassato, sortEventiByOrario } = useOrari()
const props = defineProps<{ eventi: any[] }>()

const eventiFuturi = computed(() => {
    if (props.eventi && props.eventi.length > 0) {
        return sortEventiByOrario(props.eventi, 'eventi_id', 'asc')
    } else return []
})
/*const eventiPassati = computed(() => {
    if (props.eventi && props.eventi.length > 0) {
        const eventi = props.eventi.filter((e: any) => seEventoPassato(e.eventi_id.date))
        return sortEventiByOrario(eventi, 'eventi_id', 'desc')
    } else return []
})*/
</script>

<style lang="scss" scoped>
.link {
    display: flex;
    flex-direction: column;

    .evento {
        font-size: var(--font-size-body);
        line-height: var(--font-line-body);
        color: var(--color-azure-dark);
        font-weight: bold;

        a {
            text-decoration: none;
        }
    }
}
</style>
import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "acquista-prodotto": () => import("/app/middleware/acquistaProdotto.ts"),
  carrello: () => import("/app/middleware/carrello.ts"),
  "find-page": () => import("/app/middleware/findPage.ts"),
  "private-area": () => import("/app/middleware/privateArea.ts"),
  "private-area-armonia-prenota": () => import("/app/middleware/privateAreaArmoniaPrenota.ts"),
  "private-area-operatori-incoming": () => import("/app/middleware/privateAreaOperatoriIncoming.ts"),
  "private-area-operatori-outdoor": () => import("/app/middleware/privateAreaOperatoriOutdoor.ts"),
  "registra-invito": () => import("/app/middleware/registraInvito.ts"),
  "reset-password": () => import("/app/middleware/resetPassword.ts"),
  "search-link-redirect": () => import("/app/middleware/searchLinkRedirect.ts"),
  "search-node-redirect": () => import("/app/middleware/searchNodeRedirect.ts")
}
<template>
    <article v-if="operatore">

        <section class="operatoreInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBodyOperatore v-if="operatore.translations && operatore.translations.length > 0" :body="operatore.translations[0]?.descrizione" :aree="operatore.aree" :lingue="operatore.lingue_attivita" />

                        <!-- IMMAGINI -->
                        <ModuloImmagine v-if="operatore.avatar" :image="{ directus_files_id: operatore.avatar }" />

                        <!-- CONTATTI -->
                        <SezioneShare />
                        <LazySkipHydration>
                            <LazySezioneContattiOperatore :operatore="operatore" />
                        </LazySkipHydration>

                        <!-- ATTIVITÀ PROPOSTE -->
                        <ListaAttivitaOutdoor v-if="(operatore.attivita && operatore.attivita.length > 0)" :attivita="operatore.attivita" />

                    </div>
                </div>
            </div>
        </section>
    </article>
</template>

<script lang="ts" setup>
const { localeProperties, t } = useI18n()
const { dizionario, createOperatoreOutdoorRoutes } = useSitemap()
const setI18nParams = useSetI18nParams()
const route = useRoute()
const directusUrl = useDirectusUrl()
const props = defineProps<{ operatore: any }>()


const nomeOperatore = computed(() => {
    if (props.operatore) {
        if (props.operatore.denominazione) return props.operatore.denominazione
        else return `${props.operatore.first_name} ${props.operatore.last_name}`
    } else return ''
})

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }]
});
useSeoMeta({
    title: () => nomeOperatore.value ? `${nomeOperatore.value} | ${t(props.operatore.categoria_operatore_outdoor)} | Turismo Torino e Provincia` : null,
    ogTitle: () => nomeOperatore.value ? `${nomeOperatore.value} | ${t(props.operatore.categoria_operatore_outdoor)} | Turismo Torino e Provincia` : null,
    twitterTitle: () => nomeOperatore.value ? `${nomeOperatore.value} | ${t(props.operatore.categoria_operatore_outdoor)} | Turismo Torino e Provincia` : null,

    ogImage: () => props.operatore && props.operatore.avatar && props.operatore.avatar.height && props.operatore.avatar.width ? `${directusUrl}assets/${props.operatore.avatar.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.operatore && props.operatore.avatar && props.operatore.avatar.height && props.operatore.avatar.width ? `${directusUrl}assets/${props.operatore.avatar.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

const operatoreRoutes = createOperatoreOutdoorRoutes(props.operatore)
if (operatoreRoutes) setI18nParams(operatoreRoutes)

const itemListElement = computed(() => {
    const elements = <any[]>[]
    const parents = <{ slug: string, titolo: string }[]>[]
    const urls = <string[]>[]

    let hasMoreParents = true
    let currentParent = props.operatore.pagina_operatore_outdoor

    if (currentParent) {
        while (hasMoreParents) {
            if (currentParent.translations && currentParent.translations.length > 0) {
                const localeParentTranslation = currentParent.translations.find((t: any) => t.languages_code.code == localeProperties.value.code)
                if (localeParentTranslation && localeParentTranslation.slug) {
                    parents.push(localeParentTranslation)
                }
            }

            if (currentParent.parent) currentParent = currentParent.parent
            else hasMoreParents = false
        }
    }

    for (const p of parents.reverse()) {
        urls.push(p.slug)
        elements.push({
            '@type': 'ListItem',
            position: urls.length,
            name: p.titolo,
            item: `https://turismotorino.org/${urls.join('/')}`
        })
    }

    elements.push({
        '@type': 'ListItem',
        position: urls.length + 1,
        name: dizionario.titoloOperatoriOutdoor[localeProperties.value.code as 'it' | 'en' | 'fr'],
        item: `https://turismotorino.org/${urls.join('/')}/${dizionario.linkOperatoriOutdoor[localeProperties.value.code as 'it' | 'en' | 'fr']}`
    })
    elements.push({
        '@type': 'ListItem',
        position: urls.length + 2,
        name: props.operatore && props.operatore.denominazione ? `${props.operatore.denominazione}` : `${props.operatore.first_name} ${props.operatore.last_name}`,
        item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
    })

    return elements
})

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            'itemListElement': itemListElement.value
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.operatore && props.operatore.denominazione ? `${props.operatore.denominazione} | ${t('operatori_outdoor')} | Turismo Torino e Provincia` : `${props.operatore.first_name} ${props.operatore.last_name} | ${t('operatori_outdoor')} | Turismo Torino e Provincia`,
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.operatore && props.operatore.avatar && props.operatore.avatar.height && props.operatore.avatar.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.operatore.avatar.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.operatore.avatar.height * 1600 / props.operatore.avatar.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.operatore.avatar.title
            } : undefined,
            datePublished: props.operatore && props.operatore.date_created ? new Date(props.operatore.date_created).toJSON() : undefined,
            dateModified: props.operatore && props.operatore.date_updated ? new Date(props.operatore.date_updated).toJSON() : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                    ]
                }
            ]
        },
        !props.operatore.denominazione ? {
            '@type': 'Person',
            '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#person`,
            name: `${props.operatore.first_name} ${props.operatore.last_name}`,
            email: props.operatore.email || undefined,
            telephone: props.operatore.cellulare || undefined,
            image: props.operatore.avatar ? `${directusUrl}assets/${props.operatore.avatar.id}?width=1600&format=jpeg` : undefined,
            url: props.operatore.sito_web || undefined,
        } : {
            '@type': 'LocalBusiness',
            name: props.operatore.denominazione,
            image: props.operatore.avatar ? `${directusUrl}assets/${props.operatore.avatar.id}?width=1600&format=jpeg` : undefined,
            url: props.operatore.sito_web || undefined,
            email: props.operatore.email || undefined,
            address: props.operatore.indirizzo ? {
                '@type': 'PostalAddress',
                addressCountry: "IT",
                addressLocality: props.operatore.comune ? props.operatore.comune.nome : undefined,
                //addressRegion: "",
                postalCode: props.operatore.cap,
                streetAddress: props.operatore.indirizzo
            } : undefined,
            telephone: props.operatore.cellulare || undefined
        }
    ]
}))
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .operatoreInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .operatoreInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>
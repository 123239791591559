<template>
    <section class="moduloImmagine" v-if="image && image.directus_files_id">
        <div class="cont">
            <div :class="[{ innerCont: size == 'standard' }, { thirdCont: size == 'small' }]">
                <div :class="['imageCont', { armonia }]" :style="{ backgroundImage: backgroundStyle }">
                    <Image :image="image.directus_files_id" :width="750" :height="500" :mobileWidth="800" :mobileHeight="600" imageClass="cover" />
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { blurhashToCssGradientString } from "@unpic/placeholder";

interface Props {
    image: {
        directus_files_id: any;
    } | null;
    size?: 'small' | 'standard';
    armonia?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    size: 'standard',
    armonia: false
})

const backgroundStyle = computed(() => {
    if (props.image?.directus_files_id && props.image.directus_files_id.blurhash) return blurhashToCssGradientString(props.image.directus_files_id.blurhash)
    else return 'transparent'
})
</script>

<style lang="scss" scoped>
.moduloImmagine {

    .imageCont {
        position: relative;
        width: 100%;

        background-color: var(--color-background-image);
        border-radius: var(--size-card-radius);
        overflow: hidden;
    }

    .innerCont .imageCont {
        height: 500px;

        &.armonia {
            height: 620px;
        }
    }

    .thirdCont .imageCont {
        height: 300px;
    }
}

@media screen and (max-width: 600px) {
    .moduloImmagine {
        .innerCont .imageCont {
            height: 300px;

            &.armonia {
                height: 300px;
            }
        }

        .thirdCont .imageCont {
            height: 300px;
        }
    }
}
</style>
<template>
    <div>
        <ModuloMappa>
            <template #featureGroup>
                <LMarker v-for="gruppo in geoGroups" :lat-lng="gruppo[1][0].latlng">
                    <LIcon icon-url="/map/marker.png" shadow-url="/map/shadow.png" :icon-size="[50, 50]" :icon-anchor="[25, 25]" :shadow-size="[50, 50]" :shadow-anchor="[25, 25]"></LIcon>
                    <LPopup>
                        <div v-for="punto in gruppo[1]">
                            <NuxtLinkLocale v-if="punto.link" :to="punto.link"> {{ punto.evento.translations[0]?.titolo }}</NuxtLinkLocale>
                            <span v-else> {{ punto.evento.translations[0]?.titolo }}</span>
                        </div>
                    </LPopup>
                </LMarker>
            </template>
        </ModuloMappa>
    </div>
</template>

<script lang="ts" setup>
import { encodeBase32 } from 'geohashing';

const props = defineProps<{ sottoeventi: any[], spettacoli: any[], visiteGuidate: any[] }>()

const geoGroups = computed(() => {
    if ((props.sottoeventi && props.sottoeventi.length > 0) || (props.spettacoli && props.spettacoli.length > 0) || (props.visiteGuidate && props.visiteGuidate.length > 0)) {

        const puntiHash = []

        for (const evento of props.sottoeventi) {
            if (evento.related_eventi_id.geolocalizzazione) {
                puntiHash.push({
                    hash: encodeBase32(evento.related_eventi_id.geolocalizzazione.coordinates[1], evento.related_eventi_id.geolocalizzazione.coordinates[0], 7),
                    latlng: [evento.related_eventi_id.geolocalizzazione.coordinates[1], evento.related_eventi_id.geolocalizzazione.coordinates[0]],
                    evento: evento.related_eventi_id,
                    link: evento.related_eventi_id.translations && evento.related_eventi_id.translations.length > 0 ? `/visita/eventi/${evento.related_eventi_id.translations[0].slug}` : null
                })
            }
        }
        for (const spettacolo of props.spettacoli) {
            if (spettacolo.spettacoli_id.luogo && spettacolo.spettacoli_id.luogo.geolocalizzazione) {
                puntiHash.push({
                    hash: encodeBase32(spettacolo.spettacoli_id.luogo.geolocalizzazione.coordinates[1], spettacolo.spettacoli_id.luogo.geolocalizzazione.coordinates[0], 7),
                    latlng: [spettacolo.spettacoli_id.luogo.geolocalizzazione.coordinates[1], spettacolo.spettacoli_id.luogo.geolocalizzazione.coordinates[0]],
                    evento: spettacolo.spettacoli_id,
                    link: spettacolo.spettacoli_id.translations && spettacolo.spettacoli_id.translations.length > 0 ? `/visita/eventi/musica/${spettacolo.spettacoli_id.translations[0].slug}` : null
                })
            }
        }
        for (const visita of props.visiteGuidate) {
            if (visita.visite_guidate_id.geolocalizzazione) {
                puntiHash.push({
                    hash: encodeBase32(visita.visite_guidate_id.geolocalizzazione.coordinates[1], visita.visite_guidate_id.geolocalizzazione.coordinates[0], 7),
                    latlng: [visita.visite_guidate_id.geolocalizzazione.coordinates[1], visita.visite_guidate_id.geolocalizzazione.coordinates[0]],
                    evento: visita.visite_guidate_id,
                    link: visita.visite_guidate_id.translations && visita.visite_guidate_id.translations.length > 0 ? `/visita/eventi/visite-guidate/${visita.visite_guidate_id.translations[0].slug}` : null
                })
            }
        }

        const gruppi = puntiHash.reduce(
            (result: any, punto: any) => ({
                ...result,
                [punto.hash]: [
                    ...(result[punto.hash] || []),
                    punto,
                ],
            }),
            {},
        )

        return Object.keys(gruppi).map((key) => [key, gruppi[key]])

    } else return []
})
</script>

<style lang="scss">
.leaflet-popup {
    margin-bottom: 28px;

    .leaflet-popup-content-wrapper {
        border-radius: var(--size-card-radius-inner);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.13);

        .leaflet-popup-content {
            font-family: 'Switzer', Arial, Helvetica, sans-serif;
            color: var(--color-text-default);

            margin: 0;
            padding: var(--margin-default);
            text-align: center;

            font-size: var(--font-size-subtitle);
            line-height: var(--font-line-subtitle);
            font-weight: 800;

            a {
                color: var(--color-azure-dark);
                text-decoration: none;
            }
        }
    }

    .leaflet-popup-close-button {
        display: none;
    }
}
</style>
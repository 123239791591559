<template>
    <div class="listaPress">
        <div class="innerCont">
            <div :class="['estendi', 'noselect', { open: passatiOpen }]" @click="togglePassati">
                <div class="titlebody">{{ group.year }}</div>
                <NIconWrapper :size="22" :border-radius="11">
                    <NIcon size="1.176rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound" :aria-label="$t('apri')"></NIcon>
                </NIconWrapper>
            </div>
        </div>
        <NCollapseTransition :show="passatiOpen">
            <div class="lista" v-if="tipologia == 'lista'">
                <OggettoDocumentoLista v-for="card in group.cards.sort((a: any, b: any) => b.timestamp - a.timestamp)" :key="card.id" :card="card.item" :typename="card.item.__typename || card.collection"></OggettoDocumentoLista>
            </div>
            <div class="grid" v-else-if="tipologia == 'grid'">
                <div class="gridItem" v-for="card in group.cards.sort((a: any, b: any) => b.timestamp - a.timestamp)" :key="card.id">
                    <OggettoDocumentoCard :card="card" :typename="card.item.__typename || card.collection" />
                </div>
            </div>
        </NCollapseTransition>
    </div>
</template>

<script lang="ts" setup>
import { NIconWrapper, NIcon, NCollapseTransition } from 'naive-ui'
import KeyboardArrowRightRound from '@vicons/material/KeyboardArrowRightRound'

const props = defineProps<{ group: any; tipologia: string }>()

const passatiOpen = ref(false)

const togglePassati = () => {
    passatiOpen.value = !passatiOpen.value
}
</script>

<style lang="scss" scoped>
.listaPress {
    .estendi {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:deep(.n-icon-wrapper) {
            svg {
                transition: transform 300ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
            }
        }

        &.open {
            &:deep(.n-icon-wrapper) {
                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .lista {
        padding-top: var(--margin-default);
        display: flex;
        flex-direction: column;
        gap: var(--margin-gap);
    }

    .grid {
        padding-top: var(--margin-default);
        display: grid;
        gap: var(--margin-gap);
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-auto-rows: 400px;

        .gridItem {
            position: relative;
        }
    }
}
</style>
<template>
    <div class="oggettoSottoevento" v-if="evento">
        <CardMini :cover="evento.cover" :titolo="evento.translations && evento.translations.length > 0 ? evento.translations[0].titolo : null" :link="link">
            <template #end>
                <div class="end">
                    <div class="small" v-if="evento.comune">
                        {{ evento.comune.nome }}<template v-if="evento.ospitato_in && evento.ospitato_in.length == 1 && evento.ospitato_in[0].risorse_id.translations"> - {{ evento.ospitato_in[0].risorse_id.translations[0].titolo }}</template>
                    </div>
                    <div class="small" v-if="evento.luogo && evento.luogo.comune">
                        {{ evento.luogo.comune.nome }} - {{ evento.luogo.nome }}
                    </div>
                    <div class="small" v-if="dataSottotitolo">{{ dataSottotitolo }}</div>
                </div>
            </template>
        </CardMini>
    </div>
</template>

<script lang="ts" setup>
const { verbalizzaSottotitolo, verbalizzaSottotitoloSpettacolo, verbalizzaSottotitoloVisitaGuidata } = useOrari()
const props = defineProps<{ evento: any }>()

const link = computed(() => {
    if (props.evento && props.evento.luogo && props.evento.translations && props.evento.translations.length > 0) {
        return `/visita/eventi/musica/${props.evento.translations[0].slug}`
    } else if (props.evento && props.evento.date_visite_guidate && props.evento.translations.length > 0) {
        return `/visita/eventi/visite-guidate/${props.evento.translations[0].slug}`
    } else if (props.evento && props.evento.translations && props.evento.translations.length > 0) {
        return `/visita/eventi/${props.evento.translations[0].slug}`
    } else return null
})
const dataSottotitolo = computed(() => {
    if (props.evento && props.evento.date) {
        return verbalizzaSottotitolo(props.evento.date)
    } else if (props.evento && props.evento.date_spettacoli) {
        return verbalizzaSottotitoloSpettacolo(props.evento.date_spettacoli)
    } else if (props.evento && props.evento.date_visite_guidate) {
        return verbalizzaSottotitoloVisitaGuidata(props.evento.date_visite_guidate)
    } else return null
})
</script>

<style lang="scss" scoped>
.oggettoSottoevento {
    .end {
        text-transform: uppercase;
    }
}
</style>
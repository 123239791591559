<template>
    <div class="oggettoDocumentoLista">
        <div class="avatarCont">
            <div class="filler"></div>
            <div class="avatar" v-if="card.cover">
                <Image :image="card.cover" :width="600" imageClass="cover" :key="card.cover.id" />
            </div>
        </div>
        <div class="info">
            <div class="paragraph">{{ $dataSenzaOrario(card.data) }}</div>
            <div class="title4">{{ card.translations[0]?.titolo }}</div>
        </div>
        <div class="iconCont">
            <NIcon :size="40" color="var(--color-text-default)" :component="InsertDriveFileRound" :aria-label="$t('documento')"></NIcon>
        </div>
        <a v-if="card.translations[0]?.file" class="overlink" :href="`${directusUrl}/assets/${card.translations[0]?.file.id}`" target="_blank" :download="card.translations[0]?.file.title"></a>
        <a v-else-if="card.link" class="overlink" :href="card.link" target="_blank"></a>
    </div>
</template>

<script lang="ts" setup>
import { NIcon } from 'naive-ui';
import InsertDriveFileRound from '@vicons/material/InsertDriveFileRound'

defineProps<{ card: any }>()
const directusUrl = useDirectusUrl()
</script>

<style lang="scss" scoped>
.oggettoDocumentoLista {

    position: relative;
    min-height: 170px;
    background-color: var(--color-background-box);
    border-radius: var(--size-card-radius);
    overflow: hidden;

    display: grid;
    grid-template-columns: 200px 1fr min-content;

    .avatarCont {
        position: relative;
        padding: var(--margin-default) 0 var(--margin-default) var(--margin-default);

        .avatar {
            position: absolute;
            top: 0;
            left: 0;
            background-color: var(--color-background-image);
            height: 100%;
            width: 100%;
        }

        .filler {
            width: 100%;
            padding-top: 50%;
        }
    }

    .info {
        padding: var(--margin-default) var(--margin-default) var(--margin-default) var(--margin-double);
        display: grid;
        grid-template-columns: 160px 1fr;
        gap: var(--margin-default);
    }

    .iconCont {
        padding: var(--margin-default);
    }
}

@media screen and (max-width: 1060px) {
    .oggettoDocumentoLista {
        .info {
            display: flex;
            flex-direction: column;
            gap: 0;
        }
    }
}

@media screen and (max-width: 600px) {
    .oggettoDocumentoLista {
        display: flex;
        flex-direction: column;

        .avatarCont {
            width: 200px;

            .avatar {
                position: absolute;
                top: var(--margin-default);
                bottom: var(--margin-default);
                left: var(--margin-default);
                right: 0;
                border-radius: var(--size-card-radius-inner);
                overflow: hidden;

                width: auto;
                height: auto;
            }

            .filler {
                padding-top: 75%;
            }
        }
        .info {
            padding: 0 var(--margin-default) var(--margin-default) var(--margin-default);
        }
        .iconCont {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}
</style>
<template>
    <section class="moduloCarousel">
        <div class="cont">
            <OggettoHeadModulo :modulo="modulo" />
            <div class="controls" v-if="shouldShowSwiper">
                <NButton size="medium" secondary circle :disabled="disablePrev" @click="swipePrev" tabindex="-1" :aria-label="$t('indietro')">
                    <template #icon>
                        <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowLeftRound"></NIcon>
                    </template>
                </NButton>
                <NButton size="medium" secondary circle :disabled="disableNext" @click="swipeNext" tabindex="-1" :aria-label="$t('avanti')">
                    <template #icon>
                        <NIcon size="1.412rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound"></NIcon>
                    </template>
                </NButton>
            </div>
        </div>
        <div :class="['cardsZone', size]" v-if="cards && cards.length > 0">
            <div :class="['swiperZone', { offset: !offsetMounted }]" v-if="shouldShowSwiper">
                <swiper-container slides-per-view="auto" :slides-per-group="1" :grab-cursor="true" :slides-offset-before="swiperOffset" :slides-offset-after="swiperOffset" ref="swiper">
                    <template v-for="card in cards">
                        <swiper-slide class="cardCont">
                            <OggettoGenericoCard :card="card" :typename="card.item?.__typename || card.collection" :width="260" :height="390" :mobileHeight="780" />
                        </swiper-slide>
                    </template>
                </swiper-container>
            </div>
            <div :class="['inlineCards']" v-else>
                <div class="offset"></div>
                <template v-for="card in cards">
                    <div class="cardCont">
                        <OggettoGenericoCard :card="card" :typename="card.item?.__typename || card.collection" :width="260" :height="390" :mobileHeight="780" />
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { NButton, NIcon } from 'naive-ui'
import KeyboardArrowLeftRound from '@vicons/material/KeyboardArrowLeftRound'
import KeyboardArrowRightRound from '@vicons/material/KeyboardArrowRightRound'

interface Props {
    modulo: any;
    size?: 'default' | 'small';
}
const props = withDefaults(defineProps<Props>(), {
    size: 'default'
})
const { searchMeilimoduleCardsGrid } = useSearch()

const offsetMounted = ref(false)
const disablePrev = ref(true)
const disableNext = ref(false)

const { width: windowWidth } = useWindowSize({ includeScrollbar: false })
const swiper = ref()

const { data: generatedCards } = props.modulo.contenuto == 'automatico' && props.modulo.translations_formula[0] ? useAsyncData(JSON.stringify(props.modulo.translations_formula[0].formula), () => searchMeilimoduleCardsGrid(props.modulo.translations_formula[0].formula)) : { data: null }

const cards = computed(() => {
    if (props.modulo.contenuto == 'automatico' && generatedCards && generatedCards.value) return generatedCards.value
    else if (props.modulo.contenuto == 'manuale') return props.modulo.card
    else return []
})

onMounted(() => {
    offsetMounted.value = true
})

const swiperOffset = computed(() => {
    if (windowWidth.value && isFinite(windowWidth.value) && offsetMounted.value === true) {
        if (windowWidth.value - 1240 > 0) return (windowWidth.value - 1200) / 2
        else return 20
    } else return 0
})
const shouldShowSwiper = computed(() => {
    if (cards.value && cards.value.length > 0 && windowWidth.value && isFinite(windowWidth.value) && offsetMounted.value === true) {
        if (cards.value.length * 270 > windowWidth.value - swiperOffset.value) return true
        else return false
    } else return false
})

const swipeNext = () => {
    if (swiper.value) {
        swiper.value.swiper.slideNext()
        disablePrev.value = swiper.value.swiper.isBeginning
        disableNext.value = swiper.value.swiper.isEnd
    }
}
const swipePrev = () => {
    if (swiper.value) {
        swiper.value.swiper.slidePrev()
        disablePrev.value = swiper.value.swiper.isBeginning
        disableNext.value = swiper.value.swiper.isEnd
    }
}
</script>


<style lang="scss" scoped>
.moduloCarousel {
    .cont {
        display: flex;
        flex-direction: row;
        gap: var(--margin-default);
        justify-content: space-between;

        padding: 0 var(--margin-page);
        min-height: 40px;
    }

    .controls {
        display: flex;
        flex-direction: row;
        gap: var(--margin-gap);
    }

    .cardsZone {
        margin-top: var(--article-gap-inner);

        .swiperZone {
            position: absolute;
            left: 0;
            right: 0;
            overflow: hidden;

            &.offset {
                margin-left: calc((100% - 1200px) / 2);
            }
        }

        .inlineCards {
            position: absolute;
            left: 0;
            right: 0;
            overflow: hidden;
            white-space: nowrap;

            .offset {
                display: inline-block;
                width: calc((100% - 1200px) / 2);
            }

            .cardCont {
                display: inline-block;
                white-space: normal;
            }
        }

        .cardCont {
            position: relative;
            width: 260px;
            margin-right: var(--margin-gap);
        }

        &.default {

            height: 390px;

            .swiperZone,
            .cardCont {
                height: 390px;
            }
        }

        &.small {
            height: 300px;

            .swiperZone,
            .cardCont {
                height: 300px;
            }
        }
    }
}


@media screen and (max-width: 1220px) {
    .moduloCarousel {

        .cardsZone {
            .swiperZone.offset {
                margin-left: 20px;
            }

            .inlineCards {
                .offset {
                    width: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .moduloCarousel {
        .cont {
            min-height: 0px;
        }

        .controls {
            display: none;
        }
    }
}

@media screen and (max-width: 600px) {
    .moduloCarousel {

        .cardsZone {
            .swiperZone.offset {
                margin-left: 20px;
            }

            .inlineCards {
                .offset {
                    width: 20px;
                }
            }
        }
    }
}
</style>
<template>
    <div class="orario">
        <div class="titlebody">{{ formatoDataLungo(new Date(data_spettacolo.data)) }}</div>
        <div class="turno paragraph">{{ formatoOra(new Date(data_spettacolo.data)) }}</div>
    </div>
</template>

<script lang="ts" setup>
const { formatoDataLungo, formatoOra } = useOrari()
defineProps<{ data_spettacolo: any }>()
</script>


<style lang="scss" scoped>
.orario {

    .titlebody {
        display: flex;
        flex-direction: column;
    }

    .turno {
        border-top: 1px solid var(--color-azure-back);
        padding-top: 6px;
        margin-top: 6px;

        display: flex;
        flex-direction: row;
        gap: var(--margin-gap);
    }
}
</style>
<template>
    <div :class="['orario', { noborder }]">

        <!-- RICORRENZA: MAI -->
        <template v-if="orario.ricorrenza == 'mai'">

            <div class="titlebody">
                <span class="upp" v-if="orario.translations && orario.translations[0] && orario.translations[0].etichetta">{{ orario.translations[0].etichetta }}</span>

                <!-- <span class="upp">SEMPRE APERTO</span> -->
                <span v-if="date.data_inizio && date.data_fine && date.data_inizio.getTime() == date.data_fine.getTime()">{{ formatoDataLungo(date.data_inizio) }}</span>
                <span v-else-if="date.data_inizio && date.data_fine">{{ $t('data_mdal') }} {{ formatoDataCompatto(date.data_inizio) }} {{ $t('data_al') }} {{ formatoDataCompatto(date.data_fine) }}</span>
                <span v-else-if="date.data_inizio && !date.data_fine">{{ $t('data_mdal') }} {{ formatoDataCompatto(date.data_inizio) }}</span>
                <span v-else-if="!date.data_inizio && date.data_fine">{{ $t('data_mfinoal') }} {{ formatoDataCompatto(date.data_fine) }}</span>

                <span class="sec" v-if="eccetto">{{ $t('eccetto') }} {{ eccetto }}</span>
            </div>

            <div class="turno paragraph">
                <div v-if="orario.aperto == 'chiuso'">{{ $t('chiuso') }}</div>

                <template v-else-if="(orario.aperto == 'aperto' || orario.aperto == 'aperto_blank') && orario.tutto_il_giorno === false && orario.fasce_orarie && orario.fasce_orarie.length > 0">
                    <div v-for="turno of orario.fasce_orarie">{{ formatoOraSenzaData(turno.ora_inizio) }}<template v-if="turno.ora_fine"> &ndash; {{ formatoOraSenzaData(turno.ora_fine) }}</template></div>
                </template>
                <div v-else-if="orario.aperto == 'aperto' && (orario.tutto_il_giorno === true || !orario.fasce_orarie || orario.fasce_orarie.length == 0)">{{ $t('aperto') }}</div>

                <div v-else-if="orario.aperto == 'prenotazione'">{{ $t('aperto_prenotazione') }}</div>
                <div v-else-if="orario.aperto == 'eventi'">{{ $t('aperto_eventi') }}</div>
                <div v-else-if="orario.aperto == 'visite_guidate'">{{ $t('aperto_visite_guidate') }}</div>
                <template v-if="(orario.aperto == 'prenotazione' || orario.aperto == 'eventi' || orario.aperto == 'visite_guidate') && orario.tutto_il_giorno === false && orario.fasce_orarie && orario.fasce_orarie.length > 0">
                    <div v-for="turno of orario.fasce_orarie">{{ formatoOraSenzaData(turno.ora_inizio) }}<template v-if="turno.ora_fine"> &ndash; {{ formatoOraSenzaData(turno.ora_fine) }}</template></div>
                </template>
            </div>


        </template>
        <!-- RICORRENZA: GIORNALIERA -->
        <template v-else-if="orario.ricorrenza == 'giornaliera'">
            <div class="titlebody">
                <span class="upp" v-if="orario.translations && orario.translations[0] && orario.translations[0].etichetta">{{ orario.translations[0].etichetta }}</span>
                <div class="upp">{{ $t('apertura_giornaliera') }}</div>

                <span v-if="date.inizio_ricorrenza && date.fine_ricorrenza">{{ $t('data_mdal') }} {{ formatoDataCompatto(date.inizio_ricorrenza) }} {{ $t('data_al') }} {{ formatoDataCompatto(date.fine_ricorrenza) }}</span>
                <span v-else-if="date.inizio_ricorrenza && !date.fine_ricorrenza">{{ $t('data_mdal') }} {{ formatoDataCompatto(date.inizio_ricorrenza) }}</span>
                <span v-else-if="!date.inizio_ricorrenza && date.fine_ricorrenza">{{ $t('data_finoal') }} {{ formatoDataCompatto(date.fine_ricorrenza) }}</span>

                <span class="sec" v-if="eccetto">{{ $t('eccetto') }} {{ eccetto }}</span>
            </div>

            <div class="turno paragraph">
                <div v-if="orario.aperto == 'chiuso'">{{ $t('chiuso') }}</div>

                <template v-else-if="(orario.aperto == 'aperto' || orario.aperto == 'aperto_blank') && orario.tutto_il_giorno === false && orario.fasce_orarie && orario.fasce_orarie.length > 0">
                    <div v-for="turno of orario.fasce_orarie">{{ formatoOraSenzaData(turno.ora_inizio) }}<template v-if="turno.ora_fine"> &ndash; {{ formatoOraSenzaData(turno.ora_fine) }}</template></div>
                </template>
                <div v-else-if="orario.aperto == 'aperto' && (orario.tutto_il_giorno === true || !orario.fasce_orarie || orario.fasce_orarie.length == 0)">{{ $t('aperto') }}</div>

                <div v-else-if="orario.aperto == 'prenotazione'">{{ $t('aperto_prenotazione') }}</div>
                <div v-else-if="orario.aperto == 'eventi'">{{ $t('aperto_eventi') }}</div>
                <div v-else-if="orario.aperto == 'visite_guidate'">{{ $t('aperto_visite_guidate') }}</div>
                <template v-if="(orario.aperto == 'prenotazione' || orario.aperto == 'eventi' || orario.aperto == 'visite_guidate') && orario.tutto_il_giorno === false && orario.fasce_orarie && orario.fasce_orarie.length > 0">
                    <div v-for="turno of orario.fasce_orarie">{{ formatoOraSenzaData(turno.ora_inizio) }}<template v-if="turno.ora_fine"> &ndash; {{ formatoOraSenzaData(turno.ora_fine) }}</template></div>
                </template>
            </div>
        </template>
        <!-- RICORRENZA: SETTIMANALE -->
        <template v-else-if="orario.ricorrenza == 'settimanale'">

            <div class="titlebody">
                <span class="upp" v-if="orario.translations && orario.translations[0] && orario.translations[0].etichetta">{{ orario.translations[0].etichetta }}</span>

                <div class="upp" v-if="settimanaleCompatto">{{ settimanaleCompatto.titolo }}</div>
                <span class="upp" v-else-if="!settimanaleCompatto && !(orario.translations && orario.translations[0] && orario.translations[0].etichetta)">{{ $t('apertura_settimanale') }}</span>

                <span v-if="date.inizio_ricorrenza && date.fine_ricorrenza">{{ $t('data_mdal') }} {{ formatoDataCompatto(date.inizio_ricorrenza) }} {{ $t('data_al') }} {{ formatoDataCompatto(date.fine_ricorrenza) }}</span>
                <span v-else-if="date.inizio_ricorrenza && !date.fine_ricorrenza">{{ $t('data_mdal') }} {{ formatoDataCompatto(date.inizio_ricorrenza) }}</span>
                <span v-else-if="!date.inizio_ricorrenza && date.fine_ricorrenza">{{ $t('data_finoal') }} {{ formatoDataCompatto(date.fine_ricorrenza) }}</span>

                <span class="sec" v-if="eccetto">{{ $t('eccetto') }} {{ eccetto }}</span>
            </div>

            <div class="turno paragraph" v-if="settimanaleCompatto">
                <div v-if="settimanaleCompatto.aperto == 'chiuso'">{{ $t('chiuso') }}</div>

                <template v-else-if="(settimanaleCompatto.aperto == 'aperto' || settimanaleCompatto.aperto == 'aperto_blank') && settimanaleCompatto.fasce_orarie && settimanaleCompatto.fasce_orarie.length > 0">
                    <div v-for="turno of settimanaleCompatto.fasce_orarie">{{ formatoOraSenzaData(turno.ora_inizio) }}<template v-if="turno.ora_fine"> &ndash; {{ formatoOraSenzaData(turno.ora_fine) }}</template></div>
                </template>
                <div v-else-if="settimanaleCompatto.aperto == 'aperto' && (!settimanaleCompatto.fasce_orarie || settimanaleCompatto.fasce_orarie.length == 0)">{{ $t('aperto') }}</div>

                <div v-else-if="settimanaleCompatto.aperto == 'prenotazione'">{{ $t('aperto_prenotazione') }}</div>
                <div v-else-if="settimanaleCompatto.aperto == 'eventi'">{{ $t('aperto_eventi') }}</div>
                <div v-else-if="settimanaleCompatto.aperto == 'visite_guidate'">{{ $t('aperto_visite_guidate') }}</div>
                <template v-if="(settimanaleCompatto.aperto == 'prenotazione' || settimanaleCompatto.aperto == 'eventi' || settimanaleCompatto.aperto == 'visite_guidate') && settimanaleCompatto.fasce_orarie && settimanaleCompatto.fasce_orarie.length > 0">
                    <div v-for="turno of settimanaleCompatto.fasce_orarie">{{ formatoOraSenzaData(turno.ora_inizio) }}<template v-if="turno.ora_fine"> &ndash; {{ formatoOraSenzaData(turno.ora_fine) }}</template></div>
                </template>
            </div>
            <div class="settimana paragraph" v-else>
                <template v-for="giorno, indexGiorno in nomiGiorni">
                    <div class="giorno" v-if="orario[`${giorno}_aperto`]">
                        <div class="nome">{{ giorniAbbreviati[indexGiorno] }}</div>

                        <div v-if="orario[`${giorno}_aperto`] == 'chiuso'">{{ $t('chiuso') }}</div>
                        <div v-else-if="orario[`${giorno}_aperto`] == 'aperto' && orario[`${giorno}_turni`]" class="orarigiorno">
                            <template v-for="turno of orario[`${giorno}_turni`]">
                                <div v-if="!turno.ora_fine || turno.ora_inizio.slice(0, -3) == turno.ora_fine.slice(0, -3)">{{ formatoOraSenzaData(turno.ora_inizio) }}</div>
                                <div v-else>{{ formatoOraSenzaData(turno.ora_inizio) }}<template v-if="turno.ora_fine"> &ndash; {{ formatoOraSenzaData(turno.ora_fine) }}</template>
                                </div>
                            </template>
                        </div>
                        <div v-else-if="orario[`${giorno}_aperto`] == 'prenotazione'">{{ $t('aperto_prenotazione') }}</div>
                        <div v-else-if="orario[`${giorno}_aperto`] == 'eventi'">{{ $t('aperto_eventi') }}</div>

                    </div>
                </template>
            </div>

        </template>
        <!-- RICORRENZA: MENSILE -->
        <template v-else-if="orario.ricorrenza == 'mensile'">

            <div class="titlebody">
                <span class="upp" v-if="orario.translations && orario.translations[0] && orario.translations[0].etichetta">{{ orario.translations[0].etichetta }}</span>

                <div class="upp" v-if="titoloMensile">{{ titoloMensile }}</div>
                <span class="upp" v-else>{{ $t('apertura_mensile') }}</span>

                <span v-if="date.inizio_ricorrenza && date.fine_ricorrenza">{{ $t('data_mdal') }} {{ formatoDataCompatto(date.inizio_ricorrenza) }} {{ $t('data_al') }} {{ formatoDataCompatto(date.fine_ricorrenza) }}</span>
                <span v-else-if="date.inizio_ricorrenza && !date.fine_ricorrenza">{{ $t('data_mdal') }} {{ formatoDataCompatto(date.inizio_ricorrenza) }}</span>
                <span v-else-if="!date.inizio_ricorrenza && date.fine_ricorrenza">{{ $t('data_finoal') }} {{ formatoDataCompatto(date.fine_ricorrenza) }}</span>

                <span class="sec" v-if="eccetto">{{ $t('eccetto') }} {{ eccetto }}</span>
            </div>

            <div class="turno paragraph">
                <div v-if="orario.aperto == 'chiuso'">{{ $t('chiuso') }}</div>

                <template v-else-if="(orario.aperto == 'aperto' || orario.aperto == 'aperto_blank') && orario.tutto_il_giorno === false && orario.fasce_orarie && orario.fasce_orarie.length > 0">
                    <div v-for="turno of orario.fasce_orarie">{{ formatoOraSenzaData(turno.ora_inizio) }}<template v-if="turno.ora_fine"> &ndash; {{ formatoOraSenzaData(turno.ora_fine) }}</template></div>
                </template>
                <div v-else-if="orario.aperto == 'aperto' && (orario.tutto_il_giorno === true || !orario.fasce_orarie || orario.fasce_orarie.length == 0)">{{ $t('aperto') }}</div>

                <div v-else-if="orario.aperto == 'prenotazione'">{{ $t('aperto_prenotazione') }}</div>
                <div v-else-if="orario.aperto == 'eventi'">{{ $t('aperto_eventi') }}</div>
                <div v-else-if="orario.aperto == 'visite_guidate'">{{ $t('aperto_visite_guidate') }}</div>
                <template v-if="(orario.aperto == 'prenotazione' || orario.aperto == 'eventi' || orario.aperto == 'visite_guidate') && orario.tutto_il_giorno === false && orario.fasce_orarie && orario.fasce_orarie.length > 0">
                    <div v-for="turno of orario.fasce_orarie">{{ formatoOraSenzaData(turno.ora_inizio) }}<template v-if="turno.ora_fine"> &ndash; {{ formatoOraSenzaData(turno.ora_fine) }}</template></div>
                </template>
            </div>

        </template>

        <div class="note mini" v-if="orario.translations && orario.translations[0] && orario.translations[0].note" v-html="orario.translations[0].note"></div>

    </div>
</template>

<script lang="ts" setup>
import type { Orario } from '~/utils/schema';
const { dizionario, getUTCMidnightDate, formatoDataLungo, formatoDataCompatto, formatoOraSenzaData, giorniAbbreviati, giorniLunghi, giorniLunghiPlurali, nomiGiorni, nomiMesi, numeraliMese } = useOrari()
const { locale } = useI18n()
const { isDeepEqual } = useFunctions()

interface Props {
    orario: Orario;
    noborder?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    noborder: false
})

const date = reactive({
    data_inizio: props.orario.data_inizio ? getUTCMidnightDate(props.orario.data_inizio) : null,
    data_fine: props.orario.data_fine ? getUTCMidnightDate(props.orario.data_fine) : null,
    inizio_ricorrenza: props.orario.inizio_ricorrenza ? getUTCMidnightDate(props.orario.inizio_ricorrenza) : null,
    fine_ricorrenza: props.orario.fine_ricorrenza ? getUTCMidnightDate(props.orario.fine_ricorrenza) : null
})

const eccetto = computed(() => {
    const eccezioni = []

    if (props.orario.mesi_esclusi) {
        eccezioni.push(...props.orario.mesi_esclusi.map((e) => nomiMesi[parseInt(e) - 1]))
    }
    if (props.orario && props.orario.eccetto && props.orario.eccetto.length > 0) {
        eccezioni.push(...props.orario.eccetto.map((e) => formatoDataCompatto(getUTCMidnightDate(e.data))))
    }

    if (eccezioni.length > 0) return eccezioni.join(', ')
    else return null
})
const settimanaleCompatto = computed(() => {
    if (props.orario && props.orario.ricorrenza == 'settimanale') {

        const loc = locale.value as 'it' | 'en' | 'fr'

        const giorniSettimana = []
        const turniSettimana = []
        const numeriGiorni = []
        if (props.orario.lunedi_aperto != 'chiuso') { giorniSettimana.push(props.orario.lunedi_aperto); if (props.orario.lunedi_turni) turniSettimana.push(props.orario.lunedi_turni); numeriGiorni.push(0) }
        if (props.orario.martedi_aperto != 'chiuso') { giorniSettimana.push(props.orario.martedi_aperto); if (props.orario.martedi_turni) turniSettimana.push(props.orario.martedi_turni); numeriGiorni.push(1) }
        if (props.orario.mercoledi_aperto != 'chiuso') { giorniSettimana.push(props.orario.mercoledi_aperto); if (props.orario.mercoledi_turni) turniSettimana.push(props.orario.mercoledi_turni); numeriGiorni.push(2) }
        if (props.orario.giovedi_aperto != 'chiuso') { giorniSettimana.push(props.orario.giovedi_aperto); if (props.orario.giovedi_turni) turniSettimana.push(props.orario.giovedi_turni); numeriGiorni.push(3) }
        if (props.orario.venerdi_aperto != 'chiuso') { giorniSettimana.push(props.orario.venerdi_aperto); if (props.orario.venerdi_turni) turniSettimana.push(props.orario.venerdi_turni); numeriGiorni.push(4) }
        if (props.orario.sabato_aperto != 'chiuso') { giorniSettimana.push(props.orario.sabato_aperto); if (props.orario.sabato_turni) turniSettimana.push(props.orario.sabato_turni); numeriGiorni.push(5) }
        if (props.orario.domenica_aperto != 'chiuso') { giorniSettimana.push(props.orario.domenica_aperto); if (props.orario.domenica_turni) turniSettimana.push(props.orario.domenica_turni); numeriGiorni.push(6) }

        if (numeriGiorni.length == 1) {
            return {
                titolo: `${dizionario.ogni[loc]} ${giorniLunghiPlurali[numeriGiorni[0]]}`,
                aperto: giorniSettimana[0],
                fasce_orarie: turniSettimana[0]
            }
        } else if (numeriGiorni.length == 2) {
            if (turniSettimana.length == 2 && isDeepEqual(turniSettimana[0], turniSettimana[1]) && giorniSettimana[0] == giorniSettimana[1]) {
                return {
                    titolo: `${dizionario.ogni[loc]} ${giorniLunghiPlurali[numeriGiorni[0]]}${dizionario.e[loc]}${giorniLunghiPlurali[numeriGiorni[1]]}`,
                    aperto: giorniSettimana[0],
                    fasce_orarie: turniSettimana[0]
                }
            } else return null
        } else return null
    } else return null
})
const titoloMensile = computed(() => {
    if (props.orario && props.orario.ricorrenza == 'mensile') {

        const loc = locale.value as 'it' | 'en' | 'fr'

        switch (props.orario.ogni) {
            case 'numero':
                return `${dizionario.ogni[loc]} ${props.orario.numero_giorno?.join(', ')} ${dizionario.delmese[loc]}`
            case 'primo':
            case 'secondo':
            case 'terzo':
            case 'quarto':
            case 'ultimo':
                let desc_mensile = ''
                if (props.orario.settimana?.length == 1) {
                    desc_mensile = `${numeraliMese[props.orario.ogni as 'primo' | 'secondo' | 'terzo' | 'quarto' | 'ultimo'].singolo} ${giorniLunghi[parseInt(props.orario.settimana[0]) - 1]} ${dizionario.delmese[loc]}`
                    if (props.orario.settimana[0] == '7') desc_mensile = `${numeraliMese[props.orario.ogni as 'primo' | 'secondo' | 'terzo' | 'quarto' | 'ultimo'].domenica} ${giorniLunghi[parseInt(props.orario.settimana[0]) - 1]} ${dizionario.delmese[loc]}`
                } else {
                    desc_mensile = `${numeraliMese[props.orario.ogni as 'primo' | 'secondo' | 'terzo' | 'quarto' | 'ultimo'].plurale} ${props.orario.settimana?.map((g: string) => giorniLunghi[parseInt(g) - 1]).join(', ')} ${dizionario.delmese[loc]}`
                }
                return desc_mensile
        }
    } else return null
})
</script>

<style lang="scss" scoped>
.orario {

    .titlebody {
        display: flex;
        flex-direction: column;

        .upp {
            text-transform: uppercase;
        }

        .sec {
            font-weight: normal;
        }
    }

    .settimana {
        display: flex;
        flex-direction: column;

        border-top: 1px solid var(--color-azure-back);
        padding-top: 6px;
        margin-top: 6px;

        .giorno {
            display: grid;
            grid-template-columns: 50px 1fr;
            gap: var(--margin-default);

            .nome {
                font-weight: 800;
            }

            .orarigiorno {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0 var(--margin-gap);
            }
        }

        .singologiorno {
            display: flex;
            flex-direction: row;
            gap: var(--margin-gap);
        }
    }

    .turno {
        border-top: 1px solid var(--color-azure-back);
        padding-top: 6px;
        margin-top: 6px;

        display: flex;
        flex-direction: row;
        gap: var(--margin-gap);
    }

    .note {
        margin-top: 6px;
    }

    &.noborder {
        .turno {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
    }
}
</style>